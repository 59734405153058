import React, { useMemo, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineMailOpen } from "react-icons/hi";
import * as yup from "yup";
import * as auth from "@ecp-redux/api/auth";
import { IBusinessUserRegisterForm } from "@ecp-redux/dto/auth.types";
import { yupResolver } from "@hookform/resolvers/yup";
import LinkWrapper from "../../global/components/LinkWrapper/LinkWrapper";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import {
  isConfirmPassword,
  isEmail,
  isNIP,
  isNameOrSurnameB2B,
  isPassword,
  isPhoneB2B,
  isRequiredCheckbox,
  isRequiredString,
  isString,
  isZipCode,
} from "../../helpers/validators";
import { useAddAlert } from "../../redux/slices/alertsSlice";
import Alert from "../../shared/components/Alert/Alert";
import { CheckboxesList } from "../../shared/components/CheckboxesList/CheckboxesList";
import { Info, StyledCircleIcon } from "../../shared/components/Info/Info";
import { InputPassword, InputText } from "../../shared/components/Input/Inputs";
import { StyledLine } from "../../shared/components/Input/StyledInput/StyledInput";
import { StyledAlignWrapper } from "../../shared/components/StyledAlignWrapper/StyledAlignWrapper";
import { StyledAuthFormWrapper } from "../../shared/components/StyledAuthFormWrapper/StyledAuthFormWrapper.styled";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { isFetchBaseQueryErrorType } from "../BoxLogin/elements/ShoppingWithoutRegister";
import { IRegisterFormProps } from "./BoxRegister.types";

const MAX_LETTERS = 20;

const registerDefaultValues: IBusinessUserRegisterForm = {
  companyName: "",
  firstName: "",
  lastName: "",
  nip: "",
  street: "",
  streetNumber: "",
  flatNumber: "",
  zipCode: "",
  city: "",
  country: "Polska",
  password: "",
  repeatedPassword: "",
  phoneNumber: "",
  consentD: false,
  email: "",
  newsletter: false,
  terms: false,
  clientType: "B2B",
};

const BusinessRegisterForm: React.FC<IRegisterFormProps> = ({
  messages,
  settings,
}: IRegisterFormProps) => {
  const { addAlert } = useAddAlert();

  const registerRef = useRef<HTMLDivElement>(null);

  const registerCheckboxes = useMemo(
    () => [
      {
        name: "terms",
        label: (
          <>
            {messages.register_form_regulations}
            <span>&nbsp; &nbsp;</span>
            <LinkWrapper
              href={settings.register_form_terms_link}
              linkStyle={settings.register_form_checkbox_link_typo}
              fontStyle={settings.register_form_checkbox_typo}
            >
              {messages.register_form_terms_link}
            </LinkWrapper>
          </>
        ),
        rules: { required: true },
      },
      {
        name: "newsletter",
        label: messages.register_form_email_consent,
      },
      {
        name: "consentD",
        label: messages.register_form_profile_consent,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings, messages]
  );
  const validationSchema = yup.object().shape({
    companyName: isRequiredString(
      messages.register_form_empty_verification_inputs
    ),
    nip: isNIP(messages.register_form_input_wrong_nip_message),
    street: isRequiredString(messages.register_form_empty_verification_inputs),
    firstName: isNameOrSurnameB2B(
      messages.register_form_empty_name_surname_verification
    ),
    lastName: isNameOrSurnameB2B(
      messages.register_form_empty_name_surname_verification
    ),
    streetNumber: isString(),
    flatNumber: isString(),
    city: isRequiredString(messages.register_form_empty_verification_inputs),
    zipCode: isZipCode(
      messages.register_form_syntax_verification_zip_code,
      messages.register_form_empty_verification_inputs
    ),
    email: isEmail(
      messages.register_form_empty_verification_email,
      messages.register_form_syntax_verification_email
    ),
    password: isPassword(
      messages.register_form_empty_verification_password,
      messages.register_form_syntax_verification_password
    ),
    repeatedPassword: isConfirmPassword(
      messages.register_form_empty_verification_repeat_password,
      messages.register_form_diffrent_verification_password
    )("password"),
    phoneNumber: isPhoneB2B(
      messages.register_form_verification_phone,
      messages.register_form_empty_phone_verification
    ),
    terms: isRequiredCheckbox(
      messages.register_form_required_verification_consent
    ),
  });

  const [postRegister, { isSuccess }] = auth.usePostRegisterMutation();

  const formMethods = useForm<IBusinessUserRegisterForm>({
    defaultValues: registerDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    delayError: 500,
  });

  const { handleSubmit, control } = formMethods;

  const onSubmit = async (data: IBusinessUserRegisterForm) => {
    data.flatNumber === "" && delete data.flatNumber;
    data.streetNumber === "" && delete data.streetNumber;

    const register = await postRegister(data);
    const errorCode =
      "error" in register &&
      isFetchBaseQueryErrorType(register?.error) &&
      (
        register.error.data as {
          code: number;
          message: string;
          serviceName: string;
        }
      ).code;
    if (errorCode === 3004) {
      addAlert({
        code: "register_account_already_exist",
        message: messages.register_form_error,
      });
    }
    if (errorCode === 3009) {
      addAlert({
        code: "register_account_already_exist",
        message: messages.register_form_activated_error,
      });
    }
    document?.getElementById("scroll-wrapper")?.scrollTo({
      top: 0,
    });
  };

  return (
    <StyledAuthFormWrapper
      buttonWidth={settings.register_form_submit_button_width}
      inputStyleId={settings.register_form_input_style}
      formElementsGap={settings.register_form_elements_gap}
      titleSpacing={settings.register_form_title_spacing}
      termsLinkSpacing={settings.register_form_terms_link_spacing}
      backgroundColorId={settings.register_form_wrapper_background_color}
      wrapperMargin={settings.register_form_wrapper_margin}
      data-testid="business-register-form"
      className="business-register-form-container register-custom-style"
      ref={registerRef}
    >
      <Alert
        alertsCodes={["register_account_already_exist"]}
        backgroundColor={settings.register_form_error_background_color}
        textColor={settings.register_form_error_info_text_color}
        typography={settings.register_form_error_info_typo}
        closeable
        lifetime={5000}
      />
      {isSuccess ? (
        <Info>
          <>
            <StyledCircleIcon className="business-register-form-container__circle-icon">
              <HiOutlineMailOpen size={24} color="black" />
            </StyledCircleIcon>
            <StyledText
              className="business-register-form-container__success"
              $settings={{
                font: settings.register_form_success_info_typo,
                text: { color: settings.register_form_success_info_text_color },
              }}
              show={isNotEmptyMessage(messages.register_form_success)}
            >
              {messages.register_form_success}
            </StyledText>
          </>
        </Info>
      ) : (
        <>
          <StyledText
            className="business-register-form-container__title"
            $settings={{
              font: settings.register_form_title_typo,
              text: { color: settings.register_form_title_color },
            }}
            alignment={settings.register_form_title_align?.toLowerCase()}
            show={isNotEmptyMessage(messages.register_form_title)}
          >
            {messages.register_form_title}
          </StyledText>
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit((d) => onSubmit(d))}
              autoComplete="off"
              className="business-register-form"
            >
              <InputText
                className="business-register-form__company-name"
                name="companyName"
                control={control}
                placeholder={messages.register_form_placeholder_company_name}
                label={messages.register_form_heading_company_name}
                $settings={settings.register_form_input_style}
                maxLength={100}
              />
              <StyledLine className="business-register-form__name-info">
                <InputText
                  className="business-register-form__first-name"
                  name="firstName"
                  control={control}
                  placeholder={messages.register_form_placeholder_name}
                  label={messages.register_form_heading_name}
                  $settings={
                    settings.register_form_input_name_and_surname_style
                  }
                  maxLength={MAX_LETTERS}
                />
                <InputText
                  className="business-register-form__last-name"
                  name="lastName"
                  control={control}
                  placeholder={messages.register_form_placeholder_surname}
                  label={messages.register_form_heading_surname}
                  $settings={
                    settings.register_form_input_name_and_surname_style
                  }
                  maxLength={MAX_LETTERS}
                />
              </StyledLine>
              <InputText
                className="business-register-form__nip"
                name="nip"
                control={control}
                placeholder={messages.register_form_placeholder_nip}
                label={messages.register_form_heading_nip}
                $settings={settings.register_form_input_style}
                mask="999-999-99-99"
              />

              <InputText
                className="business-register-form__street"
                name="street"
                control={control}
                placeholder={messages.register_form_placeholder_street}
                label={messages.register_form_placeholder_heading_street}
                $settings={settings.register_form_input_style}
                maxLength={75}
              />
              <StyledLine className="business-register-form__street-info">
                <InputText
                  className="business-register-form__street-number"
                  name="streetNumber"
                  control={control}
                  placeholder={messages.register_form_placeholder_house}
                  label={messages.register_form_heading_house}
                  $settings={settings.register_form_input_style}
                  maxLength={10}
                />
                <InputText
                  className="business-register-form__flat-number"
                  name="flatNumber"
                  control={control}
                  placeholder={messages.register_form_placeholder_flat}
                  label={messages.register_form_heading_flat}
                  $settings={settings.register_form_input_style}
                  maxLength={10}
                />
              </StyledLine>
              <InputText
                className="business-register-form__zip-code"
                name="zipCode"
                control={control}
                placeholder={messages.register_form_placeholder_zip_code}
                label={messages.register_form_heading_zip_code}
                $settings={settings.register_form_input_style}
                mask="99-999"
              />
              <InputText
                className="business-register-form__city"
                name="city"
                control={control}
                placeholder={messages.register_form_placeholder_city}
                label={messages.register_form_heading_city}
                $settings={settings.register_form_input_style}
                maxLength={35}
              />

              <InputText
                name="country"
                control={control}
                placeholder={messages.register_form_placeholder_country}
                label={messages.register_form_heading_country}
                $settings={settings.register_form_input_style}
                disabled
              />

              <InputText
                className="business-register-form__phone"
                name="phoneNumber"
                control={control}
                placeholder={messages.register_form_placeholder_phone}
                label={messages.register_form_heading_phone}
                $settings={settings.register_form_input_style}
                mask="999-999-999"
              />
              <InputText
                className="business-register-form__email"
                name="email"
                control={control}
                placeholder={messages.register_form_placeholder_email}
                label={messages.register_form_heading_email}
                $settings={settings.register_form_input_style}
              />
              <InputPassword
                className="business-register-form__password"
                name="password"
                control={control}
                placeholder={messages.register_form_placeholder_password}
                label={messages.register_form_heading_password}
                $settings={settings.register_form_input_style}
              />
              <InputPassword
                className="business-register-form__repeat-password"
                name="repeatedPassword"
                control={control}
                placeholder={messages.register_form_placeholder_repeat_password}
                label={messages.register_form_heading_repeat_password}
                $settings={settings.register_form_input_style}
              />
              <CheckboxesList
                checkboxes={registerCheckboxes}
                checkAllText={messages.register_form_tick_all_consents}
                checkboxesGap={settings.register_form_checkboxes_gap}
                textStyles={{
                  labelTypo: settings.register_form_checkbox_typo,
                  labelColor: settings.register_form_checkbox_color,
                  errorTypo: settings.register_form_checkbox_error_typo,
                  errorColor: settings.register_form_checkbox_error_color,
                  linkTypo: settings.register_form_checkbox_link_typo,
                }}
              />
              <StyledButton
                className="business-register-form__save-button"
                renderAs="button"
                type="submit"
                $settings={settings.register_form_button_style}
                show={isNotEmptyMessage(messages.register_form_save_button)}
              >
                {messages.register_form_save_button}
              </StyledButton>
            </form>
            <StyledAlignWrapper
              alignment={settings.register_form_link_align?.toLowerCase()}
              className="business-register-form__redirect-login"
            >
              <LinkWrapper
                linkStyle={settings.register_form_link_style}
                fontStyle={settings.register_form_redirect_login_typo}
                href={settings.register_form_redirect_login}
              >
                {messages.register_form_login}
              </LinkWrapper>
            </StyledAlignWrapper>
          </FormProvider>
        </>
      )}
    </StyledAuthFormWrapper>
  );
};

export default BusinessRegisterForm;
