import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  TColorId,
  TSizeCSS,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../global/global";
import { alignmentOptionsToValue } from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";

interface IStyledBoxLogin {
  buttonLoginWidth: TSizeCSS;
  buttonRedirectWidth: TSizeCSS;
  formElementsGap: TSizeCSS;
  titleSpacing: TSizeCSS;
  termsLinkSpacing: TSizeCSS;
  wrapperMargin: TSizeCSS;
  backgroundColor: TColorId;
}
interface IStyledRegisterCodeFailed {
  buttonWidth: TSizeCSS;
  iconBackgroundColor: TColorId;
}

interface IStyledRemindPassword {
  remindPasswordPosition: AlignmentHorizontalOption;
}

export const StyledBoxLogin = styled.div<IStyledBoxLogin>(
  ({
    buttonLoginWidth,
    buttonRedirectWidth,
    formElementsGap,
    titleSpacing,
    termsLinkSpacing,
    wrapperMargin,
    backgroundColor,
    theme,
  }) => {
    const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
    return `
      background-color: ${bg};
      box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.15);
      display: flex;
      flex-direction: column;
      padding: ${wrapperMargin};
      
      .login-form-container__submit-button { width: ${buttonLoginWidth}; }
      
      .login-form-container__redirect-button { width: ${buttonRedirectWidth}; margin-top: ${formElementsGap};}
      .login-form-container__remind-password { margin-bottom: ${termsLinkSpacing}; }

      form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: ${formElementsGap};
        margin-top: ${titleSpacing};
      }
    `;
  }
);
export const StyledRegisterCodeFailed = styled.div<IStyledRegisterCodeFailed>(
  ({ buttonWidth, iconBackgroundColor, theme }) => {
    const bg = convertColorIdToHex(iconBackgroundColor, theme.colorPalette);
    return `
        background: white;
        box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 30px;
        ${buttonWidth && `${StyledButton} { width: ${buttonWidth}; }`}
        width: 100%;

      .registerFailedHeader{
        &__Container{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        &__iconContainer{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
          background-color: ${bg};
          width: 40px;
          height 40px;
          border-radius: 50%;
        }
    }
    `;
  }
);
export const StyledLinkContainer = styled.div<IStyledRemindPassword>(
  ({ remindPasswordPosition }) => `
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    align-items: flex-start;
  }

  .login-form-container__privacy-policy {
    &__link {
      display: flex;
      gap: 0.25rem;

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex-direction: column;
      }
    }

    &__remind-password {
      justify-content: ${alignmentOptionsToValue(remindPasswordPosition)};
      width: 100%;
    }
  }

  .shopping-without-register-container__privacy-policy {
    gap: 0.25rem;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      flex-direction: column;
    }
  }
`
);
