import styled from "styled-components";
import {
  TColorId,
  TInputId,
  TMargin,
  TSizeCSS,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../styleElements/StyledButton/StyledButtonWrapper.styled";
import { convertInputIdToValues } from "../Input/StyledInput/StyledInput.methods";

export const StyledAuthFormWrapper = styled.div<{
  buttonWidth: TSizeCSS;
  inputStyleId: TInputId;
  formElementsGap: TSizeCSS;
  backgroundColorId: TColorId;
  titleSpacing: TSizeCSS;
  termsLinkSpacing?: TSizeCSS;
  wrapperMargin: TMargin;
}>(
  ({
    buttonWidth,
    inputStyleId,
    formElementsGap,
    titleSpacing,
    backgroundColorId,
    termsLinkSpacing,
    wrapperMargin,
    theme,
  }) => {
    const { width } = convertInputIdToValues(
      inputStyleId,
      theme.globalObjects?.inputs
    );
    const bgcColor = convertColorIdToHex(backgroundColorId, theme.colorPalette);

    return `
      background-color: ${bgcColor};
      box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.15);
      display: flex;
      flex-direction: column;
      padding:${wrapperMargin};
      ${buttonWidth && `${StyledButton} { width: ${buttonWidth}; }`}
      > form {
        width: ${width}%;
        margin: auto;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        gap: ${formElementsGap};
        margin-top: ${titleSpacing};
        ${termsLinkSpacing && `margin-bottom: ${termsLinkSpacing};`};
      }
      form > * {
        width: 100%;
      }
      a {
        width: fit-content;
        margin: auto;
      }
    `;
  }
);
