import React, { useState } from "react";
import { PaymentType } from "@ecp-redux/dto/cart.types";
import { isNotEmptyMessage } from "../../../../../helpers/isNotEmptyMessage";
import Alert from "../../../../../shared/components/Alert/Alert";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";
import StyledButton from "../../../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { StyledDeliveryChannels } from "../../BoxCartStepTwo.styled";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../../BoxCartStepTwo.types";
import { IDeliveryChannelsProps } from "./DeliveryChannels.types";
import DeliveryElement from "./elements/DeliveryElement";
import SelectedAddress from "./elements/SelectedAddress/SelectedAddress";

const DeliveryChannels: React.FC<IDeliveryChannelsProps> = ({
  deliveryChannels,
  paymentType,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();

  const selectedDeliveryChannel = deliveryChannels.find((e) => e.selected);
  const [editDeliveryChannel, setEditDeliveryChannel] = useState(
    selectedDeliveryChannel === undefined
  );

  const isMobile = useIsMobilePortal();

  return (
    <StyledDeliveryChannels
      className="delivery-channels"
      data-testid="DeliveryChannelsComponent"
    >
      <StyledText
        className="delivery-channels__header"
        $settings={{
          font: settings.basket_step_2_3_typography_header_1,
          text: { color: settings.basket_step_all_color_main },
        }}
        show={isNotEmptyMessage(messages.delivery_channel_header)}
      >
        <p>{messages.delivery_channel_header}</p>
      </StyledText>

      <Alert
        alertsCodes={["4004"]}
        backgroundColor={settings.basket_alert_background}
        textColor={settings.basket_alert_typo_color}
        typography={settings.basket_alert_typo}
        closeable={false}
        className="mb-[15px]"
        removeWhen={!!selectedDeliveryChannel?.selectedAddress}
      />

      <Alert
        alertsCodes={["6000"]}
        backgroundColor={settings.basket_alert_background}
        textColor={settings.basket_alert_typo_color}
        typography={settings.basket_alert_typo}
        closeable={true}
        lifetime={5000}
        className="mb-[15px]"
      />

      {editDeliveryChannel || selectedDeliveryChannel === undefined ? (
        <div className="delivery-channels__channel-container__main">
          <div className="delivery-channels__channel-container">
            {deliveryChannels.map((channel) => (
              <DeliveryElement
                deliveryChannel={channel}
                paymentType={paymentType ?? PaymentType.PAYMENT_IN_ADVANCE}
                key={channel.channelType}
                onClick={() => setEditDeliveryChannel(false)}
              />
            ))}
          </div>
          {!isMobile && (
            <div className="delivery-channels__channel-button-container">
              <StyledButton
                className="delivery-channels__channel-button"
                $settings={settings.basket_step_all_back_button}
                renderAs="button"
                onClick={() => setEditDeliveryChannel(false)}
                data-testid="BackDeliveryChannelBtn"
                show={
                  isNotEmptyMessage(messages.delivery_back_button) &&
                  selectedDeliveryChannel !== undefined
                }
              >
                {messages.delivery_back_button}
              </StyledButton>
            </div>
          )}
        </div>
      ) : (
        <SelectedAddress
          deliveryChannel={selectedDeliveryChannel}
          removeSelectedDeliveryChannel={() => setEditDeliveryChannel(true)}
          deliveryChannels={deliveryChannels}
        />
      )}
    </StyledDeliveryChannels>
  );
};

export default DeliveryChannels;
