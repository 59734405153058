import React, { useEffect } from "react";
import cleanDeep from "clean-deep";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import * as cartAPI from "@ecp-redux/api/cart";
import {
  AddressPostalBilling,
  AddressType,
  IBillingPostalAddAddressRequest,
} from "@ecp-redux/dto/cartAddresses.type";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { isNIP, isPhone, isZipCode } from "../../../helpers/validators";
import { Checkbox } from "../../../shared/components/CheckboxesList/CheckboxesList";
import { InputText } from "../../../shared/components/Input/Inputs";
import { FormRadio } from "../../../shared/components/Radio/Radio";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledBillingPostalAddressForm } from "../BoxCartStepTwo/BoxCartStepTwo.styled";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../BoxCartStepTwo/BoxCartStepTwo.types";

type AddresType = "private" | "company";

interface BillingPostalAddresForm
  extends Omit<IBillingPostalAddAddressRequest, "type"> {
  addressType: AddresType;
}

interface NewAddressFormProps {
  disableForm: () => void;
  addressType: AddressType.POSTAL | AddressType.BILLING;
  editData: AddressPostalBilling | null;
  isDefaultAddress?: boolean;
}

const BillingPostalAdressForm: React.FC<NewAddressFormProps> = ({
  disableForm,
  addressType,
  editData,
  isDefaultAddress = false,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();

  const newDeliveryAddress: BillingPostalAddresForm = {
    firstName: editData?.firstName ?? "",
    lastName: editData?.lastName ?? "",
    street: editData?.street ?? "",
    streetNumber: editData?.streetNumber ?? "",
    flatNumber: editData?.flatNumber ?? "",
    city: editData?.city ?? "",
    zipCode: editData?.zipCode ?? "",
    country: editData?.country ?? "Polska",
    companyName: editData?.companyName ?? "",
    nip: editData?.nip ?? "",
    phone: editData?.phone ?? "",
    defaultAddress: isDefaultAddress ? true : editData?.defaultAddress ?? false,
    addressType: editData?.companyName ? "company" : "private",
  };

  const [addAddress] = cartAPI.usePostCartAddressByPortalUserTokenMutation();
  const [editAddress] = cartAPI.usePutCartAddressByPortalUserTokenMutation();

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(messages.form_required_verification),
    lastName: yup.string().trim().required(messages.form_required_verification),
    street: yup.string().trim().required(messages.form_required_verification),
    streetNumber: yup.string().trim().notRequired(),
    flatNumber: yup.string().trim().notRequired(),
    city: yup.string().trim().required(messages.form_required_verification),
    zipCode: isZipCode(
      messages.form_syntax_verification_zip_code,
      messages.form_required_verification
    ),
    country: yup.string().trim().required(messages.form_required_verification),
    phone: isPhone(messages.form_syntax_verification_phone).required(
      messages.form_required_verification
    ),
    defaultAddress: yup.boolean(),
    addressType: yup.string().trim(),
    nip: yup.string().when("addressType", {
      is: "company",
      then: isNIP(messages.form_syntax_verification_nip),
    }),
    companyName: yup.string().when("addressType", {
      is: "company",
      then: yup.string().trim().required(messages.form_required_verification),
    }),
  });

  const billingPostalAddresFormMethods = useForm<BillingPostalAddresForm>({
    defaultValues: newDeliveryAddress,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    delayError: 500,
  });

  const { handleSubmit, control, register, watch, resetField } =
    billingPostalAddresFormMethods;

  const onSubmit = (data: BillingPostalAddresForm) => {
    const bodyFromForm: IBillingPostalAddAddressRequest = {
      ...data,
      type:
        addressType === AddressType.BILLING
          ? "BILLING"
          : isPrivate
          ? "SHIPPING_PRIVATE"
          : "SHIPPING_COMPANY",
      nip: data.addressType === "company" ? data.nip : undefined,
      companyName:
        data.addressType === "company" ? data.companyName : undefined,
    };
    const cleanedEmptyStringsBody = cleanDeep(
      bodyFromForm
    ) as IBillingPostalAddAddressRequest;

    if (editData == null) {
      addAddress(cleanedEmptyStringsBody).then(disableForm);
    } else {
      editAddress({
        ...cleanedEmptyStringsBody,
        addressId: editData.id,
      }).then(disableForm);
    }
  };

  const isPrivate = watch("addressType") === "private";

  useEffect(() => {
    if (isPrivate) {
      resetField("nip");
      resetField("companyName");
    }
  }, [isPrivate]);

  return (
    <StyledBillingPostalAddressForm
      data-testid="PostalBillingAddressForm"
      className="billing-postal-address-form"
    >
      <StyledText
        className="billing-postal-address-form__add-address-button"
        $settings={{
          font: settings.basket_step_2_3_typography_paragraph_4,
          text: { color: settings.basket_step_all_color_main },
        }}
        show={
          addressType === AddressType.POSTAL
            ? isNotEmptyMessage(messages.add_new_postal_address)
            : isNotEmptyMessage(messages.add_new_billing_address)
        }
      >
        {addressType === AddressType.POSTAL
          ? messages.add_new_postal_address
          : messages.add_new_billing_address}
      </StyledText>

      <FormProvider {...billingPostalAddresFormMethods}>
        <form
          onSubmit={handleSubmit((d) => onSubmit(d))}
          autoComplete="off"
          className="billing-postal-address-form__form"
        >
          <div className="billing-postal-address-form__form__address-type">
            <FormRadio
              {...register("addressType")}
              label={messages.form_heading_private}
              value="private"
              textStyles={{
                labelColor: settings.basket_step_all_color_main,
                labelTypo: settings.basket_step_all_typography_paragraph_2,
              }}
            />
            <FormRadio
              {...register("addressType")}
              label={messages.form_heading_company}
              value="company"
              textStyles={{
                labelColor: settings.basket_step_all_color_main,
                labelTypo: settings.basket_step_all_typography_paragraph_2,
              }}
            />
          </div>
          <InputText
            name="firstName"
            control={control}
            placeholder={messages.form_placeholder_first_name}
            label={messages.form_heading_first_name}
            $settings={settings.basket_step_2_full_input}
            maxLength={20}
          />
          <InputText
            name="lastName"
            control={control}
            placeholder={messages.form_placeholder_last_name}
            label={messages.form_heading_last_name}
            $settings={settings.basket_step_2_full_input}
            maxLength={20}
          />
          {!isPrivate && (
            <InputText
              name="companyName"
              control={control}
              placeholder={messages.form_placeholder_company_name}
              label={messages.form_heading_company_name}
              $settings={settings.basket_step_2_full_input}
              maxLength={100}
            />
          )}
          {!isPrivate && (
            <InputText
              name="nip"
              control={control}
              placeholder={messages.form_placeholder_nip}
              label={messages.form_heading_nip}
              $settings={settings.basket_step_2_full_input}
              mask="999-999-99-99"
            />
          )}
          <InputText
            name="street"
            control={control}
            placeholder={messages.form_placeholder_street}
            label={messages.form_heading_street}
            $settings={settings.basket_step_2_full_input}
            maxLength={75}
          />
          <div className="half_inputs">
            <InputText
              name="streetNumber"
              control={control}
              placeholder={messages.form_placeholder_house_number}
              label={messages.form_heading_house_number}
              $settings={settings.basket_step_2_half_input}
              maxLength={10}
            />
            <InputText
              name="flatNumber"
              control={control}
              placeholder={messages.form_placeholder_apartment_number}
              label={messages.form_heading_apartment_number}
              $settings={settings.basket_step_2_half_input}
              maxLength={10}
            />
          </div>
          <InputText
            name="city"
            control={control}
            placeholder={messages.form_placeholder_city}
            label={messages.form_heading_city}
            $settings={settings.basket_step_2_full_input}
            maxLength={35}
          />
          <InputText
            name="zipCode"
            control={control}
            placeholder={messages.form_placeholder_zip_code}
            label={messages.form_heading_zip_code}
            $settings={settings.basket_step_2_full_input}
            mask="99-999"
          />
          <InputText
            name="country"
            control={control}
            placeholder={messages.form_placeholder_country}
            label={messages.form_heading_country}
            $settings={settings.basket_step_2_full_input}
            disabled
          />
          <InputText
            name="phone"
            control={control}
            placeholder={messages.form_placeholder_phone}
            label={messages.form_heading_phone}
            $settings={settings.basket_step_2_full_input}
            mask="999-999-999"
          />
          <Checkbox
            control={control}
            name="defaultAddress"
            label={messages.form_heading_default_address}
            textStyles={{
              labelTypo: settings.basket_step_2_checkbox_typo,
              labelColor: settings.basket_step_2_checbox_color,
              errorTypo: settings.basket_step_2_checkbox_typo,
              errorColor: settings.basket_step_2_checbox_color,
            }}
            disabled={isDefaultAddress}
          />
          <div className="buttons">
            <StyledButton
              renderAs="button"
              type="submit"
              $settings={settings.basket_step_all_main_button}
              show={messages.form_button_save}
            >
              {messages.form_button_save}
            </StyledButton>
            <StyledButton
              renderAs="button"
              $settings={settings.basket_step_all_second_button}
              onClick={disableForm}
              show={messages.form_button_cancel}
            >
              {messages.form_button_cancel}
            </StyledButton>
          </div>
        </form>
      </FormProvider>
    </StyledBillingPostalAddressForm>
  );
};

export default BillingPostalAdressForm;
