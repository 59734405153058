import React from "react";
import useTranslation from "next-translate/useTranslation";
import { isPortalSide } from "../../helpers/helpers";
import AttributeList from "../BoxProductAttributes/AttributeList/AttributeList";
import AttributeTable from "../BoxProductAttributes/AttributeTable/AttributeTable";
import { isEveryAttributeIsEmpty } from "../BoxProductAttributes/BoxProductAttributes.methods";
import {
  StyledAttributeListWrapper,
  StyledBoxProductAttributesWrapper,
} from "../BoxProductAttributes/BoxProductAttributes.styled";
import { AttributeDisplayType } from "../BoxProductAttributes/BoxProductAttributes.types";
import { IBoxGetDataProps } from "./BoxGetData.types";
import useBoxGetData from "./useBoxGetData";

const BoxGetData: React.FC<IBoxGetDataProps> = (props) => {
  const { t } = useTranslation();
  const { contentSettings, displaySettings, dataAttributes, currency } =
    useBoxGetData(props);

  if (
    dataAttributes === undefined ||
    dataAttributes.length === 0 ||
    isEveryAttributeIsEmpty(dataAttributes)
  )
    return (
      <div className="product-attributes--empty">
        {!isPortalSide() && t("global_keys.empty_box")}
      </div>
    );

  return (
    <StyledBoxProductAttributesWrapper
      spacing={displaySettings.padding}
      className="product-attributes-container get-data-custom-style"
    >
      {displaySettings.displayType === AttributeDisplayType.LIST ? (
        <StyledAttributeListWrapper
          gap={displaySettings.tableSettings.spaceBetweenRows}
          className="product-attributes-container__list"
        >
          {dataAttributes.map((attribute, id) => (
            <AttributeList
              key={id}
              contentSettings={contentSettings}
              displaySettings={displaySettings}
              attribute={attribute}
              searchRedirectUrl=""
              currency={currency}
            />
          ))}
        </StyledAttributeListWrapper>
      ) : (
        <AttributeTable
          contentSettings={contentSettings}
          displaySettings={displaySettings}
          productExtendedAttributes={dataAttributes}
          searchRedirectUrl=""
          currency={currency}
        />
      )}
    </StyledBoxProductAttributesWrapper>
  );
};

export default BoxGetData;
