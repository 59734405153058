import React from "react";
import styled from "styled-components";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import Popup from "../../../shared/components/Popup/Popup";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledUnavalibleReorderPopup } from "../BoxMyAccountMyOrders.styled";
import {
  IBoxMyAccountMyOrdersMessages,
  ITBoxMyAccountMyOrdersSettings,
} from "../BoxMyAccountMyOrders.types";

interface IUnavalibleReorderPopupProps {
  closePopup: () => void;
}

const StyledUnavalibleReorderPopupWrapper = styled(Popup)`
  padding: 30px;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const UnavalibleReorderPopup: React.FC<IUnavalibleReorderPopupProps> = ({
  closePopup,
}) => {
  const { settings, messages } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersMessages,
    ITBoxMyAccountMyOrdersSettings
  >();

  return (
    <StyledUnavalibleReorderPopupWrapper
      desktopWidth="600px"
      className="unavailable-reorder-popup"
      closePopup={closePopup}
    >
      <StyledUnavalibleReorderPopup>
        <StyledText
          className="unavailable-reorder-popup__description"
          $settings={{
            font: settings.popup_description_typo,
            text: { color: settings.popup_description_color },
          }}
          show={isNotEmptyMessage(
            messages.customer_data_reorder_unavailable_description
          )}
        >
          {messages.customer_data_reorder_unavailable_description}
        </StyledText>

        <div className="unavailable-reorder-popup__button-container">
          <StyledButton
            className="unavailable-reorder-popup__button-container__save-button"
            $settings={settings.popup_no_button}
            renderAs={"button"}
            onClick={() => closePopup()}
            show={isNotEmptyMessage(
              messages.customer_data_reorder_unavailable_button_save
            )}
          >
            {messages.customer_data_reorder_unavailable_button_save}
          </StyledButton>
        </div>
      </StyledUnavalibleReorderPopup>
    </StyledUnavalibleReorderPopupWrapper>
  );
};

export default UnavalibleReorderPopup;
