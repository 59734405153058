import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import * as searchResults from "../api/searchResults";
import { IPaginationGridPayload } from "../dto/paginationResponse.types";
import {
  IProductAttributePm,
  IProductListAttributesResponse,
  IProductListAttributesTypeResponse,
  IProductListCategoryTreeResponse,
  IProductListPoolsResponse,
  IProductPageConfigResponse,
  IProductsResponse,
  TAttribute,
  TCategory,
  TCategoryResponse,
} from "../dto/productList.types";
import { ISearchResultsTransformResponse } from "../dto/searchResults.types";
import { API, api } from "./api";

export const productList = api.injectEndpoints({
  endpoints: (builder) => ({
    getAttributes: builder.query<
      IProductAttributePm[],
      { onlyFilterable: boolean; extra: IProductAttributePm[] }
    >({
      query: ({ onlyFilterable }) => {
        return onlyFilterable
          ? API.getFilterableSearchAttributes.endpoint()
          : API.getSearchAttributes.endpoint();
      },
      transformResponse: (
        resp: {
          attributes: TAttribute[];
        },
        _,
        { extra }
      ) =>
        [
          ...resp.attributes.map((attrib) => ({
            attributeId: attrib.id,
            label: attrib.label,
            type: attrib.type,
            filterable: attrib.filterable,
            options: attrib.options,
          })),
          ...extra,
        ].sort((a, b) =>
          (a.label ?? a.attributeId).localeCompare(b.label ?? b.attributeId)
        ),
    }),
    getProducts: builder.mutation<IProductsResponse, IPaginationGridPayload>({
      queryFn: async (body, { getState }, _1, baseQuery) => {
        if ((getState() as any).dragAndDrop.layout.rootId === null) {
          const productPageResponse: QueryReturnValue<
            IProductPageConfigResponse,
            FetchBaseQueryError
          > = await baseQuery(API.getProductPageConfigs.endpoint());

          const categoryIds = productPageResponse.data?.categoryIds ?? [];
          const skus = productPageResponse.data?.skus ?? [];

          return baseQuery({
            url: API.getProducts.endpoint(),
            method: "POST",
            body: {
              ...body,
              filter: [
                ...body.filter,
                ...(categoryIds.length > 0
                  ? [
                      {
                        name: "categories",
                        operator: "NOT_IN",
                        value: categoryIds.join(";"),
                      },
                    ]
                  : []),
                ...skus.map((sku: string) => ({
                  name: "sku",
                  operator: "NOT_EQ",
                  value: sku,
                })),
              ],
            },
          });
        }
        return baseQuery({
          url: API.getProducts.endpoint(),
          method: "POST",
          body,
        });
      },
    }),
    getProductListAttributes: builder.mutation<
      IProductListAttributesResponse,
      IPaginationGridPayload
    >({
      query: (body) => ({
        url: API.getProductListAttributes.endpoint(),
        method: "POST",
        body,
      }),
      transformResponse: (response: IProductListAttributesResponse) => ({
        ...response,
        result: response.result?.map((attr) => ({
          ...attr,
          name: attr.label,
        })),
      }),
    }),
    getProductListAttributesType: builder.query<
      IProductListAttributesTypeResponse,
      void
    >({
      query: () => API.getProductListAttributesType.endpoint(),
    }),
    getProductProjection: builder.query<string[], void>({
      query: () => API.getProductProjection.endpoint(),
    }),
    getProductListPools: builder.mutation<
      IProductListPoolsResponse,
      IPaginationGridPayload
    >({
      query: (body) => ({
        url: API.getProductListPools.endpoint(),
        method: "POST",
        body,
      }),
    }),
    getProductListCategoryTree: builder.query<
      IProductListCategoryTreeResponse,
      void
    >({
      query: () => API.getProductListCategoryTree.endpoint(),
      transformResponse: (response: {
        children: IProductListCategoryTreeResponse;
      }) =>
        response?.children?.map(function mapper(
          res
        ): Partial<TCategoryResponse> {
          return {
            id: res?.code,
            name: res?.label,
            children: res?.children?.map(mapper) as TCategory[],
          };
        }),
    }),
    getDynamicAssociationProducts: builder.query<
      ISearchResultsTransformResponse,
      { currentSku: string; attributes: string[] }
    >({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async ({ currentSku, attributes }, { dispatch }) => {
        try {
          const currentProductData = await dispatch(
            searchResults.getPostProducts.initiate({
              skus: [currentSku],
              projection: ["lowestCategoryLevel"].concat(attributes),
            })
          );

          const currentProductAttributes = attributes
            .map((attributeName) => {
              const value =
                currentProductData.data?.products?.[0][attributeName];
              if (!value) return;
              return {
                attributeName,
                value: Array.isArray(value)
                  ? value.map((e) => e.label ?? value).join(";")
                  : value.label ?? value.value,
              };
            })
            .filter(Boolean) as {
            attributeName: string;
            value: string;
          }[];

          let attributesQuery = currentProductAttributes
            .map((arg) => `filter=${arg.attributeName}:${arg.value}`)
            .concat(`notFilter=sku:${currentSku}`)
            .join("&");

          if (attributes.includes("PortalCategory")) {
            const category =
              currentProductData.data?.products[0].lowestCategoryLevel;

            const categoryLevel = category
              ? Math.floor(category.length / 2) - 1
              : "";

            const levelWithPrefix = categoryLevel
              ? categoryLevel < 10
                ? `0${categoryLevel}`
                : categoryLevel
              : "";

            attributesQuery === ""
              ? (attributesQuery = attributesQuery.concat(
                  `filter=cat${levelWithPrefix}:${category}`
                ))
              : (attributesQuery = attributesQuery.concat(
                  `&filter=cat${levelWithPrefix}:${category}`
                ));
          }

          const associatedSkus = await dispatch(
            searchResults.getSearchResults.initiate({
              query: attributesQuery,
            })
          );

          return attributesQuery === ""
            ? { ...associatedSkus, data: { products: [] } }
            : associatedSkus;
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetAttributesQuery,
  useGetProductListAttributesMutation,
  useGetProductListAttributesTypeQuery,
  useGetProductListPoolsMutation,
  useGetProductListCategoryTreeQuery,
  useGetProductsMutation,
  useGetDynamicAssociationProductsQuery,
  useGetProductProjectionQuery,
  util: { getRunningOperationPromises },
} = productList;

export const {
  getProductListAttributes,
  getProductListAttributesType,
  getProductListPools,
  getProductListCategoryTree,
  getProducts,
  getDynamicAssociationProducts,
} = productList.endpoints;
