import React, { memo } from "react";
import { isEqual } from "lodash";
import {
  IPortalSection,
  IPortalSlot,
  IPortalStack,
  SectionType,
} from "@ecp-pageTypes";
import { DisplaySettingsSlotScheme } from "@ecp-redux/dto/themeSettings/StyledSlot.types";
import { getDefaultsFromZod, isPortalSide } from "../../helpers/helpers";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { StyledSlot } from "../../settingsPatterns/slot/StyledSlot";
import { StyledSlotWrapper } from "../../shared/components/StyledSlotWrapper";
import { PortalPath, usePagePathId } from "../../shared/portalPath/portalPath";
import { useLayoutControllerContext } from "../Contexts/LayoutControllerContext";
import { CustomCssWrapper } from "../CustomCssWrapper/CustomCssWrapper.styled";
import Publication from "../Publication/Publication";
import Section from "../Section/Section";

export interface ISlotProps {
  slotData: IPortalSlot;
  type: SectionType;
  path: PortalPath;
}

const Slot: React.FC<ISlotProps> = ({ slotData, type, path }) => {
  const hasSectionData = slotData?.sections?.length > 0;
  const hasPublicationData = slotData?.stacks?.length > 0;
  const slotSettings = usePrepareSettings({
    props: slotData?.displaySettings,
    settingsPattern: getDefaultsFromZod(DisplaySettingsSlotScheme),
    options: { themePath: "stylePages.slot", includeTheme: true },
  }).settings;

  const { publicationIndex } = usePagePathId(path, slotData);
  const { boxSliderActiveId } = useLayoutControllerContext();

  return (
    <StyledSlotWrapper
      margins={slotData?.displaySettings.margin}
      slotColumns={slotData?.columns ?? 12}
    >
      <CustomCssWrapper
        data-testid="customCss"
        id="custom-Css"
        customCss={slotData.customCss}
      >
        <StyledSlot
          $settings={slotSettings}
          data-testid="slot"
          id={`SLOT=${slotData?.id}`}
          hoverEffect={slotSettings.effects.hoverEffect}
        >
          <div id="overlay-slot"></div>
          {hasPublicationData &&
            slotData.stacks.map((stack: IPortalStack, index: number) => {
              return (
                <Publication
                  path={path.concatPaths(
                    new PortalPath([
                      {
                        type: "STACK",
                        elementId: "x",
                        elementIndex: index,
                      },
                      {
                        type: "PUBLICATION",
                        elementId:
                          stack.publications[publicationIndex[index]].id,
                        elementIndex: publicationIndex[index],
                      },
                    ])
                  )}
                  key={stack.publications[publicationIndex[index]].id}
                  publicationData={{
                    ...stack.publications[publicationIndex[index]],
                  }}
                  isEditMode={!isPortalSide()}
                  {...(boxSliderActiveId
                    ? { pathExtraSettings: boxSliderActiveId }
                    : {})}
                />
              );
            })}
          {hasSectionData &&
            slotData.sections.map((section: IPortalSection, index: number) => {
              return (
                <Section
                  key={section.uuid + section.mobileSubSection}
                  sectionData={section}
                  type={type}
                  path={path.concatPaths(
                    new PortalPath([
                      {
                        type: "SECTION",
                        elementId: section.id,
                        elementIndex: section.sectionOrder,
                      },
                    ])
                  )}
                />
              );
            })}
        </StyledSlot>
      </CustomCssWrapper>
    </StyledSlotWrapper>
  );
};

export default memo(Slot, isEqual);
