import styled, { css } from "styled-components";
import { IElementButton } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  TButtonId,
  TButtonReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalHTMLElement from "../../../global/components/ConditionalHTMLElement/ConditionalHTMLElement";
import composeCss from "../../../settingsPatterns/composeCss";
import {
  convertButtonIdToValues,
  convertColorIdToHex,
} from "../../../settingsPatterns/settingsPatterns.methods";

export interface IButtonSettings {
  $settings: IElementButton | TButtonId | TButtonReadoutValue;
}

const StyledButtonWrapper = styled(ConditionalHTMLElement).attrs((props) => ({
  renderAs: props?.["renderAs"] ?? "a",
  show:
    props?.["$settings"]?.show === false || props["show"] === false
      ? false
      : true,
  target: props?.["target"] ? "_blank" : "_parent",
}))<IButtonSettings>(({ $settings, theme }) => {
  const buttonValue = convertButtonIdToValues(
    $settings,
    theme?.globalObjects?.buttons
  );

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${buttonValue.verticalPadding}px;
    padding-right: ${buttonValue.horizontalPadding}px;
    padding-bottom: ${buttonValue.verticalPadding}px;
    padding-left: ${buttonValue.horizontalPadding}px;
    height: fit-content;
    width: fit-content;
    border-style: solid;
    ${composeCss.buttonSettings.icon(buttonValue.icon)};
    ${composeCss.font(buttonValue.font, theme.typography)};
    ${composeCss.buttonSettings.borderColor(buttonValue.border, theme)};
    ${composeCss.buttonSettings.borderWidth(
      buttonValue.border.widthV2,
      buttonValue.border.width
    )};
    ${composeCss.buttonSettings.borderRadius(
      buttonValue.border.radiusV2,
      buttonValue.border.radius
    )};
    background-color: ${convertColorIdToHex(
      buttonValue.background.color,
      theme.colorPalette
    )};
    color: ${convertColorIdToHex(buttonValue.text.color, theme.colorPalette)};

    .svg {
      height: ${buttonValue.icon.size}px;
      width: ${buttonValue.icon.size}px;
      background-color: ${convertColorIdToHex(
        buttonValue.icon.color,
        theme.colorPalette
      )};
      mask: url(${buttonValue.icon.iconUrl}) no-repeat center / contain;
      -webkit-mask: url(${buttonValue.icon.iconUrl}) no-repeat center / contain;
    }

    &:hover {
      background-color: ${convertColorIdToHex(
        buttonValue.background.hoverColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(
        buttonValue.text.hoverColor,
        theme.colorPalette
      )};
      border-color: ${convertColorIdToHex(
        buttonValue.border.hoverColor,
        theme.colorPalette
      )};

      .svg {
        background-color: ${convertColorIdToHex(
          buttonValue.icon.hoverColor,
          theme.colorPalette
        )};
        mask: url(${buttonValue.icon.iconUrl}) no-repeat center / contain;
        -webkit-mask: url(${buttonValue.icon.iconUrl}) no-repeat center /
          contain;
      }
    }

    &:active,
    &.active-button {
      background-color: ${convertColorIdToHex(
        buttonValue.background.activeColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(
        buttonValue.text.activeColor,
        theme.colorPalette
      )};
      border-color: ${convertColorIdToHex(
        buttonValue.border.activeColor,
        theme.colorPalette
      )};
    }

    &:disabled,
    &.disabled-button {
      background-color: ${convertColorIdToHex(
        buttonValue.background.disabledColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(
        buttonValue.text.disabledColor,
        theme.colorPalette
      )};
      border-color: ${convertColorIdToHex(
        buttonValue.border.disabledColor,
        theme.colorPalette
      )};
      user-select: none;
      pointer-events: none;
    }
    /* Smartphones and touchscreens - Fix for stop applying hover styles after clicking on button on smartphones and touchscreens (ECP-8289) */
    @media (hover: none) and (pointer: coarse) {
      &:hover {
        // default state colors applied for hover here
        background-color: ${convertColorIdToHex(
          buttonValue.background.color,
          theme.colorPalette
        )};
        border-color: ${convertColorIdToHex(
          buttonValue.border.color,
          theme.colorPalette
        )};
        color: ${convertColorIdToHex(
          buttonValue.text.color,
          theme.colorPalette
        )};
      }

      &:active,
      &.active-button {
        background-color: ${convertColorIdToHex(
          buttonValue.background.activeColor,
          theme.colorPalette
        )};
        color: ${convertColorIdToHex(
          buttonValue.text.activeColor,
          theme.colorPalette
        )};
        border-color: ${convertColorIdToHex(
          buttonValue.border.activeColor,
          theme.colorPalette
        )};
      }

      &:disabled,
      &.disabled-button {
        background-color: ${convertColorIdToHex(
          buttonValue.background.disabledColor,
          theme.colorPalette
        )};
        color: ${convertColorIdToHex(
          buttonValue.text.disabledColor,
          theme.colorPalette
        )};
        border-color: ${convertColorIdToHex(
          buttonValue.border.disabledColor,
          theme.colorPalette
        )};
        user-select: none;
        pointer-events: none;
      }
    }
  `;
});

export default StyledButtonWrapper;
