import React from "react";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import {
  BOX_BANNER_CONTENT_PATTERN,
  BOX_BANNER_PATTERN,
  StyledBanner,
} from "../../settingsPatterns/boxes/BoxBanner/StyledBanner";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import InnerBox from "../../shared/components/InnerBox/InnerBox";
import { IBoxBannerProps } from "./BoxBanner.types";

const BoxBanner: React.FC<IBoxBannerProps> = (props) => {
  const [imgWrapper, imageWidth] = useImageSize();

  const { settings } = usePrepareSettings({
    props: {
      ...props.displaySettings,
      ...props.contentSettings,
    },
    settingsPattern: BOX_BANNER_PATTERN(),
  });

  const content = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_BANNER_CONTENT_PATTERN(),
    options: {
      includeTheme: true,
      themePath: "advanceSettings.settings.backgroundUrl",
      themeWrapperPath: "image.imageUrl",
    },
  }).settings;
  return (
    <StyledBanner
      ref={imgWrapper}
      $settings={settings}
      className="banner-container banner-custom-style"
    >
      {settings.image.show && imageWidth && (
        <ImageWrapper
          imageUrl={content.image.imageUrl}
          seoDescription={content.seoDescription}
          imageFit={settings.image.fit}
          imageAlignment={settings.image.alignment}
          opacity={settings.image.opacity}
          width={imageWidth}
          parallax={settings.image.parallax}
          imgTagAsBackground
          link={content.bannerUrl}
        />
      )}
      <InnerBox
        content={content}
        settings={settings}
        className="banner-container__inner-box"
      />
    </StyledBanner>
  );
};

export default BoxBanner;
