import React, { useEffect } from "react";
import { useRouter } from "next/router";
import * as cartAPI from "@ecp-redux/api/cart";
import { CART_ERROR_STATUS } from "@ecp-redux/dto/cart.types";
import { isPortalSide } from "../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { useAddAlert } from "../../../redux/slices/alertsSlice";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import Summary from "../BoxCartStepOne/potentialBoxes/Summary/Summary";
import { isCartError } from "../cart.methods";
import { StyledBoxCartStepTwo } from "./BoxCartStepTwo.styled";
import { IBoxCartStepTwoProps } from "./BoxCartStepTwo.types";
import Billing from "./potentialBoxes/Billing/Billing";
import DeliveryChannels from "./potentialBoxes/DeliveryChannels/DeliveryChannels";
import Payments from "./potentialBoxes/Payment/Payments";

const alertMessagesMap = {
  "1015": "basket_missing_billing_address_alert",
  "1016": "basket_missing_payment_method_alert",
  "4004": "basket_missing_delivery_address_alert",
  "1010": "basket_no_products_has_been_added_alert",
  "6000": "basket_packstation_not_available_alert",
} as const;

const BoxCartStepTwo: React.FC<IBoxCartStepTwoProps> = ({
  settings,
  messages,
}) => {
  const { data, isError } =
    cartAPI.useGetDeliveryAndPaymentByPortalUserTokenQuery();
  const [order, { error: orderError }] =
    cartAPI.usePostCartPlaceOrderMutation();
  const router = useRouter();
  const { addAlert } = useAddAlert([orderError]);

  useEffect(() => {
    if (
      data &&
      data.errors.some(
        (err) =>
          err.status === CART_ERROR_STATUS.DELIVERY_CHANNELS_NOT_AVAILABLE
      )
    ) {
      addAlert({
        code: "DELIVERY_CHANNELS_NOT_AVAILABLE",
        message: messages.basket_unavailable_delivery_channels_alert,
      });
    }
  }, [data?.errors]);

  useEffect(() => {
    if (
      router &&
      isPortalSide() &&
      (data?.response.orderLines.length === 0 || isError)
    ) {
      router.replace(`${settings.basket_step_2_url_step_0}`);
    }
  }, [router, isError, data?.response.orderLines]);

  const isMobile = useIsMobilePortal();

  if (data == null) {
    return null;
  }

  const placeOrder = () => {
    order()
      .unwrap()
      .then((res) =>
        router.replace(
          `${settings.basket_step_2_summary_order_url}?orderId=${res.response.orderId}`
        )
      )
      .catch((e) => {
        const code = String(e.data.code) as "1015" | "1016" | "4004" | "1010" | "6000";
        addAlert({
          code,
          message: messages[alertMessagesMap[code]],
        });
      });
  };

  const isSelectedDeliveryChannelAndPaymentMethod =
    data.response.deliveryChannels.find((e) => e.selected) !== undefined &&
    data.response.payments.find((e) => e.selected) !== undefined;

  return (
    <StyledBoxCartStepTwo
      className="cart-step-two"
      backgroundColor={settings.basket_step_all_background_color}
    >
      <StyledText
        $settings={{
          font: settings.basket_step_2_top_header_typo,
          text: {
            color: settings.basket_step_2_top_header_color,
          },
        }}
        backgroundColor={settings.basket_step_2_top_header_background_color}
        className="mobile-top-header"
        show={isNotEmptyMessage(messages.mobile_top_header) && isMobile}
      >
        {messages.mobile_top_header}
      </StyledText>

      <div className="cart-step-two__content">
        <DeliveryChannels
          deliveryChannels={data.response.deliveryChannels}
          paymentType={
            data.response.payments.find((e) => e.selected)?.paymentType
          }
        />
        <Billing
          selectedBilling={data.response.billingAddress}
          billingAddresses={data.response.billingAddresses}
        />
        <Payments payments={data.response.payments} />
      </div>

      <Summary
        cartSummary={data.response.cartSummary}
        disabledNextStep={isCartError(data, [
          CART_ERROR_STATUS.DELIVERY_CHANNELS_NOT_AVAILABLE,
          CART_ERROR_STATUS.PRODUCT_DELIVERY_TIME_NOT_AVAILABLE,
          CART_ERROR_STATUS.PRODUCT_DISABLED,
        ])}
        buttonClick={placeOrder}
        isSelectedDeliveryChannel={isSelectedDeliveryChannelAndPaymentMethod}
      />
    </StyledBoxCartStepTwo>
  );
};

export default BoxCartStepTwo;
