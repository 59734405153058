import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { PathStringType } from "../shared/portalPath/portalPath";

export type TTextContent = string;

interface IGoToPage {
  selectedGoToOption: SelectedGoToOption.GO_TO_PAGE;
  goToPageSetting: IGoToPageSetting;
  showLinkOptions?: boolean;
  goToRowSetting?: IGoToRowSetting;
  forceOrderInsideStack?: Record<string, PathStringType[]>;
  openPopupSetting?: IOpenPopupSetting;
  closePopupSetting?: IClosePopupSetting;
}

interface IGoToRow {
  selectedGoToOption: SelectedGoToOption.GO_TO_ROW;
  goToRowSetting: IGoToRowSetting;
  showLinkOptions?: boolean;
  goToPageSetting?: IGoToPageSetting;
  forceOrderInsideStack?: Record<string, PathStringType[]>;
  openPopupSetting?: IOpenPopupSetting;
  closePopupSetting?: IClosePopupSetting;
}

interface IChangeOrderInsideStack {
  selectedGoToOption: SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK;
  goToPageSetting?: IGoToPageSetting;
  goToRowSetting?: IGoToRowSetting;
  showLinkOptions?: boolean;
  forceOrderInsideStack: Record<string, PathStringType[]>;
  openPopupSetting?: IOpenPopupSetting;
  closePopupSetting?: IClosePopupSetting;
}

interface IOpenPopup {
  selectedGoToOption: SelectedGoToOption.OPEN_POPUP;
  goToRowSetting?: IGoToRowSetting;
  showLinkOptions?: boolean;
  goToPageSetting?: IGoToPageSetting;
  forceOrderInsideStack?: Record<string, PathStringType[]>;
  openPopupSetting: IOpenPopupSetting;
  closePopupSetting?: IClosePopupSetting;
}

interface IClosePopup {
  selectedGoToOption: SelectedGoToOption.CLOSE_POPUP;
  goToRowSetting?: IGoToRowSetting;
  showLinkOptions?: boolean;
  goToPageSetting?: IGoToPageSetting;
  forceOrderInsideStack?: Record<string, PathStringType[]>;
  openPopupSetting?: IOpenPopupSetting;
  closePopupSetting: IClosePopupSetting;
}

export type ILinkContent =
  | IGoToPage
  | IGoToRow
  | IChangeOrderInsideStack
  | IOpenPopup
  | IClosePopup;

export interface IImageContent {
  imageUrl: TUrl;
}

export interface IImageInlineContent {
  imageUrl?: TUrl;
}

export interface IButtonContent {
  heading: string;
  link: string | null;
}

export interface IGoToPageSetting {
  link: TUrl | null;
  openInNewTab: boolean;
}

type TRowId = string | number | null;

export interface IGoToRowSetting {
  rowId: TRowId;
}

export interface IOpenPopupSetting {
  rowId: TRowId;
}

export interface IClosePopupSetting {
  rowId: TRowId;
}

export enum SelectedGoToOption {
  GO_TO_PAGE = "GO_TO_PAGE",
  GO_TO_ROW = "GO_TO_ROW",
  CHANGE_ORDER_INSIDE_STACK = "CHANGE_ORDER_INSIDE_STACK",
  OPEN_POPUP = "OPEN_POPUP",
  CLOSE_POPUP = "CLOSE_POPUP",
}
