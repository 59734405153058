import React from "react";
import { ArticleListBoxType } from "@ecp-redux/dto/article.type";
import {
  BOX_ARTICLE_LIST_CONTENT_PATTERN,
  BOX_ARTICLE_LIST_PATTERN,
  StyledBoxArticleList,
} from "../../settingsPatterns/boxes/BoxArticleList/StyledBoxArticleList";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { useFetchArticleList } from "./BoxArticleList.methods";
import { IBoxArticleListProps } from "./BoxArticleList.types";
import BoxArticleListGrid from "./views/BoxArticleListGrid/BoxArticleListGrid";
import BoxArticleListSlider from "./views/BoxArticleListSlider";

const BoxArticleList: React.FC<IBoxArticleListProps> = (props) => {
  const { settings } = usePrepareSettings({
    props: {
      ...props.displaySettings,
      ...props.contentSettings,
    },
    settingsPattern: BOX_ARTICLE_LIST_PATTERN(),
    options: {
      includeTheme: true,
      themePath: "advanceSettings.settings",
      themeWrapperPath: "image",
    },
  });
  const content = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_ARTICLE_LIST_CONTENT_PATTERN(),
  }).settings;

  const { articles } = useFetchArticleList(content, settings, props.editMode);

  return articles?.length === 0 && !props.editMode ? null : (
    <StyledBoxArticleList
      $settings={settings}
      className="article-list article-list-custom-style"
    >
      {articles?.length !== 0 && (
        <StyledText
          $settings={settings.heading}
          className="article-list__heading"
        >
          {content.heading}
        </StyledText>
      )}
      {settings.article.type === ArticleListBoxType.SLIDER && (
        <BoxArticleListSlider
          settings={settings}
          content={content}
          editMode={props.editMode}
        />
      )}

      {settings.article.type === ArticleListBoxType.GRID && (
        <BoxArticleListGrid
          settings={settings}
          content={content}
          editMode={props.editMode}
        />
      )}
    </StyledBoxArticleList>
  );
};

export default BoxArticleList;
