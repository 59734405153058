import React from "react";
import styled from "styled-components";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import Popup from "../../../shared/components/Popup/Popup";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { StyledCancelOrderPopup } from "../BoxMyAccountMyOrders.styled";

interface ICancelOrderPopupProps {
  closePopup: () => void;
  onConfirmClick: () => void;
  settings: {
    popup_title_typo: string;
    popup_description_typo: string;
    popup_description_color: string;
    popup_title_color: string;
    popup_yes_button: string;
    popup_no_button: string;
  };
  messages: {
    popup_title: string;
    popup_description: string;
    button_save: string;
    button_cancel: string;
  };
}

const StyledCancelOrderPopupWrapper = styled(Popup)`
  padding: 30px;
}`;

const CancelOrderPopup: React.FC<ICancelOrderPopupProps> = ({
  closePopup,
  onConfirmClick,
  settings,
  messages,
}) => {
  return (
    <StyledCancelOrderPopupWrapper
      desktopWidth="600px"
      className="cancel-order-popup"
      closePopup={closePopup}
    >
      <StyledCancelOrderPopup>
        <StyledText
          className="cancel-order-popup__title"
          $settings={{
            font: settings.popup_title_typo,
            text: { color: settings.popup_title_color },
          }}
          show={isNotEmptyMessage(messages.popup_title)}
        >
          {messages.popup_title}
        </StyledText>
        <StyledText
          className="cancel-order-popup__description"
          $settings={{
            font: settings.popup_description_typo,
            text: { color: settings.popup_description_color },
          }}
          show={isNotEmptyMessage(messages.popup_description)}
        >
          {messages.popup_description}
        </StyledText>
        <div className="cancel-order-popup__button-container">
          <StyledButton
            className="cancel-order-popup__button-container__confirm-button"
            $settings={settings.popup_yes_button}
            renderAs="button"
            data-testid="yesPopupBtn"
            onClick={onConfirmClick}
            show={isNotEmptyMessage(messages.button_save)}
          >
            {messages.button_save}
          </StyledButton>
          <StyledButton
            className="cancel-order-popup__button-container__cancel-button"
            $settings={settings.popup_no_button}
            renderAs="button"
            onClick={closePopup}
            show={isNotEmptyMessage(messages.button_cancel)}
          >
            {messages.button_cancel}
          </StyledButton>
        </div>
      </StyledCancelOrderPopup>
    </StyledCancelOrderPopupWrapper>
  );
};

export default CancelOrderPopup;
