import React from "react";
import { useTheme } from "styled-components";
import { convertButtonIdToValues } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import StyledButtonWrapper, {
  IButtonSettings,
} from "./StyledButtonWrapper.styled";

interface IStyledButtonProps extends IButtonSettings {
  renderAs?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  role?: string;
  show?: boolean;
  type?: string;
  target?: boolean;
  disabled?: boolean;
}

const StyledButton: React.FC<IStyledButtonProps> = ({
  $settings,
  className,
  onClick,
  renderAs,
  children,
  role,
  show = true,
  type,
  target,
  disabled = false,
}) => {
  const theme = useTheme() as IThemeState;
  const buttonSettings = convertButtonIdToValues(
    $settings,
    theme?.globalObjects?.buttons
  );

  const isSvg = buttonSettings.icon.iconUrl?.endsWith(".svg");

  return (
    <StyledButtonWrapper
      $settings={$settings}
      className={className}
      onClick={() => onClick && onClick()}
      renderAs={renderAs}
      data-testid="styled-button-component"
      role={role}
      show={show}
      type={type}
      target={target}
      disabled={disabled}
    >
      <>
        {isSvg ? (
          <div
            style={{
              width: buttonSettings.icon.size,
              height: buttonSettings.icon.size,
            }}
            className="svg"
          >
            <i />
          </div>
        ) : (
          <i />
        )}

        {children}
      </>
    </StyledButtonWrapper>
  );
};

export default StyledButton;
