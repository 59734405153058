import React from "react";
import { TemporaryDescendant, serializeHTML } from "../../helpers/htmlHelpers";
import { StyledBoxText } from "../../settingsPatterns/boxes/BoxText/StyledText";
import StyledRichText from "../../settingsPatterns/elements/StyledRichText/StyledRichText";
import {
  BoxTextContentScheme,
  BoxTextContentSettingsScheme,
  BoxTextDisplaySettingsScheme,
} from "./BoxText.scheme";
import { IBoxTextProps } from "./BoxText.types";

const BoxText: React.FC<IBoxTextProps> = (props) => {
  const content = BoxTextContentScheme.parse(props.content);
  const contentSettings = BoxTextContentSettingsScheme.parse(
    props.contentSettings
  );
  const displaySettings = BoxTextDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const combinedSettings = { ...contentSettings, ...displaySettings };

  //Here we used Descendant typing but it caused typing errors so temporarly we added TemporaryDescendant type.
  const descendantValue: TemporaryDescendant[] = JSON.parse(content.text);

  return (
    <StyledBoxText
      $settings={combinedSettings}
      className="text-container text-custom-style"
      data-testid="text-container"
    >
      <StyledRichText
        $settings={combinedSettings}
        className="text-container__rich-text"
      >
        {descendantValue.map((el, idx) =>
          serializeHTML(el, idx, combinedSettings.link)
        )}
      </StyledRichText>
    </StyledBoxText>
  );
};

export default BoxText;
