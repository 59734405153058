import React, { useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StyledSelectStoreFromMapPopup } from "../../../boxes/BoxMyAccountMyAdressList/elements/SelectStoreFromMapPopup";
import Head from "next/head";
import Script from "next/script";

interface NewAddressFormProps {
  disableForm: () => void;
  onPointSelect: (point: any) => void;
}

declare global {
  interface Window {
    onPoint: any;
  }
}

const StyledInpostAddressMap = styled.div`
  height: 100%;
`;

const InpostAddressMap: React.FC<NewAddressFormProps> = ({
  disableForm,
  onPointSelect,
}) => {
  const {
    advanceSettings: { settings: globalSettings },
  } = useTheme() as IThemeState;
  const token = globalSettings.inpost_geowidget_token.trim();
  const scriptLoaded = useRef(false);

  useEffect(() => {
    window.onPoint = onPointSelect;

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://geowidget.inpost.pl/inpost-geowidget.css";
    document.head.appendChild(link);

    if (!scriptLoaded.current) {
      const script = document.createElement("script");
      script.src = "https://geowidget.inpost.pl/inpost-geowidget.js";
      script.async = true;
      script.onload = () => {
        scriptLoaded.current = true;
      };
      document.body.appendChild(script);
    }

    return () => {
      document.head.removeChild(link);
      if (scriptLoaded.current) {
        const script = document.querySelector(
          'script[src="https://geowidget.inpost.pl/inpost-geowidget.js"]'
        );
        if (script) {
          document.body.removeChild(script);
        }
        scriptLoaded.current = false;
      }
    };
  }, [onPointSelect, disableForm]);

  return (
    <StyledSelectStoreFromMapPopup
      style={{ height: "90vh" }}
      closePopup={disableForm}
    >
      <StyledInpostAddressMap className="inpost-geowidget">
        <inpost-geowidget
          onpoint="onPoint"
          token={token}
          language="pl"
          config="parcelcollect"
        />
      </StyledInpostAddressMap>
    </StyledSelectStoreFromMapPopup>
  );
};

export default InpostAddressMap;
