import styled, { css } from "styled-components";
import {
  GlobalTableCellObject,
  GlobalTableCellReadoutValue,
  TableCellId,
  TableCellSet,
  defaultTableCellPattern,
} from "@ecp-redux/dto/themeSettings/GlobalTableCell.types";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss, {
  chooseHorizontalAlignValue,
  chooseVerticalAlignValue,
} from "../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { StyledBadgeContent } from "../domain/Product/ProductModule/BadgesList/BadgesList.styled";

export const isTableCellId = (
  v: GlobalTableCellReadoutValue | TableCellId
): v is TableCellId => {
  return typeof (v as TableCellId) === "string";
};

export const convertTableCellIdToValues = (
  value: GlobalTableCellReadoutValue | TableCellId,
  tableCells?: TableCellSet
): GlobalTableCellObject => {
  const { id, name, ...fallbackTableCell } = defaultTableCellPattern();

  if (value === undefined) return fallbackTableCell;
  if (isTableCellId(value) && tableCells !== undefined) {
    return (
      tableCells?.find((key) => key.id === value) ??
      tableCells[0] ??
      fallbackTableCell
    );
  } else if (!isTableCellId(value) && value.id !== "custom") {
    return (
      tableCells?.find((key) => key.id === value.id) ??
      tableCells?.[0] ??
      fallbackTableCell
    );
  } else if (!isTableCellId(value) && value.id === "custom") {
    return value.custom;
  }

  return fallbackTableCell;
};

interface IStyledTableCell {
  tableCellStyleId: GlobalTableCellReadoutValue | TableCellId;
  isOdd?: boolean;
  height?: number;
  columnWithBadges?: boolean;
  actionAlignment?: AlignmentHorizontalOption;
}

export const StyledTableCell = styled.div<IStyledTableCell>(
  ({
    tableCellStyleId,
    isOdd = true,
    height,
    columnWithBadges,
    theme,
    actionAlignment,
  }) => {
    const tableCellObject = convertTableCellIdToValues(
      tableCellStyleId,
      theme.globalObjects.tableCells
    );
    const {
      padding,
      textStyle,
      textColor,
      border,
      alignmentHorizontal,
      alignmentVertical,
      oddBackgroundColor,
      evenBackgroundColor,
    } = tableCellObject;

    const isMobile = useIsMobilePortal();
    return css`
      width: inherit;
      height: inherit;
      background: ${isOdd
        ? `${convertColorIdToHex(oddBackgroundColor.color, theme.colorPalette)}`
        : `${convertColorIdToHex(
            evenBackgroundColor.color,
            theme.colorPalette
          )}`};
      display: flex;
      align-items: ${chooseVerticalAlignValue(alignmentVertical)};
      justify-content: ${actionAlignment
        ? chooseHorizontalAlignValue(actionAlignment)
        : chooseHorizontalAlignValue(alignmentHorizontal)};
      padding: ${composeCss.padding(padding)};
      ${composeCss.font(textStyle, theme.typography)}
      color: ${convertColorIdToHex(textColor, theme.colorPalette)};
      ${composeCss.borderWidth(border.width)};
      ${composeCss.borderStyle(border.style)};
      ${composeCss.borderRadius(border.radius)};
      ${composeCss.borderColor(border.color, theme)};
      :hover {
        background: ${isOdd
          ? `${convertColorIdToHex(
              oddBackgroundColor.hoverColor,
              theme.colorPalette
            )}`
          : `${convertColorIdToHex(
              evenBackgroundColor.hoverColor,
              theme.colorPalette
            )}`};
      }
      ${isMobile ? "height: 100%;" : ""}

      .imageContainer {
        width: 100%;

        span {
          height: ${height}px !important;
        }
      }

      ${columnWithBadges
        ? css`
            flex-wrap: wrap;
            column-gap: 4px;

            ${StyledBadgeContent} {
              overflow: hidden;

              .badge__name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border-radius: 4px;
              }
            }
          `
        : ""}
    `;
  }
);

interface IStyledText {
  spaceBetweenPrefixSuffix: number;
}

export const StyledTableText = styled.div<IStyledText>(
  ({ spaceBetweenPrefixSuffix }) => {
    return css`
      display: flex;
      flex-direction: row;
      gap: ${spaceBetweenPrefixSuffix}px;
      white-space: wrap;
    `;
  }
);
