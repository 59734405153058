import { useEffect } from "react";
import classNames from "classnames";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { useLinkContentActions } from "../../shared/hooks/useLinkContentAction";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import { checkIfButtonShouldBeDisabled } from "./BoxListOfButtons.methods";
import {
  BOX_LIST_OF_BUTTONS_CONTENT,
  BOX_LIST_OF_BUTTONS_CONTENT_SETTINGS,
  BOX_LIST_OF_BUTTONS_DISPLAY_SETTINGS,
} from "./BoxListOfButtons.pattern";
import { StyledListOfButtons } from "./BoxListOfButtons.styled";
import { IBoxListOfButtonsProps } from "./BoxListOfButtons.types";

const BoxListOfButtons: React.FC<IBoxListOfButtonsProps> = (props) => {
  const {
    getHref,
    linkContentActions,
    initFirstActivePaths,
    getIsActiveButton,
  } = useLinkContentActions(props.path, props.id);

  const displaySettings = usePrepareSettings({
    props: props.displaySettings,
    settingsPattern: BOX_LIST_OF_BUTTONS_DISPLAY_SETTINGS(),
  }).settings;

  const contentSettings = usePrepareSettings({
    props: props.contentSettings,
    settingsPattern: BOX_LIST_OF_BUTTONS_CONTENT_SETTINGS(),
  }).settings;

  const content = usePrepareSettings({
    props: props.content,
    settingsPattern: BOX_LIST_OF_BUTTONS_CONTENT(),
  }).settings;

  useEffect(() => {
    if (contentSettings.buttonsList.length > 0) {
      initFirstActivePaths(contentSettings.buttonsList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledListOfButtons
      className="list-of-buttons list-of-buttons-custom-style"
      boxWidth={displaySettings.boxWidth}
      spaceBetweenButtons={displaySettings.spaceBetweenButtons}
      spaceBetweenRows={displaySettings.spaceBetweenRows}
      alignment={displaySettings.alignment}
      padding={displaySettings.padding}
      vertical={displaySettings.vertical}
    >
      <div className="list-of-buttons__container">
        {contentSettings.buttonsList.map((button, index) => {
          const { isActiveButton, isBoxSelected } = getIsActiveButton(button);
          const href = getHref(button);

          return (
            <StyledButton
              {...(button
                ? {
                    href,
                    onClick: () => {
                      linkContentActions(button);
                    },
                  }
                : {})}
              target={button.goToPageSetting?.openInNewTab}
              className={classNames("list-of-buttons__container__item", {
                "active-button": isActiveButton,
                "disabled-button": checkIfButtonShouldBeDisabled(
                  isBoxSelected,
                  button.selectedGoToOption,
                  href
                ),
              })}
              $settings={displaySettings.buttonsStyle}
              key={`${content.buttonsLabels[index]}${index}`}
            >
              {content.buttonsLabels[index]}
            </StyledButton>
          );
        })}
      </div>
    </StyledListOfButtons>
  );
};
export default BoxListOfButtons;
