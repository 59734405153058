import { useRouter } from "next/router";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { StyledBackground } from "../../../shared/styleElements/StyledBackground/StyledBackground.styled";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountHeaderMessages,
  IBoxMyAccountHeaderSettings,
} from "../BoxMyAccountHeader.types";
import { StyledWrapperMyAccountHeader } from "./StyledWrapperMyAccountHeader.styled";

const BoxMyAccountHeaderDropdown: React.FC = () => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountHeaderMessages,
    IBoxMyAccountHeaderSettings
  >();

  const router = useRouter();

  const registerWrapperContainElements =
    messages.register_title ||
    messages.register_button ||
    messages.button_element_1 ||
    messages.button_element_2 ||
    messages.button_element_3;

  return (
    <StyledWrapperMyAccountHeader
      {...messages}
      {...settings}
      data-testid="dropdown"
      className="my-account-header-container__dropdown"
    >
      {(messages.login_title || messages.login_button) && (
        <StyledBackground
          backgroundColor={settings.login_background}
          className="background-login"
        >
          <StyledText
            $settings={{
              font: settings.title_typo,
              text: { color: settings.title_color },
            }}
            className="my-account-header-container__dropdown__title"
            show={isNotEmptyMessage(messages.login_title)}
          >
            {messages.login_title}
          </StyledText>
          {messages.login_button && (
            <StyledButton
              className="button-login my-account-header-container__dropdown__login-button"
              $settings={settings.login_button}
              renderAs={"button"}
              onClick={() => router.push(settings.login_url)}
              show={isNotEmptyMessage(messages.login_button)}
            >
              {messages.login_button}
            </StyledButton>
          )}
        </StyledBackground>
      )}
      {registerWrapperContainElements && (
        <StyledBackground
          backgroundColor={settings.register_background}
          className="background-register"
          data-testid="background-register"
        >
          <div className="my-account-header-container__dropdown__register-container">
            <StyledText
              $settings={{
                font: settings.title_typo,
                text: { color: settings.title_color },
              }}
              className="my-account-header-container__dropdown__register-container__title"
              show={isNotEmptyMessage(messages.register_title)}
            >
              {messages.register_title}
            </StyledText>

            <StyledButton
              className="button-register my-account-header-container__dropdown__register-container__button"
              $settings={settings.register_button}
              renderAs="button"
              onClick={() => router.push(settings.register_url)}
              show={isNotEmptyMessage(messages.register_title)}
            >
              {messages.register_button}
            </StyledButton>
          </div>

          <StyledButton
            $settings={settings.element_1_button}
            renderAs="div"
            className="my-account-header-container__dropdown__button-element-1"
            show={isNotEmptyMessage(messages.button_element_1)}
          >
            {messages.button_element_1}
          </StyledButton>

          <StyledButton
            $settings={settings.element_2_button}
            renderAs={"div"}
            className="my-account-header-container__dropdown__button-element-2"
            show={isNotEmptyMessage(messages.button_element_2)}
          >
            {messages.button_element_2}
          </StyledButton>

          <StyledButton
            $settings={settings.element_3_button}
            renderAs="div"
            className="my-account-header-container__dropdown__button-element-3"
            show={isNotEmptyMessage(messages.button_element_3)}
          >
            {messages.button_element_3}
          </StyledButton>
        </StyledBackground>
      )}
    </StyledWrapperMyAccountHeader>
  );
};

export default BoxMyAccountHeaderDropdown;
