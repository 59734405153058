import React from "react";
import LinkWrapper from "../../global/components/LinkWrapper/LinkWrapper";
import { useLinkContentActions } from "../../shared/hooks/useLinkContentAction";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import {
  BoxButtonContentScheme,
  BoxButtonContentSettingsScheme,
} from "./BoxButton.scheme";
import { StyledButtonAlignment } from "./BoxButton.styled";
import { IBoxButtonProps } from "./BoxButton.types";

const BoxButton: React.FC<IBoxButtonProps> = (props) => {
  const { linkContentActions, getHref } = useLinkContentActions(props.path);

  const contentSettings = BoxButtonContentSettingsScheme.parse(
    props.contentSettings
  );

  const content = BoxButtonContentScheme.parse(props.content);

  return (
    <StyledButtonAlignment
      alignment={contentSettings.buttonAlignment}
      className="button-container__alignment button-custom-style"
    >
      <LinkWrapper
        href={getHref(content.buttonLink)}
        openInNewTab={content.buttonLink.goToPageSetting?.openInNewTab}
      >
        <StyledButton
          $settings={contentSettings.buttonStyle}
          className="button-container"
          onClick={() => linkContentActions(content.buttonLink)}
          renderAs="button"
        >
          {content.buttonLabel}
        </StyledButton>
      </LinkWrapper>
    </StyledButtonAlignment>
  );
};

export default BoxButton;
