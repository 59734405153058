import { useState } from "react";
import classNames from "classnames";
import { AlignmentVerticalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { StyledTableWrapper } from "./Table.styled";
import { SortingType, TableProps } from "./Table.types";
import TableHeaderCell from "./elements/TableHeaderCell";
import TablePagination from "./elements/TablePagination";
import TableRowCell from "./elements/TableRowCell";
import MobileTable from "./mobile/MobileTable";
import { TableContextProvider } from "./tableContext/useTableContext";

const Table: React.FC<TableProps> = ({
  columns,
  rows,
  onSort,
  pagination,
  tableSettings,
  className,
  mobileClassName,
}) => {
  const [sort, setSort] = useState<{ key: string; direction: SortingType }>({
    key: "",
    direction: "none",
  });

  const handleSort = (key: string, direction: SortingType) => {
    setSort({ key, direction });
    onSort(key, direction);
  };

  const isMobile = useIsMobilePortal();

  if (isMobile) {
    return (
      <TableContextProvider tableSettings={tableSettings}>
        <MobileTable
          columns={columns}
          rows={rows}
          onSort={onSort}
          pagination={pagination}
          tableSettings={tableSettings}
          mobileClassName={mobileClassName}
        />
      </TableContextProvider>
    );
  } else {
    return (
      <TableContextProvider tableSettings={tableSettings}>
        <StyledTableWrapper
          tableSettings={tableSettings}
          data-testid="TableWrapper"
          className={classNames("table-custom-style", className)}
        >
          <div className="table-wrapper">
            {pagination &&
              tableSettings.pagination.show &&
              tableSettings.pagination.alignment.vertical !==
                AlignmentVerticalOption.BOTTOM && (
                <TablePagination
                  pagination={pagination}
                  paginationStyle={tableSettings.pagination}
                />
              )}
            <table className="table">
              <thead className="table__header">
                <tr>
                  {columns.map((column, columnIndex) => (
                    <>
                      <TableHeaderCell
                        key={column.key}
                        column={column}
                        tableSettings={tableSettings}
                        handleSort={handleSort}
                        sort={sort}
                      />
                      {columnIndex !== columns.length - 1 && (
                        <td className="column_spacing"></td>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody className="table__rows">
                {rows.map((row, index) => (
                  <>
                    <tr id="row" className="table__rows__row" key={index}>
                      {columns.map((column, columnIndex) => (
                        <>
                          <TableRowCell
                            key={column.key}
                            column={column}
                            tableSettings={tableSettings}
                            cellComponent={row[column.key].component(
                              index % 2 === 0,
                              tableSettings.table.rowHeight
                            )}
                          />
                          {columnIndex !== columns.length - 1 && (
                            <td className="column_spacing"></td>
                          )}
                        </>
                      ))}
                    </tr>
                    {index !== rows.length - 1 && (
                      <tr
                        className="table__rows__rowSpacing"
                        key={`spacing-${index}`}
                      />
                    )}
                  </>
                ))}
              </tbody>
            </table>
            {pagination &&
              tableSettings.pagination.show &&
              tableSettings.pagination.alignment.vertical !==
                AlignmentVerticalOption.TOP && (
                <TablePagination
                  pagination={pagination}
                  paginationStyle={tableSettings.pagination}
                />
              )}
          </div>
        </StyledTableWrapper>
      </TableContextProvider>
    );
  }
};

export default Table;
