import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { format } from "date-fns";
import { useTheme } from "styled-components";
import * as cartB2B from "@ecp-redux/api/cartB2B";
import { searchResults } from "@ecp-redux/api/searchResults";
import {
  IOrderSummaryB2B,
  PaymentMethodOptionB2B,
} from "@ecp-redux/dto/cartB2B.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice, isPortalSide } from "../../../helpers/helpers";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  StyledOrderSummaryContainer,
  StyledSummary,
  StyledSummaryB2BContainer,
} from "./BoxSummaryB2B.styled";
import {
  IBoxSummaryB2BMessages,
  IBoxSummaryB2BProps,
  IBoxSummaryB2BSettings,
} from "./BoxSummaryB2B.types";
import DesktopSummaryB2B from "./components/DesktopSummaryB2B";
import MobileSummaryB2B from "./components/MobileSummaryB2B";
import { SummaryValue } from "./components/SummaryValue";

const BoxSummaryB2B: React.FC<IBoxSummaryB2BProps> = () => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const {
    messages,
    settings: {
      basket_success_page_orders_spacing,
      basket_success_header_section_typo,
      basket_success_header_section_color,
      basket_success_header_line_size,
      basket_success_header_line_color,
      basket_success_page_orders_list_background_color,
    },
  } = useMessagesSettingsContext<
    IBoxSummaryB2BMessages,
    IBoxSummaryB2BSettings
  >();

  const router = useRouter();
  const cartId = router?.query?.["cartId"];
  const isMobile = useIsMobilePortal();
  const [orders, setOrders] = useState<IOrderSummaryB2B[]>([]);

  const [getCartSummary] = cartB2B.useLazyGetSummaryB2BQuery();
  const [getPostProducts] = searchResults.useLazyGetPostProductsQuery();

  useEffect(() => {
    if (cartId && typeof cartId === "string") {
      getCartSummary(cartId)
        .unwrap()
        .then((response) => {
          if (
            !response?.contractorOrderResponseList ||
            !response.contractorOrderResponseList.length
          )
            return;

          const skus = response.contractorOrderResponseList
            .map((position) =>
              position.orderPositions.map((position) => position.sku)
            )
            .flat();

          getPostProducts({
            skus: skus,
            projection: ["sku", "name", "coverPhoto"],
          })
            .unwrap()
            .then((products) => {
              setOrders([
                ...response.contractorOrderResponseList.map((order) => ({
                  ...order,
                  orderPositions: order.orderPositions.map((position) => {
                    const currentProduct = products?.products?.find(
                      (product) => product.sku === position.sku
                    );

                    return {
                      ...position,
                      coverPhoto: currentProduct?.coverPhoto ?? null,
                      name: currentProduct?.name ?? "",
                    };
                  }),
                })),
              ]);
            });
        });
    }
  }, [cartId]);

  const memoizedOrders = useMemo(
    () =>
      orders.length === 0 && !isPortalSide()
        ? [
            {
              creationDate: new Date().toISOString(),
              grossValue: 0,
              netValue: 0,
              orderId: "-",
              orderPositions: [],
              paymentForm: PaymentMethodOptionB2B.CASH,
              promiseDeliveryDate: null,
            },
          ]
        : orders,
    [orders]
  );

  return (
    <StyledSummaryB2BContainer $spacing={basket_success_page_orders_spacing}>
      {memoizedOrders.map((order) => (
        <StyledOrderSummaryContainer
          key={order.orderId}
          backgroundColor={basket_success_page_orders_list_background_color}
        >
          <StyledText
            className="summary-b2b-order__header"
            $settings={{
              font: basket_success_header_section_typo,
              text: {
                color: basket_success_header_section_color,
              },
            }}
          >
            {messages.basket_success_single_order_title_label} - {order.orderId}
          </StyledText>
          <StyledSummary>
            <SummaryValue
              headerText={
                messages.basket_success_single_order_value_netto_label
              }
              value={formatPrice(
                order.netValue,
                globalMessages["currencyShort"]
              )}
            />
            <SummaryValue
              headerText={
                messages.basket_success_single_order_delivery_date_label
              }
              value={
                order.promiseDeliveryDate ?? globalMessages.delivery_unknown
              }
            />
            <SummaryValue
              headerText={
                messages.basket_success_single_order_value_gross_label
              }
              value={formatPrice(
                order.grossValue,
                globalMessages["currencyShort"]
              )}
            />
            <SummaryValue
              headerText={
                messages.basket_success_single_order_payment_method_label
              }
              value={
                order.paymentForm === PaymentMethodOptionB2B.CASH
                  ? messages.basket_success_credit_bar_dropdown_cash_on_delivery
                  : messages.basket_success__credit_bar_dropdown_credit_limit
              }
            />
            <SummaryValue
              headerText={messages.basket_success_single_order_order_date_label}
              value={format(new Date(order.creationDate), "yyyy-MM-dd HH:mm")}
            />
          </StyledSummary>
          {order.orderPositions.length !== 0 && isMobile ? (
            <MobileSummaryB2B
              messages={messages}
              order={order}
              settings={{
                basket_success_header_line_size,
                basket_success_header_line_color,
              }}
            />
          ) : (
            <DesktopSummaryB2B
              messages={messages}
              order={order}
              settings={{
                basket_success_header_line_size,
                basket_success_header_line_color,
              }}
            />
          )}
        </StyledOrderSummaryContainer>
      ))}
    </StyledSummaryB2BContainer>
  );
};

export default BoxSummaryB2B;
