import React, { useMemo, useState } from "react";
import { isNotEmptyMessage } from "../../../../../helpers/isNotEmptyMessage";
import Alert from "../../../../../shared/components/Alert/Alert";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";
import { ReactComponent as PenIcon } from "../../../../../shared/icons/pen.svg";
import { StyledBackground } from "../../../../../shared/styleElements/StyledBackground/StyledBackground.styled";
import StyledButton from "../../../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { StyledPayments } from "../../BoxCartStepTwo.styled";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../../BoxCartStepTwo.types";
import { IPaymentsProps } from "./Payments.types";
import PaymentElement from "./elements/PaymentElement";

const Payments: React.FC<IPaymentsProps> = ({ payments }) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();

  const isMobile = useIsMobilePortal();

  const [editPayment, setEditPayment] = useState(false);

  const selectedPayment = useMemo(
    () => payments.find((e) => e.selected),
    [payments]
  );

  return (
    <StyledPayments className="payments">
      <StyledText
        className="payments__payment-header"
        $settings={{
          font: settings.basket_step_2_3_typography_header_1,
          text: { color: settings.basket_step_all_color_main },
        }}
        show={isNotEmptyMessage(messages.payment_header)}
      >
        <p>{messages.payment_header}</p>
      </StyledText>

      <Alert
        alertsCodes={["1016"]}
        backgroundColor={settings.basket_alert_background}
        textColor={settings.basket_alert_typo_color}
        typography={settings.basket_alert_typo}
        closeable={false}
        className="payments__alert"
        removeWhen={!!selectedPayment}
      />

      {!editPayment && selectedPayment ? (
        <StyledBackground
          className="payments__payment-method-background"
          backgroundColor={
            settings.basket_step_all_background_adress_payment_color
          }
        >
          <StyledText
            className="payments__payment-method-text"
            $settings={{
              font: settings.basket_step_2_3_typography_paragraph_4,
              text: { color: settings.basket_step_all_color_main },
            }}
            show={isNotEmptyMessage(messages.payment_method)}
          >
            <p>{messages.payment_method}</p>

            <button
              className="payments__edit-payment-button"
              onClick={() => setEditPayment(true)}
              data-testid="editPaymentBtn"
            >
              <PenIcon />
            </button>
          </StyledText>
          <StyledText
            className="payments__payment-type"
            $settings={{
              font: settings.basket_step_2_typography_paragraph_3,
              text: { color: settings.basket_step_all_color_main },
            }}
            show={isNotEmptyMessage(
              messages[`payment_name_${selectedPayment.paymentType}`]
            )}
          >
            <p>{messages[`payment_name_${selectedPayment.paymentType}`]}</p>
          </StyledText>
        </StyledBackground>
      ) : (
        <div className="payments__payments-methods-container">
          <div className="payments__payments-methods-container__content">
            {payments.map((payment) => {
              if (
                isNotEmptyMessage(
                  messages[`payment_name_${payment.paymentType}`]
                )
              ) {
                return (
                  <PaymentElement
                    payment={payment}
                    paymentName={
                      messages[`payment_name_${payment.paymentType}`]
                    }
                    closeEditAddress={() => setEditPayment(false)}
                    key={payment.paymentType}
                  />
                );
              }
              return null;
            })}
          </div>

          <StyledButton
            className="payments__payment-button"
            $settings={settings.basket_step_all_back_button}
            renderAs="button"
            onClick={() => setEditPayment(false)}
            data-testid="backPaymentBtn"
            show={
              isNotEmptyMessage(messages.delivery_back_button) &&
              selectedPayment?.selected === true &&
              !isMobile
            }
          >
            {messages.delivery_back_button}
          </StyledButton>
        </div>
      )}
    </StyledPayments>
  );
};

export default Payments;
