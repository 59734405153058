import { useEffect } from "react";
import { PageCategoryType } from "@ecp-pageTypes";
import * as searchResults from "@ecp-redux/api/searchResults";
import { useCheckPageContext } from "../../helpers/pageContext";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { BOX_PRODUCT_VARIANTS_CONTENT_SETTINGS_PATTERN } from "./BoxProductVariants.pattern";
import { BoxProductVariantsProps } from "./BoxProductVariants.types";

const useBoxProductVariants = (props: BoxProductVariantsProps) => {
  const pageContext = useCheckPageContext(PageCategoryType.PRODUCT);
  const contextProductSKU =
    pageContext.type === PageCategoryType.PRODUCT
      ? pageContext.productSku
      : undefined;

  const [getProductData, productData] =
    searchResults.useLazyGetPostProductsQuery();

  const contentSettings = usePrepareSettings({
    props: { ...props.contentSettings },
    settingsPattern: BOX_PRODUCT_VARIANTS_CONTENT_SETTINGS_PATTERN(),
  }).settings;

  useEffect(() => {
    if (!contextProductSKU || !contentSettings.variants.length) return;

    getProductData({
      projection: ["axis", "variants"],
      skus: [contextProductSKU],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextProductSKU, contentSettings.variants.length]);

  const selectedVariant = contentSettings.variants.find(
    (variant) => variant.code === productData?.data?.products?.[0]?.axis
  );
  const productVariants = productData?.data?.products?.[0]?.variants;

  return {
    contentSettings,
    selectedVariant,
    productVariants,
    pageContext,
    contextProductSKU,
  };
};

export default useBoxProductVariants;
