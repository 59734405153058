import { useState } from "react";
import { useTheme } from "styled-components";
import * as myOrdersAPI from "@ecp-redux/api/myOrders";
import { IOrderDetailsResponse } from "@ecp-redux/dto/myOrders.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import LinkWrapper from "../../../global/components/LinkWrapper/LinkWrapper";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { useProductInfo } from "../../../shared/hooks/useProductInfo";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { Popups } from "../../BoxMyAccountMyOrders/OrderActionButtons/OrderActionButtons";
import CancelOrderPopup from "../../BoxMyAccountMyOrders/popups/CancelOrderPopup";
import {
  StyledOrderDetailsWrapper,
  StyledOrderGapWrapper,
} from "../BoxMyAccountMyOrdersDetails.styled";
import {
  IBoxMyAccountMyOrdersDetailsMessages,
  IBoxMyAccountMyOrdersDetailsSettings,
} from "../BoxMyAccountMyOrdersDetails.types";
import ColumnInfoText from "../ColumnInfoText/ColumnInfoText";
import { getMyOrderDeliveryDetailsElements } from "./MyOrderDeliveryDetails.methods";
import MyOrderDeliveryProducts from "./MyOrderDeliveryProducts/MyOrderDeliveryProducts";

export interface IMyOrderDeliveryDetailsProps {
  deliveryData: IOrderDetailsResponse["order"]["portalOrderDispatches"]["0"];
  orderUuid: string;
}

const MyOrderDeliveryDetails: React.FC<IMyOrderDeliveryDetailsProps> = ({
  deliveryData,
  orderUuid,
}) => {
  const {
    advanceSettings: { messages: globalMessages, settings: globalSettings },
  } = useTheme() as IThemeState;
  const [showPopup, setShowPopup] = useState<Popups>(Popups.NONE);

  const [cancelDispatch] =
    myOrdersAPI.usePutMyOrdersDetailsDispatchCancelMutation();

  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersDetailsMessages,
    IBoxMyAccountMyOrdersDetailsSettings
  >();

  const { getProductInfo, isLoadingProduct } = useProductInfo(
    deliveryData.products
  );

  const isMobile = useIsMobilePortal();

  const handleCancelDispatch = () => {
    cancelDispatch({
      orderUuid,
      orderDispatchUuid: deliveryData.orderDispatchUuid,
    }).then(() => {
      setShowPopup(Popups.NONE);
    });
  };

  if (isLoadingProduct) {
    return null;
  }

  return (
    <StyledOrderDetailsWrapper
      backgroundColorId={settings.customer_data_order_details_background_color}
      className="my-order-details-delivery"
    >
      <div className="my-order-details-delivery__container">
        <StyledOrderGapWrapper
          className="my-order-details-delivery__container__gap-container"
          elementsGap={
            settings.customer_data_order_details_dispatch_delivery_elements_gap
          }
        >
          <StyledText
            className="my-order-details-delivery__container__gap-container__dispatch-number"
            $settings={{
              font: settings.customer_data_order_details_dispatch_number_typo,
              text: {
                color:
                  settings.customer_data_order_details_dispatch_number_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_order_details_dispatch_number
            )}
          >
            {`${messages.customer_data_order_details_dispatch_number} ${deliveryData.orderDispatchId}`}
          </StyledText>

          <StyledButton
            className="my-order-details-delivery__container__dispatch-cancel-button"
            onClick={() => setShowPopup(Popups.CANCEL_DISPATCH)}
            renderAs="button"
            $settings={
              settings.customer_data_order_details_cancel_dispatch_button_style
            }
            show={
              isNotEmptyMessage(
                messages.customer_data_orders_dispatch_cancel_button
              ) &&
              isMobile &&
              deliveryData.allowedOperations.canBeCancelled
            }
          >
            {messages.customer_data_orders_dispatch_cancel_button}
          </StyledButton>

          {getMyOrderDeliveryDetailsElements(
            messages,
            settings,
            deliveryData,
            globalMessages["currencyShort"]
          ).map((elem, index) => (
            <ColumnInfoText {...elem} key={index} />
          ))}
          <div className="my-order-details-delivery__container__gap-container__dispatch-letter-container">
            <div className="my-order-details-delivery__container__gap-container__dispatch-letter-container__dispatch-letter__header__container">
              <StyledText
                className="my-order-details-delivery__container__gap-container__dispatch-letter-container__dispatch-letter__header"
                $settings={{
                  font: settings.customer_data_order_details_dispatch_letter_label_typo,
                  text: {
                    color:
                      settings.customer_data_order_details_dispatch_letter_label_color,
                  },
                }}
                show={isNotEmptyMessage(
                  messages.customer_data_order_details_dispatch_letter
                )}
              >
                {`${messages.customer_data_order_details_dispatch_letter}: `}
              </StyledText>
            </div>
            <LinkWrapper
              linkStyle={
                settings.customer_data_order_details_dispatch_letter_value_link
              }
              fontStyle={
                settings.customer_data_order_details_dispatch_letter_value_typo
              }
              href={""}
            >
              {deliveryData.documents?.at(0) ??
                messages.customer_data_order_details_trackingnumber_not_assigned_info}
            </LinkWrapper>
          </div>
        </StyledOrderGapWrapper>

        <StyledButton
          className="my-order-details-delivery__container__dispatch-cancel-button"
          onClick={() => setShowPopup(Popups.CANCEL_DISPATCH)}
          renderAs="button"
          $settings={
            settings.customer_data_order_details_cancel_dispatch_button_style
          }
          show={
            isNotEmptyMessage(
              messages.customer_data_orders_dispatch_cancel_button
            ) &&
            !isMobile &&
            deliveryData.allowedOperations.canBeCancelled
          }
        >
          {messages.customer_data_orders_dispatch_cancel_button}
        </StyledButton>
      </div>

      {deliveryData.products.map((product) => (
        <MyOrderDeliveryProducts
          product={product}
          imageUrl={
            getProductInfo(product.productSku)?.coverPhoto ??
            globalSettings.dynamicBoxImagePlug
          }
          imageHref={getProductInfo(product.productSku)?.url ?? ""}
          key={product.productSku}
        />
      ))}

      {showPopup === Popups.CANCEL_DISPATCH && (
        <CancelOrderPopup
          closePopup={() => setShowPopup(Popups.NONE)}
          onConfirmClick={handleCancelDispatch}
          settings={settings}
          messages={{
            popup_title: messages.customer_data_cancel_dispatch_popup_title,
            popup_description:
              messages.customer_data_cancel_dispatch_popup_description,
            button_save:
              messages.customer_data_cancel_dispatch_popup_button_save,
            button_cancel:
              messages.customer_data_cancel_dispatch_popup_button_cancel,
          }}
        />
      )}
    </StyledOrderDetailsWrapper>
  );
};

export default MyOrderDeliveryDetails;
