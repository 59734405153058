import { z } from "zod";
import {
  ButtonIdScheme,
  ColorReadoutValueScheme,
  GlobalColorsStylesSchema,
  TypoReadoutValueScheme,
  UrlScheme,
  oldBorderSchema,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  SettingPositionOptions,
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const ImageContentSettingScheme = z.object({
  show: z.boolean().default(true).catch(true),
  alignment: z
    .object({
      vertical: z.nativeEnum(AlignmentVerticalOption),
      horizontal: z.nativeEnum(AlignmentHorizontalOption),
    })
    .default({
      vertical: AlignmentVerticalOption.CENTER,
      horizontal: AlignmentHorizontalOption.LEFT,
    }),
  backgroundUrl: z.string().default("").catch(""),
  opacity: z.number().default(100).catch(100),
  fit: z.boolean().default(false).catch(false),
  parallax: z.boolean().default(false).catch(false),
});

export const InnerBoxContentSettingScheme = z.object({
  show: z.boolean().default(true).catch(true),
  color: ColorReadoutValueScheme.default({
    id: "color$$3",
    custom: "#000000",
  }).catch({
    id: "color$$3",
    custom: "#000000",
  }),
  alignment: z
    .object({
      horizontal: z.nativeEnum(AlignmentHorizontalOption),
      vertical: z.nativeEnum(AlignmentVerticalOption),
    })
    .default({
      horizontal: AlignmentHorizontalOption.LEFT,
      vertical: AlignmentVerticalOption.BOTTOM,
    }),
  contentAlignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  textPadding: z
    .object({
      top: z.number(),
      right: z.number(),
      bottom: z.number(),
      left: z.number(),
    })
    .default({ top: 2, right: 2, bottom: 2, left: 2 })
    .catch({ top: 2, right: 2, bottom: 2, left: 2 }),
  width: z.number().default(40).catch(40),
});

export const createTitleContentSettingsScheme = (
  typoId?: TTypoId,
  colorId?: TColorId
) =>
  z.object({
    show: z.boolean().default(true).catch(true),
    font: TypoReadoutValueScheme.default({
      id: typoId ?? "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }).catch({
      id: typoId ?? "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }),
    text: z
      .object({
        color: ColorReadoutValueScheme.default({
          id: colorId ?? "color$$1",
          custom: "#000000",
        }).catch({ id: colorId ?? "color$$1", custom: "#000000" }),
      })
      .default({ color: { id: colorId ?? "color$$1", custom: "#000000" } })
      .catch({
        color: { id: colorId ?? "color$$1", custom: "#000000" },
      }),
  });

export const createTextStyleShowSettingScheme = (
  typoId?: TTypoId,
  colorId?: TColorId
) =>
  z.object({
    show: z.boolean(),
    font: TypoReadoutValueScheme.default({
      id: typoId ?? "typo$$2",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }).catch({
      id: typoId ?? "typo$$2",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }),
    text: z
      .object({
        color: ColorReadoutValueScheme,
      })
      .default({
        color: { id: colorId ?? "color$$2", custom: "#000000" },
      })
      .catch({ color: { id: colorId ?? "color$$2", custom: "#000000" } }),
  });

export const MainButtonContentSettingScheme = z.object({
  id: ButtonIdScheme.or(z.literal("custom"))
    .default("button$$1")
    .catch("button$$1"),
  show: z.boolean().default(true).catch(true),
  custom: z
    .object({
      horizontalPadding: z.number().default(35).catch(35),
      verticalPadding: z.number().default(8).catch(8),
      background: GlobalColorsStylesSchema.removeDefault()
        .default({
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$3", custom: "#000000" },
          disabledColor: { id: "color$$4", custom: "#000000" },
        })
        .catch({
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$3", custom: "#000000" },
          disabledColor: { id: "color$$4", custom: "#000000" },
        }),
      border: oldBorderSchema
        .merge(GlobalColorsStylesSchema.removeDefault())
        .default({
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$5", custom: "#000000" },
          hoverColor: { id: "color$$6", custom: "#000000" },
          activeColor: { id: "color$$7", custom: "#000000" },
          disabledColor: { id: "color$$8", custom: "#000000" },
        })
        .catch({
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$5", custom: "#000000" },
          hoverColor: { id: "color$$6", custom: "#000000" },
          activeColor: { id: "color$$7", custom: "#000000" },
          disabledColor: { id: "color$$8", custom: "#000000" },
        }),
      text: GlobalColorsStylesSchema.removeDefault()
        .default({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$10", custom: "#000000" },
          activeColor: { id: "color$$11", custom: "#000000" },
          disabledColor: { id: "color$$12", custom: "#000000" },
        })
        .catch({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$10", custom: "#000000" },
          activeColor: { id: "color$$11", custom: "#000000" },
          disabledColor: { id: "color$$12", custom: "#000000" },
        }),
      font: TypoReadoutValueScheme.default({
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      }).catch({
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      }),
      icon: z
        .object({
          iconUrl: UrlScheme.nullable(),
          position: z.nativeEnum(SettingPositionOptions),
          size: z.number(),
          spacing: z.number(),
          color: ColorReadoutValueScheme,
          hoverColor: ColorReadoutValueScheme,
        })
        .default({
          iconUrl: null,
          position: SettingPositionOptions.RIGHT,
          size: 16,
          spacing: 10,
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
        })
        .catch({
          iconUrl: null,
          position: SettingPositionOptions.RIGHT,
          size: 16,
          spacing: 10,
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
        }),
    })
    .default({
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$3", custom: "#000000" },
        disabledColor: { id: "color$$4", custom: "#000000" },
      },
      border: {
        radius: 4,
        width: 0,
        radiusV2: null,
        widthV2: null,
        color: { id: "color$$5", custom: "#000000" },
        hoverColor: { id: "color$$6", custom: "#000000" },
        activeColor: { id: "color$$7", custom: "#000000" },
        disabledColor: { id: "color$$8", custom: "#000000" },
      },
      text: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$10", custom: "#000000" },
        activeColor: { id: "color$$11", custom: "#000000" },
        disabledColor: { id: "color$$12", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      },
    })
    .catch({
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$3", custom: "#000000" },
        disabledColor: { id: "color$$4", custom: "#000000" },
      },
      border: {
        radius: 4,
        width: 0,
        radiusV2: null,
        widthV2: null,
        color: { id: "color$$5", custom: "#000000" },
        hoverColor: { id: "color$$6", custom: "#000000" },
        activeColor: { id: "color$$7", custom: "#000000" },
        disabledColor: { id: "color$$8", custom: "#000000" },
      },
      text: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$10", custom: "#000000" },
        activeColor: { id: "color$$11", custom: "#000000" },
        disabledColor: { id: "color$$12", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      },
    }),
});

export const SecondButtonContentSettingScheme = z.object({
  id: ButtonIdScheme.or(z.literal("custom"))
    .default("button$$1")
    .catch("button$$1"),
  show: z.boolean().default(true).catch(true),
  custom: z
    .object({
      horizontalPadding: z.number().default(35).catch(35),
      verticalPadding: z.number().default(8).catch(8),
      background: GlobalColorsStylesSchema.removeDefault()
        .default({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        })
        .catch({
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        }),
      border: oldBorderSchema
        .merge(GlobalColorsStylesSchema.removeDefault())
        .default({
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        })
        .catch({
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$1", custom: "#000000" },
          activeColor: { id: "color$$1", custom: "#000000" },
          disabledColor: { id: "color$$1", custom: "#000000" },
        }),
      text: GlobalColorsStylesSchema.removeDefault()
        .default({
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$2", custom: "#000000" },
          disabledColor: { id: "color$$2", custom: "#000000" },
        })
        .catch({
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$2", custom: "#000000" },
          disabledColor: { id: "color$$2", custom: "#000000" },
        }),
      font: TypoReadoutValueScheme.default({
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      }).catch({
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      }),
      icon: z
        .object({
          iconUrl: UrlScheme.nullable(),
          position: z.nativeEnum(SettingPositionOptions),
          size: z.number(),
          spacing: z.number(),
          color: ColorReadoutValueScheme,
          hoverColor: ColorReadoutValueScheme,
        })
        .default({
          iconUrl: null,
          position: SettingPositionOptions.RIGHT,
          size: 16,
          spacing: 10,
          color: ColorReadoutValueScheme.parse({
            id: "color$$1",
            custom: "#000000",
          }),
          hoverColor: ColorReadoutValueScheme.parse({
            id: "color$$1",
            custom: "#000000",
          }),
        })
        .catch({
          iconUrl: null,
          position: SettingPositionOptions.RIGHT,
          size: 16,
          spacing: 10,
          color: ColorReadoutValueScheme.parse({
            id: "color$$1",
            custom: "#000000",
          }),
          hoverColor: ColorReadoutValueScheme.parse({
            id: "color$$1",
            custom: "#000000",
          }),
        }),
    })
    .default({
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      border: {
        radiusV2: null,
        widthV2: null,
        width: 0,
        radius: 4,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      text: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$2", custom: "#000000" },
        disabledColor: { id: "color$$2", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      },
    })
    .catch({
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      border: {
        radiusV2: null,
        widthV2: null,
        width: 0,
        radius: 4,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      text: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$2", custom: "#000000" },
        disabledColor: { id: "color$$2", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      },
    }),
});
