import { ISrpTransformResponseV2 } from "@ecp-redux/dto/searchResults.types";
import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const isOnePhotoOrMore = (photos: TUrl[] | undefined): boolean =>
  !!photos && photos.length >= 1;

export const getProductData = (data: ISrpTransformResponseV2[] | undefined) => {
  if (!data || !data[0]) return;

  const productData = data[0];

  return {
    productName: productData.name ?? "",
    productSku: productData.sku,
    wishlist: productData.wishlist,
    type: productData.type,
  };
};

export const getProductGalleryPayload = (productSku: string) => ({
  projection: ["coverPhoto", "photoGallery", "name", "wishlist", "sku"],
  skus: [productSku],
});
