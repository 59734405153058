import { useRouter } from "next/router";
import * as searchResults from "@ecp-redux/api/searchResults";
import { useSearchQueries } from "../../../helpers/pageContext";
import { IMarketingCampaign } from "../BoxSearchResults.types";
import { SortDirection, SortOption } from "./Sort.types";
import { useMarketingBannersLogic } from "./useMarketingBannersLogic";

export const createPostProductsRequestBody = (customProductKeys: string[]) => ({
  basicInfo: [
    "name",
    "price",
    "badges",
    "rating",
    "coverPhoto",
    "hoverPhoto",
    "deliveryTime",
    "url",
    "tax",
    "wishlist",
    "axis",
    "variants",
    "pools",
  ],
  attributes: customProductKeys,
  locale: "PL",
});

export const useBoxSearchResultPagination = (
  initialTake: number,
  customAttributes: string[],
  marketingCampaign?: IMarketingCampaign
) => {
  const router = useRouter();

  const { getProductsAndBanners, bannersPositions } = useMarketingBannersLogic(
    initialTake,
    marketingCampaign
  );

  const { searchQueries, skip, take } = useSearchQueries(
    {
      initialPageIndex: 1,
      initialPageSize: initialTake,
    },
    marketingCampaign !== undefined
      ? {
          bannersTemplate: bannersPositions,
          bannersDisplayVariant: marketingCampaign.positionOption,
        }
      : undefined
  );

  const { data: productsVm, isFetching } =
    searchResults.useGetSearchWithSrpResultsV2Query({
      searchQueries,
      projection: [
        ...new Set([
          ...customAttributes,
          ...[
            "rating",
            "tax",
            "axis",
            "sku",
            "url",
            "name",
            "concession",
            "wishlist",
            "coverPhoto",
            "hoverPhoto",
            "badges",
            "promotionPrice",
            "basePrice",
            "deliveryTime",
            "variants",
            "netPrice",
            "conceptId",
          ],
        ]),
      ],
    });

  const setPage = (page: number) => {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, page: page + 1 },
      },
      undefined,
      { shallow: true }
    );
  };
  const setPageSize = (pageSize?: number, sort?: SortOption) => {
    const newSortQuery =
      sort !== undefined
        ? sort.direction === SortDirection.ASC
          ? sort.type
          : `-${sort.type}`
        : undefined;

    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          ...{ page: 1 },
          ...(pageSize ? { pageSize: pageSize } : {}),
          ...(sort ? { sort: newSortQuery } : {}),
        },
      },
      undefined,
      { shallow: true }
    );
  };

  return {
    page: skip / take,
    pageCount: Math.floor((productsVm?.size ?? 0) / take),
    totalSize: productsVm?.size ?? 0,
    pageSize: take,
    productsVm:
      marketingCampaign === undefined
        ? productsVm?.products ?? []
        : getProductsAndBanners(productsVm?.products ?? []),
    isError: productsVm === undefined,
    attributesLineDetection: productsVm?.attributesLineDetection,
    isFetching,
    setPage,
    setPageSize,
  };
};
