import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { NotificationErrorB2B_Cart } from "@ecp-redux/dto/cartB2B.types";
import { isPortalSide } from "../../../helpers/helpers";
import { useAddAlert } from "../../../redux/slices/alertsSlice";
import Alert from "../../../shared/components/Alert/Alert";
import OrderAccordion from "../../../shared/components/domain/CartB2B/OrderAccordion/OrderAccordion";
import SummaryB2B from "../../../shared/components/domain/CartB2B/SummaryB2B/SummaryB2B";
import { useCartB2BContext } from "../../../shared/components/domain/CartB2B/useCartB2BContext";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledCartB2B } from "./BoxCartB2B.styled";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BProps,
  IBoxCartB2BSettings,
} from "./BoxCartB2B.types";
import CartB2BHeader from "./components/CartB2BHeader";

const mappedAlerts = [
  {
    code: NotificationErrorB2B_Cart.CONTRACTOR_STATUS_BLOCKED,
    message: "basket_warning_feed_message_contractor_status",
  },
  {
    code: NotificationErrorB2B_Cart.CONTRACTOR_STATUS_LIMITED,
    message: "basket_warning_feed_message_contractor_status",
  },
  {
    code: NotificationErrorB2B_Cart.PRODUCT_IS_PARTIALLY_AVAILABILITY,
    message: "basket_warning_feed_message_change_quantity",
  },
  {
    code: NotificationErrorB2B_Cart.NO_CONCESSION,
    message: "basket_warning_feed_message_no_license",
  },
  {
    code: NotificationErrorB2B_Cart.CREDIT_LIMIT_EXCEEDED,
    message: "basket_warning_feed_message_credit_limit",
  },
  {
    code: NotificationErrorB2B_Cart.CONTRACTOR_HAS_NO_CREDIT_LIMIT,
    message: "basket_warning_feed_message_credit_limit",
  },
  {
    code: NotificationErrorB2B_Cart.DELIVERY_DATE_CHANGED,
    message: "basket_warning_feed_message_delivery_changed",
  },
  {
    code: NotificationErrorB2B_Cart.PRODUCT_IS_NOT_AVAILABILITY,
    message: "basket_warning_feed_message_change_quantity",
  },
];

const BoxCartB2B: React.FC<IBoxCartB2BProps> = () => {
  const [summaryHeight, setSummaryHeight] = useState(0);

  const { cartData } = useCartB2BContext();
  const router = useRouter();
  const { addAlert } = useAddAlert();
  const { settings, messages } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  useEffect(() => {
    if (!cartData) return;

    if (
      cartData.orders.length === 0 &&
      settings.basket_empty_redirect_url.length > 0 &&
      isPortalSide()
    )
      router.push(settings.basket_empty_redirect_url);

    //cartId is defined only response from place-order endpoint
    cartData.notificationErrors.forEach((error) => {
      const alert = mappedAlerts.find((a) => a.code === error);
      if (alert) {
        addAlert({
          code: alert.code,
          message: messages[alert.message as keyof IBoxCartB2BMessages],
        });
      }
    });
  }, [cartData?.notificationErrors.length, cartData]);

  if (cartData === undefined) return null;

  return (
    <StyledCartB2B
      summaryHeight={summaryHeight}
      settings={settings}
      className="cart-step-one"
    >
      <div className="cart-step-one__alerts-container">
        {cartData.notificationErrors.map((code) => (
          <Alert
            key={code}
            alertsCodes={[code]}
            backgroundColor={settings.basket_warning_feed_background_color}
            textColor={settings.basket_data_color}
            typography={settings.basket_data_typo}
            closeable={true}
            lifetime={5000}
          />
        ))}
      </div>
      <div className="cart-step-one__wrapper">
        <div className="cart-step-one__content">
          <CartB2BHeader
            articlesCount={cartData.orders.reduce(
              (acc, order) => acc + order.cartLines.length,
              0
            )}
            ordersCount={cartData.orders.length}
          />
          {cartData.orders.map((order, idx) => {
            if (order.cartLines.length === 0) return null;
            return <OrderAccordion key={idx} order={order} idx={idx} />;
          })}
        </div>

        <SummaryB2B setHeight={setSummaryHeight} summary={cartData.summary} />
      </div>
    </StyledCartB2B>
  );
};

export default BoxCartB2B;
