import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../global/global";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import {
  CheckboxButton,
  CheckboxButtonLabel,
  Item,
  StyledCheckboxList,
} from "../../shared/components/CheckboxesList/CheckboxesList.styled";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import { IBoxMyAccountConsentsSettings } from "./BoxMyAccountConsents.types";

export const StyledBoxMyAccountConsents =
  styled.div<IBoxMyAccountConsentsSettings>(
    ({ background, gap, padding, theme }) => {
      const bg = convertColorIdToHex(background, theme.colorPalette);
      return `
      background-color: ${bg};
      padding: ${padding};
     
      hr{
        margin-top: ${gap};
        margin-bottom: ${gap};
      }
      .header{
        margin-bottom: ${gap};
      }
      .form-button-container{
        display: flex;
        gap: ${gap};
        margin-top: ${gap};
      }
      .delete-account-text{
        margin-left: 9px;
        font-size: 14px;
      }

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        .form-button-container {
          flex-direction: column;
          gap: 20px;
        }
        ${StyledButton} {
          width: 100%;
        }
        .my-account-consents-container__delete-account-button {
          display: flex;
          justify-content: flex-start;
        }
        ${StyledCheckboxList} {
          ${Item} {
            gap: 20px;
          }
          ${CheckboxButtonLabel} {
            width: 24px;
            height: 24px;
          }
          ${CheckboxButton} {
            &:checked + ${CheckboxButtonLabel}, 
            &:indeterminate + ${CheckboxButtonLabel} {
              &::before {
                left: 6px;
                top: 1px;
                width: 8.5px;
                height: 15px;
              }
            }
          }
        }
      }
    `;
    }
  );

export const StyledConfirmDeletePopup = styled.div`
  .confirm-delete-popup__content-container__description {
    margin: 30px 0;
  }

  .confirm-delete-popup__content-container__button-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 12px;

    &__save-popup-button,
    &__cancel-button {
      min-width: 100%;
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    .confirm-delete-popup__content-container__button-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  margin: 30px;
`;
