import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { IPortalSection } from "@ecp-pageTypes";
import { PortalPath } from "../../shared/portalPath/portalPath";

export const RowPopupContext = createContext({} as IRowPopupContext);

export function useRowPopupContext() {
  const context = useContext(RowPopupContext);
  if (!context) {
    throw new Error(
      "useRowPopupContext must be used under RowPopupContext.Provider"
    );
  }
  return context;
}

interface IRowPopupContext {
  activePopup: {
    sectionData: IPortalSection;
    sectionPath: PortalPath;
  } | null;
  setActivePopup: (path: PortalPath) => void;
  closeActivePopup: (path?: PortalPath) => void;
  togglePopup: (path: PortalPath) => void;
}

export const RowPopupContextProvider = ({
  children,
  sections,
}: {
  children: React.ReactNode;
  sections: IPortalSection[];
}) => {
  const [activeSectionPath, setActiveSectionPath] = useState<PortalPath | null>(
    null
  );

  const activeSection = useMemo(
    () =>
      sections?.find(
        (s, idx) =>
          activeSectionPath &&
          (idx === activeSectionPath.getFirstSectionIndex() ||
            s.id === activeSectionPath.getFirstSection())
      ) ?? null,
    [sections, activeSectionPath]
  );

  const setActivePopup = (path: PortalPath) => {
    setActiveSectionPath(path);
  };

  const closeActivePopup = (path?: PortalPath) => {
    if (path === undefined) {
      setActiveSectionPath(null);
    }

    if (path && activeSectionPath?.isPathEqual(path.toString())) {
      setActiveSectionPath(null);
    }
  };

  const togglePopup = (path: PortalPath) => {
    if (activeSectionPath?.isPathEqual(path.toString())) {
      closeActivePopup();
    } else {
      setActivePopup(path);
    }
  };

  const values = useMemo(
    () => ({
      activePopup:
        activeSection && activeSectionPath
          ? {
              sectionData: activeSection,
              sectionPath: activeSectionPath,
            }
          : null,
      setActivePopup,
      closeActivePopup,
      togglePopup,
    }),
    [activeSection, activeSectionPath]
  );

  const pathname = useRouter().asPath;

  useEffect(() => {
    setActiveSectionPath(null);
  }, [pathname]);

  return (
    <RowPopupContext.Provider value={values}>
      {children}
    </RowPopupContext.Provider>
  );
};
