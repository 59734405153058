import styled, { css } from "styled-components";
import { IDisplaySettingsSection } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import composeCss, {
  alignmentsOptionsToViewport,
  closeButtonAlignment,
} from "../../settingsPatterns/composeCss";
import { convertButtonIdToValues } from "../../settingsPatterns/settingsPatterns.methods";

export const StyledRowPopup = styled.div<{
  $settings: IDisplaySettingsSection;
}>(({ $settings, theme }) => {
  const { popup, width } = $settings;

  const buttonValue = convertButtonIdToValues(
    popup.closeButtonStyle,
    theme?.globalObjects?.buttons
  );

  return css`
    position: absolute;
    pointer-events: all;
    width: ${width.percent}%;
    z-index: calc(var(--zindex-row-popup) + 1);
    ${alignmentsOptionsToViewport(popup)}
    .close-btn {
      z-index: calc(var(--zindex-row-popup) + 2);
      position: absolute;
      ${closeButtonAlignment(popup)};
      ${composeCss.buttonSettings.icon(buttonValue.icon)};
    }
  `;
});
