import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import * as newsletter from "@ecp-redux/api/newsletter";
import { IUserNewsletterForm } from "@ecp-redux/dto/newsletter.types";
import { yupResolver } from "@hookform/resolvers/yup";
import htmlToReact from "../../helpers/HtmlToReactParser";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import { isEmail, isRequiredCheckbox } from "../../helpers/validators";
import { useAddAlert } from "../../redux/slices/alertsSlice";
import Alert from "../../shared/components/Alert/Alert";
import { Checkbox } from "../../shared/components/CheckboxesList/CheckboxesList";
import { InputText } from "../../shared/components/Input/Inputs";
import { StyledAuthFormWrapper } from "../../shared/components/StyledAuthFormWrapper/StyledAuthFormWrapper.styled";
import useScrollIntoElement from "../../shared/hooks/useScrollIntoElement";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { TBoxNewsletterProps } from "./BoxNewsletter.types";

const registerDefaultValues: IUserNewsletterForm = {
  email: "",
  terms: false,
};

const BoxNewsletter: React.FC<TBoxNewsletterProps> = ({
  messages,
  settings,
}) => {
  const { addAlert } = useAddAlert();
  const validationSchema = yup.object().shape({
    email: isEmail(
      messages.newsletter_form_empty_verification_email,
      messages.newsletter_form_syntax_verification_email
    ),
    terms: isRequiredCheckbox(
      messages.newsletter_required_verification_consent
    ),
  });

  const [postNewsletter] = newsletter.usePostNewsletterMutation();

  const newsletterRef = useRef<HTMLDivElement>(null);

  const scrollIntoElement = useScrollIntoElement(newsletterRef);

  const sendNewsletterFormMethods = useForm<IUserNewsletterForm>({
    defaultValues: registerDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    delayError: 500,
  });
  const { handleSubmit, control, reset } = sendNewsletterFormMethods;
  const onSubmit = async (data: IUserNewsletterForm) => {
    try {
      await postNewsletter(data).unwrap();
      reset();
      addAlert({
        code: "200",
        message: messages.newsletter_form_success,
      });
    } catch (error) {
      addAlert({
        code: "400",
        message: messages.newsletter_form_error,
      });
    }

    scrollIntoElement();
  };

  return (
    <StyledAuthFormWrapper
      buttonWidth={settings.newsletter_form_submit_button_width}
      inputStyleId={settings.newsletter_form_input_style}
      formElementsGap={settings.newsletter_form_elements_gap}
      titleSpacing={settings.newsletter_form_title_spacing}
      wrapperMargin={settings.newsletter_form_wrapper_margin}
      backgroundColorId={settings.newsletter_form_wrapper_background_color}
      className="newsletter-form-container"
      ref={newsletterRef}
    >
      <FormProvider {...sendNewsletterFormMethods}>
        <form
          onSubmit={handleSubmit((d) => onSubmit(d))}
          autoComplete="off"
          className="newsletter-form-container__form"
        >
          <StyledText
            className="newsletter-form-container__form__title"
            $settings={{
              font: settings.newsletter_form_title_typo,
              text: { color: settings.newsletter_form_title_color },
            }}
            alignment={settings.newsletter_form_title_align?.toLowerCase()}
            show={isNotEmptyMessage(messages.newsletter_form_title)}
          >
            {messages.newsletter_form_title}
          </StyledText>

          <Alert
            alertsCodes={["400"]}
            backgroundColor={settings.newsletter_form_background_error_color}
            textColor={settings.newsletter_form_alert_text_error_color}
            typography={settings.newsletter_form_error_typo}
            closeable={true}
            lifetime={5000}
          />
          <Alert
            alertsCodes={["200"]}
            backgroundColor={settings.newsletter_form_background_success_color}
            textColor={settings.newsletter_form_alert_text_success_color}
            typography={settings.newsletter_form_success_typo}
            closeable={true}
            lifetime={5000}
          />
          <StyledText
            className="newsletter-form-container__form__description"
            $settings={{
              font: settings.newsletter_form_description_typo,
              text: {
                color: settings.newsletter_form_description_color,
              },
            }}
            alignment={settings.newsletter_form_description_align?.toLowerCase()}
            show={isNotEmptyMessage(messages.newsletter_form_description)}
          >
            {messages.newsletter_form_description}
          </StyledText>
          <InputText
            className="newsletter-form-container__form__email"
            name="email"
            control={control}
            placeholder={messages.newsletter_form_placeholder_email}
            label={messages.newsletter_form_heading_email}
            $settings={settings.newsletter_form_input_style}
          />
          <Checkbox
            label={
              <StyledText
                className="newsletter-form-container__form__consent"
                $settings={{
                  font: settings.newsletter_form_consent_typo,
                  text: { color: settings.newsletter_form_consent_color },
                }}
                show={isNotEmptyMessage(messages.newsletter_form_email_consent)}
              >
                {htmlToReact(
                  messages.newsletter_form_email_consent,
                  settings.newsletter_form_consent_link_style,
                  settings.newsletter_form_consent_link_color
                )}
              </StyledText>
            }
            name="terms"
            control={control}
            textStyles={{
              labelTypo: settings.newsletter_form_checkbox_typo,
              labelColor: settings.newsletter_form_checkbox_color,
              errorTypo: settings.newsletter_form_checkbox_error_typo,
              errorColor: settings.newsletter_form_checkbox_error_color,
            }}
          />
          <StyledButton
            className="newsletter-form-container__form__submit-button"
            renderAs="button"
            type="submit"
            $settings={settings.newsletter_form_button_style}
            show={isNotEmptyMessage(messages.newsletter_form_save_button)}
          >
            {messages.newsletter_form_save_button}
          </StyledButton>
        </form>
      </FormProvider>
    </StyledAuthFormWrapper>
  );
};

export default BoxNewsletter;
