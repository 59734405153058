import * as auth from "@ecp-redux/api/auth";
import {
  IBoxRemindPasswordMessages,
  IBoxRemindPasswordSettings,
} from "../../../boxes/BoxRemind/BoxRemind.types";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { Info, StyledCircleIcon } from "../../../shared/components/Info/Info";
import { ReactComponent as CloseCircle } from "../../../shared/icons/closeCircle.svg";
import { ReactComponent as EmailIcon } from "../../../shared/icons/email.svg";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";

const SendNewToken: React.FC<{ token: string }> = ({ token }) => {
  const [resetToken, { isSuccess }] =
    auth.usePostResetForgettenPasswordMutation();

  const { messages, settings } = useMessagesSettingsContext<
    IBoxRemindPasswordMessages,
    IBoxRemindPasswordSettings
  >();

  return isSuccess ? (
    <Info>
      <>
        <StyledCircleIcon>
          <EmailIcon />
        </StyledCircleIcon>
        <StyledText
          $settings={{
            font: settings.remind_password_form_title_typo,
            text: { color: settings.remind_password_form_title_color },
          }}
          alignment={settings.remind_password_form_title_align?.toLowerCase()}
          show={isNotEmptyMessage(messages.remind_password_form_success)}
        >
          {messages.remind_password_form_success}
        </StyledText>
      </>
    </Info>
  ) : (
    <>
      <Info>
        <>
          <StyledCircleIcon color="color$$12">
            <CloseCircle />
          </StyledCircleIcon>
          <StyledText
            $settings={{
              font: settings.remind_password_form_title_typo,
              text: { color: "color$$10" },
            }}
            alignment={settings.remind_password_form_title_align?.toLowerCase()}
            show={isNotEmptyMessage(
              messages.remind_password_activation_link_expired
            )}
          >
            {messages.remind_password_activation_link_expired}
          </StyledText>
        </>
      </Info>
      <StyledButton
        renderAs="button"
        type="submit"
        $settings={settings.remind_password_form_button_style}
        onClick={async () => {
          await resetToken({ token });
        }}
        show={isNotEmptyMessage(
          messages.remind_password_resend_activation_link_button
        )}
      >
        {messages.remind_password_resend_activation_link_button}
      </StyledButton>
      <StyledText
        $settings={{
          font: settings.remind_password_form_description_typo,
          text: { color: settings.remind_password_form_description_color },
        }}
        alignment={settings.remind_password_form_title_align?.toLowerCase()}
        show={isNotEmptyMessage(
          messages.remind_password_resend_activation_link_terms
        )}
      >
        {messages.remind_password_resend_activation_link_terms}
      </StyledText>
    </>
  );
};

export default SendNewToken;
