import React, { useState } from "react";
import * as myAccount from "@ecp-redux/api/myAccount";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import Alert from "../../shared/components/Alert/Alert";
import { ReactComponent as TrashIcon } from "../../shared/icons/trash.svg";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { StyledBoxMyAccountConsents } from "./BoxMyAccountConsents.styled";
import { IBoxMyAccountConsentsProps } from "./BoxMyAccountConsents.types";
import ConfirmDeletePopup from "./elements/ConfirmDeletePopup";
import MyAccountConsentsForm from "./elements/MyAccountConsentsForm";

const BoxMyAccountConsents: React.FC<IBoxMyAccountConsentsProps> = ({
  settings,
  messages,
}) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const { data } = myAccount.useGetConsentsQuery();

  return (
    <StyledBoxMyAccountConsents
      {...settings}
      className="my-account-consents-container my-account-consents-custom-style"
    >
      <Alert
        alertsCodes={["cons_error"]}
        backgroundColor={settings.error_background_color}
        textColor={settings.error_text_color}
        typography={settings.error_typo}
        closeable={true}
        lifetime={5000}
      />
      <Alert
        alertsCodes={["cons_succ"]}
        backgroundColor={settings.success_background_color}
        textColor={settings.success_text_color}
        typography={settings.success_typo}
        closeable={true}
        lifetime={5000}
      />
      <StyledText
        className="header my-account-consents-container__header"
        $settings={{
          font: settings.title_typo,
          text: { color: settings.title_color },
        }}
        alignment={
          settings.title_aligment?.toLowerCase() ??
          AlignmentHorizontalOption.LEFT
        }
        show={isNotEmptyMessage(messages.customer_data_consent_title)}
      >
        {messages.customer_data_consent_title}
      </StyledText>

      {data != null && (
        <MyAccountConsentsForm
          email={data.emailAddress}
          editData={{
            phone: data.mobile,
            sms_consent: data.sms,
            email_consent: data.email,
            profiling_consent: data.d, //co to jest a1, a2, d
          }}
        />
      )}
      <hr />
      <StyledButton
        $settings={settings.delete_account_button}
        renderAs={"button"}
        onClick={() => setShowDeletePopup(true)}
        data-testid="deleteAccountBtn"
        className="my-account-consents-container__delete-account-button"
        show={isNotEmptyMessage(messages.customer_data_delete_button)}
      >
        <TrashIcon height="18" width="16" />
        <p className="delete-account-text">
          {messages.customer_data_delete_button}
        </p>
      </StyledButton>

      {showDeletePopup && (
        <ConfirmDeletePopup closePopup={() => setShowDeletePopup(false)} />
      )}
    </StyledBoxMyAccountConsents>
  );
};

export default BoxMyAccountConsents;
