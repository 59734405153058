import { useEffect, useMemo, useState } from "react";
import { PageCategoryType } from "@ecp-pageTypes";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as lastSeen from "@ecp-redux/api/lastSeen";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as productList from "@ecp-redux/api/productList";
import * as searchResults from "@ecp-redux/api/searchResults";
import * as wishList from "@ecp-redux/api/wishList";
import {
  ISrpTransformResponseV2,
  SortingItemQuery,
  SortingItemType,
  SortingItemVariant,
  TSrpProductType,
} from "@ecp-redux/dto/searchResults.types";
import {
  ProductContext,
  useCheckPageContext,
} from "../../../helpers/pageContext";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import {
  getDynamicAssociationSkus,
  getProductsDataObject,
  getSearchRequestConfig,
} from "../BoxProductSlider.methods";
import {
  IBoxProductSliderContentSettings,
  dynamicProductListOption,
} from "../BoxProductSlider.types";

const getSortQueryBySortType = (
  sort: SortingItemType,
  sortingAttributeCode: string,
  sortingAttributeVariant: string
): string => {
  switch (sort) {
    case SortingItemType.NAME_ASC: {
      return SortingItemQuery.NAME_ASC;
    }
    case SortingItemType.NAME_DESC: {
      return SortingItemQuery.NAME_DESC;
    }
    case SortingItemType.POPULARITY: {
      return SortingItemQuery.POPULARITY;
    }
    case SortingItemType.PRICE_ASC: {
      return SortingItemQuery.PRICE_ASC;
    }
    case SortingItemType.PRICE_DESC: {
      return SortingItemQuery.PRICE_DESC;
    }
    case SortingItemType.RATING_ASC: {
      return SortingItemQuery.RATING_ASC;
    }
    case SortingItemType.RATING_DESC: {
      return SortingItemQuery.RATING_DESC;
    }
    case SortingItemType.RANDOM: {
      return SortingItemQuery.RANDOM;
    }
    case SortingItemType.DATE_ADDED: {
      return SortingItemQuery.DATE_ADDED;
    }
    case SortingItemType.DATE_ADDED_DESC: {
      return SortingItemQuery.DATE_ADDED_DESC;
    }
    case SortingItemType.BY_ATTRIBUTE: {
      if (sortingAttributeVariant === SortingItemVariant.DESC) {
        return `-${sortingAttributeCode}`;
      } else {
        return sortingAttributeCode;
      }
    }
    default:
      return SortingItemQuery.DATE_ADDED_DESC;
  }
};

const useBoxProductSliderData = (
  contentSettings: IBoxProductSliderContentSettings
) => {
  const {
    dynamicProductListOption: dynamicListOption,
    selectedAssociation,
    sliderSettings,
    attributes: currentSkuAttributes,
  } = contentSettings;

  const [currentPage, setCurrentPage] = useState(0);

  const isMobile = useIsMobilePortal();

  const context = useCheckPageContext<ProductContext>(PageCategoryType.PRODUCT);

  const currentSku =
    context.type === PageCategoryType.PRODUCT ? context.productSku : undefined;
  const isProductSiteWithAssociation =
    selectedAssociation && context.type === PageCategoryType.PRODUCT;

  const lastSeenResponse = lastSeen.useGetLastSeenQuery(undefined, {
    skip: dynamicListOption !== dynamicProductListOption.LAST_SEEN,
  });

  const wishListResponse = wishList.useGetWishListQuery(undefined, {
    skip: dynamicListOption !== dynamicProductListOption.WISH_LIST,
  });

  const dynamicAssociationResponse =
    productList.useGetDynamicAssociationProductsQuery(
      { currentSku: currentSku ?? "", attributes: currentSkuAttributes },
      {
        skip:
          dynamicListOption !== dynamicProductListOption.DYNAMIC_ASSOCIATION,
      }
    );

  const [getAssociationProducts, associationProducts] =
    searchResults.useLazyGetPostProductsQuery();

  const currentProductsSkus = useMemo(
    () =>
      getProductsDataObject(lastSeenResponse?.data).products ??
      getProductsDataObject(wishListResponse?.data).products ??
      getDynamicAssociationSkus(dynamicAssociationResponse.data) ??
      associationProducts?.data?.products?.[0]?.associations?.[
        selectedAssociation ?? ""
      ] ??
      [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      lastSeenResponse.fulfilledTimeStamp,
      wishListResponse.fulfilledTimeStamp,
      associationProducts.fulfilledTimeStamp,
      dynamicAssociationResponse.fulfilledTimeStamp,
      isProductSiteWithAssociation,
    ]
  );

  const attributes = useMemo(
    () => Object.keys(contentSettings.product.custom),
    [contentSettings.product.custom]
  );

  const { query, sort } = getSearchRequestConfig(
    contentSettings,
    currentProductsSkus
  );

  const { data: searchResultsResponse } =
    searchResults.useGetSearchWithSrpResultsV2Query({
      searchQueries: `${query}${
        sort
          ? `&sort=${getSortQueryBySortType(
              sort,
              sliderSettings.sortingAttributeCode,
              sliderSettings.sortingAttributeVariant
            )}`
          : ""
      }`,
      projection: [
        "rating",
        "tax",
        "axis",
        "sku",
        "url",
        "name",
        "concession",
        "wishlist",
        "coverPhoto",
        "hoverPhoto",
        "badges",
        "promotionPrice",
        "basePrice",
        "deliveryTime",
        "variants",
        "netPrice",
        "conceptId",
        ...attributes,
      ],
    });

  useEffect(() => {
    if (isProductSiteWithAssociation && currentSku) {
      getAssociationProducts({
        associations: [selectedAssociation],
        projection: [],
        skus: [currentSku],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssociation, currentSku]);

  const wishListMap: Record<string, TSrpProductType> | undefined =
    wishListResponse.data?.products?.reduce(
      (acc, product) => ({ ...acc, [product.sku]: product.type }),
      {}
    );

  const products: ISrpTransformResponseV2[] =
    dynamicListOption !== dynamicProductListOption.WISH_LIST ||
    wishListMap === undefined
      ? searchResultsResponse?.products ?? []
      : searchResultsResponse?.products.map((product) => ({
          ...product,
          type: wishListMap[product.conceptId ?? product.sku],
        })) ?? [];

  const numberOfProductsPerRow = isMobile
    ? Math.min(contentSettings.sliderSettings.numberOfProductsPerRow, 2)
    : contentSettings.sliderSettings.numberOfProductsPerRow;

  const productsPerPage = Math.min(
    numberOfProductsPerRow * contentSettings.sliderSettings.numberOfRows,
    contentSettings.sliderSettings.maxNumberOfProductsGrid
  );

  return {
    productsData: {
      products: products,
      size: searchResultsResponse?.size ?? 0,
      attributesLineDetection: searchResultsResponse?.attributesLineDetection,
    },
    context,
    currentPage,
    setCurrentPage,
    productsPerPage,
  };
};

export default useBoxProductSliderData;
