import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import StyledButtonWrapper from "@ecp-boxes/shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import Dropdown from "../../shared/components/Dropdown/Dropdown";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { setPublicationZIndex } from "./BoxMiniCart.methods";
import {
  StyledBoxMiniCartWrapper,
  StyledDropdownWrapper,
} from "./BoxMiniCart.styled";
import { TBoxMiniCartProps } from "./BoxMiniCart.types";
import MiniCartProduct from "./MiniCartProduct/MiniCartProduct";
import MiniCartSummary from "./MiniCartSummary/MiniCartSummary";
import useBoxMiniCart from "./useBoxMiniCart";

const BoxMiniCart: React.FC<TBoxMiniCartProps> = ({
  settings,
  messages,
  publicationRef,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const { miniCartContent, miniCartCount, refetch } = useBoxMiniCart();
  const { t } = useTranslation();
  const router = useRouter();

  const handleMouseOver = () => {
    if (settings.mini_cart_action_on_icon === "HOVER" && miniCartCount !== 0) {
      setIsOpenDropdown(true);
      setPublicationZIndex("var(--zindex-dropdown-content)", publicationRef);
    }
  };

  const handleBackdropMouseOver = () => {
    if (settings.mini_cart_action_on_icon === "HOVER") {
      setPublicationZIndex("var(--zindex-publication)", publicationRef);
      setIsOpenDropdown(false);
    }
  };

  const handleMouseClick = () => {
    if (
      settings.mini_cart_action_on_icon === "REDIRECT" ||
      settings.mini_cart_action_on_icon === "HOVER"
    ) {
      router.push(settings.mini_cart_on_action_redirect_url);
      return;
    }

    if (settings.mini_cart_action_on_icon === "CLICK" && miniCartCount > 0) {
      setPublicationZIndex("var(--zindex-dropdown-content)", publicationRef);
      setIsOpenDropdown((prev) => !prev);
    }
  };

  const handleMouseLeave = () => {
    setIsOpenDropdown(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsOpenDropdown(false);
      setPublicationZIndex("var(--zindex-publication)", publicationRef);
    };

    document.addEventListener("scroll", handleScroll, false);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StyledBoxMiniCartWrapper
      className="mini-cart-container"
      alignment={settings.mini_cart_button_position}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseOver}
      onMouseEnter={refetch}
      data-testid="minicart"
    >
      <Dropdown
        isOpen={isOpenDropdown}
        setIsOpen={setIsOpenDropdown}
        openHorizontalDirection={settings.mini_cart_dropped_container_position}
        actionType={settings.mini_cart_action_on_icon}
        onBackDropClick={() => {
          setIsOpenDropdown(false);
        }}
        closeDropdown={handleMouseLeave}
        onBackDropMouseOver={handleBackdropMouseOver}
        fixedWidth
        buttonContent={
          <div
            className="mini-cart-container__button-open-container"
            data-testid="button-open-minicart"
            onClick={handleMouseClick}
          >
            <div className="mini-cart-container__button-open-container__text-content">
              <StyledButton
                $settings={settings.mini_cart_icon_button}
                renderAs={"button"}
                data-testid="dropdown-button"
                className="mini-cart-container__button-open-container__text-content"
                show={isNotEmptyMessage(messages.mini_cart_icon_open_label)}
              >
                {messages.mini_cart_icon_open_label}
              </StyledButton>
              <StyledText
                className="mini-cart-container__button-open-container__text-content__products-count"
                $settings={{
                  font: settings.mini_cart_cart_products_count_typo,
                  text: {
                    color: settings.mini_cart_cart_products_count_color,
                  },
                }}
              >
                {miniCartCount > 0 && `(${miniCartCount ?? ""})`}
              </StyledText>
            </div>
          </div>
        }
        selectContainerContent={
          <StyledDropdownWrapper
            className="mini-cart-container__dropdown"
            margin={settings.mini_cart_dropped_wrapper_margin}
            gap={settings.mini_cart_dropped_elements_gap}
            data-testid="mini-cart-dropdown-wrapper"
            onMouseLeave={() => setIsOpenDropdown(false)}
          >
            <div className="mini-cart-container__dropdown__container">
              <StyledText
                className="mini-cart-container__dropdown__container__title"
                $settings={{
                  font: settings.mini_cart_dropped_title_typo,
                  text: {
                    color: settings.mini_cart_dropped_title_color,
                  },
                }}
                show={isNotEmptyMessage(messages.mini_cart_title)}
              >
                {messages.mini_cart_title}
              </StyledText>
              <StyledText
                className="mini-cart-container__dropdown__container__products-count"
                $settings={{
                  font: settings.mini_cart_dropped_summary_products_count_typo,
                  text: {
                    color:
                      settings.mini_cart_dropped_summary_products_count_color,
                  },
                }}
                show={isNotEmptyMessage(
                  messages.mini_cart_dropped_count_products_suffix
                )}
              >
                {`${miniCartCount ?? ""} ${
                  messages.mini_cart_dropped_count_products_suffix
                }`}
              </StyledText>
            </div>
            {miniCartContent.isLoading || !miniCartContent ? (
              <div className="mini-cart-container__dropdown__container__loading">
                {t("global_keys.loading")}
              </div>
            ) : (
              <>
                <MiniCartProduct
                  content={miniCartContent.data?.content?.orderLines}
                />

                <MiniCartSummary
                  content={miniCartContent?.data?.content?.cartSummary}
                />
              </>
            )}
            <div className="mini-cart-container__dropdown__container__button-container">
              <StyledButtonWrapper
                className="mini-cart-container__dropdown__container__button-container__button-go-to-cart"
                onClick={() =>
                  router.push(settings.mini_cart_on_action_redirect_url)
                }
                style={{
                  width: settings.mini_cart_dropped_button_go_to_cart_width
                    ? settings.mini_cart_dropped_button_go_to_cart_width
                    : "fit-content",
                }}
                renderAs="button"
                type="submit"
                $settings={settings.mini_cart_dropped_button_go_to_cart_style}
                show={isNotEmptyMessage(
                  messages.mini_cart_dropped_button_go_to_cart_label
                )}
              >
                {messages.mini_cart_dropped_button_go_to_cart_label}
              </StyledButtonWrapper>
            </div>
          </StyledDropdownWrapper>
        }
      />
    </StyledBoxMiniCartWrapper>
  );
};

export default BoxMiniCart;
