import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { FormProvider, useForm } from "react-hook-form";
import * as auth from "@ecp-redux/api/auth";
import { IUserChangePasswordForm } from "@ecp-redux/dto/auth.types";
import { yupResolver } from "@hookform/resolvers/yup";
import SendNewToken from "../../../global/components/SendNewToken/SendNewToken";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { useAddAlert } from "../../../redux/slices/alertsSlice";
import Alert from "../../../shared/components/Alert/Alert";
import { InputPassword } from "../../../shared/components/Input/Inputs";
import useScrollIntoElement from "../../../shared/hooks/useScrollIntoElement";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { isFetchBaseQueryErrorType } from "../../BoxLogin/elements/ShoppingWithoutRegister";
import {
  IBoxRemindPasswordMessages,
  IBoxRemindPasswordSettings,
  RemindChangePasswordProps,
} from "../BoxRemind.types";
import { getValidationChangePassSchema } from "../validation/RemindValidationSchemas";

const changePasswordDefaultValues: IUserChangePasswordForm = {
  password: "",
  repeatedPassword: "",
};

export const REDIRECT_ACTION = "NEW_PASSWORD";

const RemindChangePassword: React.FC<RemindChangePasswordProps> = ({
  changePasswordToken,
}) => {
  const { addAlert } = useAddAlert();
  const router = useRouter();

  const { messages, settings } = useMessagesSettingsContext<
    IBoxRemindPasswordMessages,
    IBoxRemindPasswordSettings
  >();

  const remindRef = useRef<HTMLDivElement>(null);

  const scrollIntoElement = useScrollIntoElement(remindRef);

  const [postChangePassword, { isSuccess, isError, error }] =
    auth.usePostChangePasswordMutation();

  const { data: validTokenData, isSuccess: validTokenRequestSucess } =
    auth.useGetVerifyResetPasswordTokenQuery(changePasswordToken);

  const changePasswordFormMethods = useForm<IUserChangePasswordForm>({
    defaultValues: changePasswordDefaultValues,
    resolver: yupResolver(getValidationChangePassSchema(messages)),
    mode: "onBlur",
    delayError: 500,
  });

  const { handleSubmit, control } = changePasswordFormMethods;
  const onSubmit = async (data: IUserChangePasswordForm) => {
    const changePassword = await postChangePassword({
      ...data,
      token: changePasswordToken,
    });

    const errorCode =
      "error" in changePassword &&
      isFetchBaseQueryErrorType(changePassword?.error) &&
      (
        changePassword.error.data as {
          code: number;
          message: string;
          serviceName: string;
        }
      ).code;

    if (errorCode === 4031) {
      addAlert({
        code: "same_password",
        message: messages.remind_password_same_password_error,
      });
    }

    scrollIntoElement();
  };

  useEffect(() => {
    const redirectUrl = settings.remind_password_form_redirect_login;
    isSuccess && router.push(`${redirectUrl}?action=${REDIRECT_ACTION}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const errorCode =
    error &&
    "data" in error &&
    (
      error.data as {
        code: number;
        message: string;
        serviceName: string;
      }
    )?.code;

  if (!validTokenRequestSucess) return null;

  return (isError && errorCode !== 4031) || !validTokenData?.tokenValid ? (
    <SendNewToken token={changePasswordToken} />
  ) : (
    <>
      <Alert
        alertsCodes={["same_password"]}
        backgroundColor={settings.remind_password_error_background_color}
        textColor={settings.remind_password_error_text_color}
        typography={settings.remind_password_error_typo}
        closeable
        lifetime={5000}
      />
      <StyledText
        className="remind-password-form__title"
        $settings={{
          font: settings.remind_password_form_title_typo,
          text: { color: settings.remind_password_form_title_color },
        }}
        alignment={settings.remind_password_form_title_align?.toLowerCase()}
        ref={remindRef}
        show={isNotEmptyMessage(
          messages.remind_password_form_second_step_title
        )}
      >
        {messages.remind_password_form_second_step_title}
      </StyledText>
      <FormProvider {...changePasswordFormMethods}>
        <form
          onSubmit={handleSubmit((d) => onSubmit(d))}
          autoComplete="off"
          className="remind-password-form"
        >
          <InputPassword
            className="remind-password-form__password"
            name="password"
            control={control}
            placeholder={messages.remind_password_form_placeholder_password}
            label={messages.remind_password_form_heading_password}
            $settings={settings.remind_password_form_input_style}
          />
          <InputPassword
            className="remind-password-form__repeat-password"
            name="repeatedPassword"
            control={control}
            placeholder={
              messages.remind_password_form_placeholder_repeat_password
            }
            label={messages.remind_password_form_heading_repeat_password}
            $settings={settings.remind_password_form_input_style}
          />
          <StyledButton
            className="remind-password-form__submit-button"
            renderAs="button"
            type="submit"
            $settings={settings.remind_password_form_button_style_2}
            show={isNotEmptyMessage(messages.remind_password_form_button_2)}
          >
            {messages.remind_password_form_button_2}
          </StyledButton>
        </form>
      </FormProvider>
    </>
  );
};

export default RemindChangePassword;
