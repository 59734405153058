import { IPortalBox } from "@ecp-pageTypes";
import { TAttributeValue } from "@ecp-redux/dto/searchResults.types";
import {
  IElementText,
  IElementTextContent,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  ISpacing,
  SettingPositionOptions,
  TColorReadoutValue,
  TLinkReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export type BoxProductAttributesProps = IPortalBox<IBoxProductAttributesData>;

export interface IBoxProductAttributesData {
  content: IBoxProductAttributesContent;
  contentSettings: IBoxProductAttributesContentSettings;
  displaySettings: IBoxProductAttributesDisplaySettings;
  messages: Record<string, never>;
  settings: { [key: string]: string };
  mobileSettings: { [key: string]: string };
  mobileContentSettings: IBoxProductAttributesContentSettings;
  mobileDisplaySettings: IBoxProductAttributesDisplaySettings;
}

/**
 * @deprecated After refactoring BoxProductAttributes to remove
 */

export enum SrpInfoProductFields {
  rating = "Rating",
  reviews = "Reviews",
  deliveryTime = "deliveryTime",
  pools = "pools",
  badges = "badges",
  productName = "ProductName",
  basePrice = "BasePrice",
  oldPrice = "OldPrice",
  promotionPrice = "promotionPrice",
  TAX = "TAX",
  netPrice = "Net_price",
  grossPrice = "grossPrice",
  sku = "sku",
  otherDeliveryDates = "others_delivery_dates",
}

type TAttributeIconSettings = {
  iconUrl: string;
  iconSize: { auto: boolean; height: number };
  iconPosition: SettingPositionOptions;
  iconSpacing: number;
};

type TAttributeIconSvgSettings = {
  type: "SVG" | null;
  iconMainColor: TColorReadoutValue;
  iconHoverColor: TColorReadoutValue;
} & TAttributeIconSettings;

type TAttributeIconPngSettings = {
  type: "PNG" | null;
} & TAttributeIconSettings;

type TAttributeValueIconBasicSettings = {
  pureIcon: boolean;
  valueCode: string;
};

export type TTitleIconSettings =
  | TAttributeIconSvgSettings
  | TAttributeIconPngSettings;

export type TValueIconSettings = (
  | TAttributeIconSvgSettings
  | TAttributeIconPngSettings
) &
  TAttributeValueIconBasicSettings;

export type AttributeIcon = {
  titleIcon: TTitleIconSettings;
  valuesIcons: TValueIconSettings[];
};

export interface ProductAttributeElement {
  attributeId: string | SrpInfoProductFields;
  translation: string;
  activeLink?: boolean;
  value?:
    | string
    | number
    | string[]
    | TAttributeValue
    | TAttributeValue[]
    | null;
  numberOfReviews?: number | null;
  badgeType?: string[] | null;
  attributeIcon?: AttributeIcon;
}

interface IBoxProductAttributesContent {
  selections: ProductAttributeElement[];
}

export interface IBoxProductAttributesContentSettings {
  nameStyle: IElementText;
  valueStyle: IElementText;
  rating: IRatingStyle;
  linkStyle: TLinkReadoutValue;
  contentAlignment: AlignmentHorizontalOption;
}

export interface IRatingStyle extends IElementTextContent {
  filledPointsColor: TColorReadoutValue;
  unfilledPointsColor: TColorReadoutValue;
}

export enum AttributeDisplayType {
  LIST = "LIST",
  TABLE = "TABLE",
}

export interface IBoxProductAttributesDisplaySettings {
  displayType: AttributeDisplayType;
  showLabel: boolean;
  padding: ISpacing;
  tableSettings: {
    firstColumnSize: number;
    spaceBetweenRows: number;
    evenRows: TColorReadoutValue;
    oddRows: TColorReadoutValue;
  };
}
