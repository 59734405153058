import { z } from "zod";
import { PathNumberType } from "../shared/portalPath/portalPath";
import { SelectedGoToOption } from "./contentPatterns.types";

const GoToPageSettingSchema = z.object({
  link: z.string().nullable().default(null),
  openInNewTab: z.boolean().default(false),
});

const GoToRowSettingSchema = z
  .object({
    rowId: z.union([z.string(), z.number()]).nullable().default(null),
  })
  .default({ rowId: null });

export const PathStringTypeSchema = z.custom<
  | `section:${PathNumberType},${PathNumberType}-slot:${PathNumberType},${PathNumberType}-stack:${PathNumberType},${PathNumberType}-publication:${PathNumberType},${PathNumberType}`
  | `section:${PathNumberType},${PathNumberType}-slot:${PathNumberType},${PathNumberType}`
  | `section:${PathNumberType},${PathNumberType}`
  | ""
  | undefined
>((val) => {
  return (
    val === undefined ||
    val === "" ||
    /^(section:(\d+|x),(\d+|x)-slot:(\d+|x),(\d+|x)-stack:(\d+|x),(\d+|x)-publication:(\d+|x),(\d+|x)|section:(\d+|x),(\d+|x)-slot:(\d+|x),(\d+|x)|section:(\d+|x),(\d+|x)|)$/.test(
      val as string
    )
  );
});

export const LinkContentScheme = z.discriminatedUnion("selectedGoToOption", [
  z.object({
    selectedGoToOption: z.literal(SelectedGoToOption.GO_TO_PAGE),
    goToPageSetting: GoToPageSettingSchema,
    showLinkOptions: z.boolean().optional(),
    goToRowSetting: GoToRowSettingSchema.optional(),
    forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
    openPopupSetting: GoToRowSettingSchema.optional(),
    closePopupSetting: GoToRowSettingSchema.optional(),
  }),
  z.object({
    selectedGoToOption: z.literal(SelectedGoToOption.GO_TO_ROW),
    goToRowSetting: GoToRowSettingSchema,
    showLinkOptions: z.boolean().optional(),
    goToPageSetting: GoToPageSettingSchema.optional(),
    forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
    openPopupSetting: GoToRowSettingSchema.optional(),
    closePopupSetting: GoToRowSettingSchema.optional(),
  }),
  z.object({
    selectedGoToOption: z.literal(SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK),
    goToPageSetting: GoToPageSettingSchema.optional(),
    goToRowSetting: GoToRowSettingSchema.optional(),
    showLinkOptions: z.boolean().optional(),
    forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).default({}),
    openPopupSetting: GoToRowSettingSchema.optional(),
    closePopupSetting: GoToRowSettingSchema.optional(),
  }),
  z.object({
    selectedGoToOption: z.literal(SelectedGoToOption.OPEN_POPUP),
    goToPageSetting: GoToPageSettingSchema.optional(),
    goToRowSetting: GoToRowSettingSchema.optional(),
    showLinkOptions: z.boolean().optional(),
    forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
    openPopupSetting: GoToRowSettingSchema,
    closePopupSetting: GoToRowSettingSchema.optional(),
  }),
  z.object({
    selectedGoToOption: z.literal(SelectedGoToOption.CLOSE_POPUP),
    goToPageSetting: GoToPageSettingSchema.optional(),
    goToRowSetting: GoToRowSettingSchema.optional(),
    showLinkOptions: z.boolean().optional(),
    forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
    openPopupSetting: GoToRowSettingSchema.optional(),
    closePopupSetting: GoToRowSettingSchema,
  }),
]);
