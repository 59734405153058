import React, { useEffect, useState } from "react";
import cleanDeep from "clean-deep";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import * as myAddressList from "@ecp-redux/api/myAddressList";
import {
  IBillingPostalAddMyAddressRequest,
  MyAddressPostalBilling,
  MyAddressType,
} from "@ecp-redux/dto/myAddresses.type";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import { scrollIntoTop } from "../../helpers/scrollIntoTop";
import { isNIP, isPhone, isZipCode } from "../../helpers/validators";
import { useAddAlert } from "../../redux/slices/alertsSlice";
import { Checkbox } from "../../shared/components/CheckboxesList/CheckboxesList";
import Dropdown from "../../shared/components/Dropdown/Dropdown";
import { InputText } from "../../shared/components/Input/Inputs";
import { StyledLine } from "../../shared/components/Input/StyledInput/StyledInput";
import { FormRadio } from "../../shared/components/Radio/Radio";
import { ReactComponent as ArrowDown } from "../../shared/icons/arrowDown.svg";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import {
  StyledDropdownList,
  StyledToggler,
} from "../../shared/styleElements/StyledSearchResultsDropdown/SearchResultsDropdown.styled";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import { StyledButtonsContainer } from "../BoxMyAccountMyData/BoxMyAccountMyData.styled";
import {
  IBoxMyAccountMyAddressListMessages,
  IBoxMyAccountMyAddressListSettings,
} from "./BoxMyAccountMyAddressList.types";
import { StyledBoxMyAccountMyAddressList } from "./StyledBoxMyAccountMyAddressList.styled";

type AddresType = "private" | "company";

interface BoxMyAccountMyAddressListForm
  extends Omit<IBillingPostalAddMyAddressRequest, "type"> {
  addresType: AddresType;
}

interface NewAddressFormProps {
  disableForm: () => void;
  addressType: MyAddressType.POSTAL | MyAddressType.BILLING;
  editData: MyAddressPostalBilling | null;
}

const BoxMyAccountMyAddressListForm: React.FC<NewAddressFormProps> = ({
  disableForm,
  addressType,
  editData,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyAddressListMessages,
    IBoxMyAccountMyAddressListSettings
  >();

  const { addAlert } = useAddAlert();

  const newDeliveryAddress: BoxMyAccountMyAddressListForm = {
    firstName: editData?.firstName ?? "",
    lastName: editData?.lastName ?? "",
    street: editData?.street ?? "",
    streetNumber: editData?.streetNumber ?? "",
    flatNumber: editData?.flatNumber ?? "",
    city: editData?.city ?? "",
    zipCode: editData?.zipCode ?? "",
    country: editData?.country ?? "",
    companyName: editData?.companyName ?? "",
    nip: editData?.nip ?? "",
    phone: editData?.phone ?? "",
    addresType: editData?.companyName ? "company" : "private",
    billing: addressType === "BILLING",
    defaultAddress: editData?.defaultAddress ?? false,
  };

  const [addAddress] = myAddressList.usePostAddNewAddressMutation();
  const [editAddress] = myAddressList.usePutEditAddressMutation();

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(
        messages.myaccount_address_list_edit_invoice_empty_verification_inputs
      ),
    lastName: yup
      .string()
      .required(
        messages.myaccount_address_list_edit_invoice_empty_verification_inputs
      ),
    street: yup
      .string()
      .required(
        messages.myaccount_address_list_edit_invoice_empty_verification_inputs
      ),
    streetNumber: yup.string().notRequired(),
    city: yup
      .string()
      .required(
        messages.myaccount_address_list_edit_invoice_empty_verification_inputs
      ),
    zipCode: isZipCode(
      messages.myaccount_address_list_edit_invoice_syntax_verification_zip_code,
      messages.myaccount_address_list_edit_invoice_empty_verification_inputs
    ),
    phone: isPhone(
      messages.myaccount_address_list_edit_invoice_syntax_verification_phone
    ).required(
      messages.myaccount_address_list_edit_invoice_empty_verification_inputs
    ),
    defaultAddress: yup.boolean(),
    addresType: yup.string(),
    nip: yup.string().when("addresType", {
      is: "company",
      then: isNIP(
        messages.myaccount_address_list_edit_invoice_wrong_nip_message
      ),
    }),
    companyName: yup.string().when("addresType", {
      is: "company",
      then: yup
        .string()
        .required(
          messages.myaccount_address_list_edit_invoice_empty_verification_inputs
        ),
    }),
  });

  const BoxMyAccountMyAddressListFormMethods =
    useForm<BoxMyAccountMyAddressListForm>({
      defaultValues: newDeliveryAddress,
      resolver: yupResolver(validationSchema),
      mode: "onBlur",
      delayError: 500,
    });

  const { handleSubmit, control, register, watch, resetField } =
    BoxMyAccountMyAddressListFormMethods;

  const onSubmit = ({
    addresType,
    country,
    ...data
  }: BoxMyAccountMyAddressListForm) => {
    const bodyFromForm: IBillingPostalAddMyAddressRequest = {
      ...data,
      type: "POSTAL",
      billing: addressType === "BILLING",
    };
    const cleanedEmptyStringsBody = cleanDeep(
      bodyFromForm
    ) as IBillingPostalAddMyAddressRequest;

    if (editData == null) {
      addAddress(cleanedEmptyStringsBody).then(() => {
        disableForm();
        scrollIntoTop();
        addAlert({
          code: "ADDRESS_ADDED_SUCCESS",
          message:
            addressType === "POSTAL"
              ? messages.myaccount_address_list_edit_delivery_consent_success
              : messages.myaccount_address_list_edit_invoice_consent_success,
        });
      });
    } else {
      editAddress({
        ...cleanedEmptyStringsBody,
        addressId: editData.id,
      }).then(() => {
        scrollIntoTop();
        addAlert({
          code: "ADDRESS_ADDED_SUCCESS",
          message:
            addressType === "POSTAL"
              ? messages.myaccount_address_list_edit_delivery_consent_success
              : messages.myaccount_address_list_edit_invoice_consent_success,
        });
        disableForm();
      });
    }
  };

  const isPrivate = watch("addresType") === "private";

  useEffect(() => {
    if (isPrivate) {
      resetField("nip");
      resetField("companyName");
    }
  }, [isPrivate]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledBoxMyAccountMyAddressList
      data-testid="BoxMyAccountMyAddressListForm"
      className="my-account__delivery-address-container"
    >
      <StyledText
        className="my-account__delivery-address-container__title"
        $settings={{
          font: settings.myaccount_address_list_delivery_address_label_typo,
          text: {
            color: settings.myaccount_address_list_delivery_address_label_color,
          },
        }}
        show={
          addressType === MyAddressType.POSTAL
            ? isNotEmptyMessage(
                messages.myaccount_address_list_edit_delivery_title
              )
            : isNotEmptyMessage(
                messages.myaccount_address_list_edit_invoice_title
              )
        }
      >
        {addressType === MyAddressType.POSTAL
          ? messages.myaccount_address_list_edit_delivery_title
          : messages.myaccount_address_list_edit_invoice_title}
      </StyledText>

      <FormProvider {...BoxMyAccountMyAddressListFormMethods}>
        <form
          onSubmit={handleSubmit((d) => onSubmit(d))}
          autoComplete="off"
          className="my-account__delivery-address-container__form"
        >
          <div className="my-account__delivery-address-container__form-container">
            <FormRadio
              {...register("addresType")}
              label={
                messages.myaccount_address_list_edit_invoice_heading_private
              }
              value="private"
              textStyles={{
                labelColor:
                  settings.myaccount_address_list_invoice_address_value_color,
                labelTypo:
                  settings.myaccount_address_list_invoice_address_value_typo,
              }}
            />
            <FormRadio
              {...register("addresType")}
              label={
                messages.myaccount_address_list_edit_invoice_heading_company
              }
              value="company"
              textStyles={{
                labelColor:
                  settings.myaccount_address_list_invoice_address_value_color,
                labelTypo:
                  settings.myaccount_address_list_invoice_address_value_typo,
              }}
            />
          </div>
          <InputText
            className="my-account__delivery-address-container__form__name"
            name="firstName"
            control={control}
            placeholder={
              messages.myaccount_address_list_edit_delivery_placeholder_name
            }
            label={messages.myaccount_address_list_edit_delivery_heading_name}
            $settings={settings.myaccount_address_list_edit_input_style}
            maxLength={20}
          />
          <InputText
            className="my-account__delivery-address-container__form__last-name"
            name="lastName"
            control={control}
            placeholder={
              messages.myaccount_address_list_edit_delivery_placeholder_surname
            }
            label={
              messages.myaccount_address_list_edit_delivery_heading_surname
            }
            $settings={settings.myaccount_address_list_edit_input_style}
            maxLength={20}
          />
          <InputText
            className="my-account__delivery-address-container__form__phone"
            name="phone"
            control={control}
            placeholder={
              messages.myaccount_address_list_edit_delivery_placeholder_phone
            }
            label={messages.myaccount_address_list_edit_delivery_heading_phone}
            $settings={settings.myaccount_address_list_edit_input_style}
            mask="999-999-999"
          />
          {!isPrivate && (
            <InputText
              className="my-account__delivery-address-container__form__nip"
              name="nip"
              control={control}
              placeholder={
                messages.myaccount_address_list_edit_invoice_placeholder_nip
              }
              label={messages.myaccount_address_list_edit_invoice_heading_nip}
              $settings={settings.myaccount_address_list_edit_input_style}
              mask="999-999-99-99"
            />
          )}
          {!isPrivate && (
            <InputText
              className="my-account__delivery-address-container__form__company-name"
              name="companyName"
              control={control}
              placeholder={
                messages.myaccount_address_list_edit_invoice_placeholder_company_name
              }
              label={
                messages.myaccount_address_list_edit_invoice_heading_company_name
              }
              $settings={settings.myaccount_address_list_edit_input_style}
              maxLength={100}
            />
          )}
          <InputText
            className="my-account__delivery-address-container__form__street"
            name="street"
            control={control}
            placeholder={
              messages.myaccount_address_list_edit_delivery_placeholder_street
            }
            label={messages.myaccount_address_list_edit_delivery_heading_street}
            $settings={settings.myaccount_address_list_edit_input_style}
            maxLength={75}
          />
          <StyledLine>
            <InputText
              className="my-account__delivery-address-container__form__street-number"
              name="streetNumber"
              control={control}
              placeholder={
                messages.myaccount_address_list_edit_delivery_placeholder_house
              }
              label={
                messages.myaccount_address_list_edit_delivery_heading_house
              }
              $settings={
                settings.myaccount_address_list_edit_half_width_input_style
              }
              maxLength={10}
            />
            <InputText
              className="my-account__delivery-address-container__form__flat-number"
              name="flatNumber"
              control={control}
              placeholder={
                messages.myaccount_address_list_edit_delivery_placeholder_flat
              }
              label={messages.myaccount_address_list_edit_delivery_heading_flat}
              $settings={
                settings.myaccount_address_list_edit_half_width_input_style
              }
              maxLength={10}
            />
          </StyledLine>
          <InputText
            className="my-account__delivery-address-container__form__city"
            name="city"
            control={control}
            placeholder={
              messages.myaccount_address_list_edit_delivery_placeholder_city
            }
            label={messages.myaccount_address_list_edit_delivery_heading_city}
            $settings={settings.myaccount_address_list_edit_input_style}
            maxLength={35}
          />
          <InputText
            className="my-account__delivery-address-container__form__zip-code"
            name="zipCode"
            control={control}
            placeholder={
              messages.myaccount_address_list_edit_delivery_placeholder_zip_code
            }
            label={
              messages.myaccount_address_list_edit_delivery_heading_zip_code
            }
            $settings={settings.myaccount_address_list_edit_input_style}
            mask="99-999"
          />
          <StyledText
            className="my-account__delivery-address-container__form__country__heading"
            $settings={{
              font: settings.myaccount_address_list_country_select_label_typo,
              text: {
                color:
                  settings.myaccount_address_list_country_select_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.myaccount_address_list_edit_delivery_heading_country
            )}
          >
            {messages.myaccount_address_list_edit_delivery_heading_country}
          </StyledText>
          {/* Dropdown logic to add with next task */}
          <Dropdown
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            buttonContent={
              <StyledToggler
                $settings={{
                  font: settings.myaccount_address_list_country_select_value_typo,
                  text: {
                    color:
                      settings.myaccount_address_list_country_select_value_color,
                  },
                }}
                onClick={() => setIsOpen(!isOpen)}
                renderAs="button"
                isOpen={isOpen}
                disabled
              >
                <span>Polska</span>
                <ArrowDown />
              </StyledToggler>
            }
            selectContainerContent={
              <StyledDropdownList
                $settings={{
                  font: settings.myaccount_address_list_country_select_value_typo,
                  text: {
                    color:
                      settings.myaccount_address_list_delivery_address_label_color,
                  },
                }}
                renderAs="ul"
              ></StyledDropdownList>
            }
          ></Dropdown>

          <Checkbox
            control={control}
            name="defaultAddress"
            label={
              messages.myaccount_address_list_edit_delivery_default_address
            }
            textStyles={{
              labelTypo: settings.myaccount_address_list_main_address_typo,
              labelColor: settings.myaccount_address_list_main_address_color,
              errorTypo:
                settings.myaccount_address_list_invoice_address_value_typo,
              errorColor:
                settings.myaccount_address_list_edit_success_text_color,
            }}
          />
          <StyledButtonsContainer className="my-account__delivery-address-container__form__buttons_container">
            <StyledButton
              className="my-account__delivery-address-container__form__save-button"
              renderAs="button"
              type="submit"
              $settings={settings.myaccount_address_list_edit_save_button_typo}
              show={isNotEmptyMessage(
                messages.myaccount_address_list_edit_delivery_save_button
              )}
            >
              {messages.myaccount_address_list_edit_delivery_save_button}
            </StyledButton>
            <StyledButton
              className="my-account__delivery-address-container__form__cancel-button"
              renderAs="button"
              $settings={
                settings.myaccount_address_list_edit_cancel_button_typo
              }
              onClick={disableForm}
              show={isNotEmptyMessage(
                messages.myaccount_address_list_edit_delivery_cancel_button
              )}
            >
              {messages.myaccount_address_list_edit_delivery_cancel_button}
            </StyledButton>
          </StyledButtonsContainer>
        </form>
      </FormProvider>
    </StyledBoxMyAccountMyAddressList>
  );
};

export default BoxMyAccountMyAddressListForm;
