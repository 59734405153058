import { useTheme } from "styled-components";
import {
  IGlobalButtonObject,
  IThemeState,
  ITypography,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertTypoIdToValues } from "../../../../../settingsPatterns/settingsPatterns.methods";

type TextHeight = { typo: ITypography; button?: null };
type ButtonHeight = { button: IGlobalButtonObject; typo?: null };

interface SpaceReservationProps {
  reservation: boolean;
  component: TextHeight | ButtonHeight;
}

const SpaceReservation: React.FC<SpaceReservationProps> = ({
  reservation,
  component,
}) => {
  const { typography } = useTheme() as IThemeState;

  let height = 0;
  if (component.typo != null) {
    height = component.typo.lineHeight;
  }
  if (component.button != null) {
    const { button } = component;
    const currentButtonText = convertTypoIdToValues(button.font, typography);

    const width =
      typeof button.border.width === "number" ? button.border.width : 0; // Extra safety check, see oldBorderScheme "width" property

    height =
      button.verticalPadding * 2 +
      currentButtonText.lineHeight +
      (button.border.widthV2?.top ?? width) +
      (button.border.widthV2?.bottom ?? width);
  }

  return reservation ? (
    <div
      style={{ height }}
      className="product-module__content-container__empty-one-attribute"
      data-testid="space-reservation"
    />
  ) : (
    <div
      className="product-module__content-container__empty-all-attributes"
      style={{ display: "none" }}
      data-testid="space-reservation-hidden"
    />
  );
};

export default SpaceReservation;
