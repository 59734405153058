import StyledText from "../../shared/styleElements/StyledText/StyledText";
import {
  StyledFacetsContainer,
  StyledFiltersContainer,
} from "./BoxSearchFilters.styled";
import { ISearchFiltersProps } from "./BoxSearchFilters.types";
import SearchCategories from "./SearchCategories";
import SearchFacet from "./SearchFacet";
import { useSearchFiltersLogic } from "./useSearchFiltersLogic";

const SearchFiltersDesktop: React.FC<ISearchFiltersProps> = ({
  contentSettings,
  displaySettings,
  content,
}) => {
  const {
    changeCheckbox,
    facetsToShow,
    hasCategories,
    childCategory,
    deleteFiltersFromUrl,
  } = useSearchFiltersLogic(content);

  return (
    <StyledFiltersContainer
      categoriesPosition={contentSettings.categories.position}
      className="search-filters-container search-filters-custom-style"
      spaceBetweenCategoriesAndFilters={
        displaySettings.spaceBetweenCategoriesAndFilters
      }
    >
      {contentSettings.categories.show && hasCategories && (
        <SearchCategories
          categoryBranch={childCategory()}
          displaySettings={displaySettings}
          contentSettings={contentSettings}
          content={content}
        />
      )}
      {facetsToShow.length > 0 && (
        <div className="search-filters-container__facets-container">
          <StyledText
            $settings={contentSettings.title}
            className="search-filters-container__facets-title"
          >
            {content.facetsTitle}
          </StyledText>
          {contentSettings.clear.show && (
            <button onClick={deleteFiltersFromUrl}>
              <StyledText
                $settings={contentSettings.clear}
                className="search-filters-container__facets-clear-button"
              >
                {content.clear}
              </StyledText>
            </button>
          )}
          <StyledFacetsContainer
            gap={displaySettings.spaceBetweenFacets}
            className="search-filters-container__facets"
          >
            {facetsToShow.map((attrib) => (
              <SearchFacet
                contentSettings={contentSettings}
                displaySettings={displaySettings}
                content={content}
                facet={attrib}
                selectValue={changeCheckbox}
                key={attrib.attributeId}
              />
            ))}
          </StyledFacetsContainer>
        </div>
      )}
    </StyledFiltersContainer>
  );
};

export default SearchFiltersDesktop;
