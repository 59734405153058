import { ElementRef, PropsWithChildren, useRef } from "react";
import { IDisplaySettingsSection } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import { useOnClickOutside } from "@udecode/plate";
import { isPortalSide } from "../../helpers/helpers";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import { useRowPopupContext } from "../Contexts/RowPopupContext";
import { StyledRowPopup } from "./RowPopup.styled";

interface IPopupProps {
  settings: IDisplaySettingsSection;
}

const RowPopup = ({ children, settings }: PropsWithChildren<IPopupProps>) => {
  const popupRef = useRef<ElementRef<"div">>(null);
  const { closeActivePopup } = useRowPopupContext();

  const { showCloseButton, closeButtonStyle } = settings.popup;

  const handlePopupClose = () => {
    if (settings.popup.closeOnOutsideClick && isPortalSide()) {
      closeActivePopup();
    }
  };

  useOnClickOutside(handlePopupClose, {
    refs: [popupRef],
  });

  return (
    <StyledRowPopup
      $settings={settings}
      ref={popupRef}
      onClick={(e) => e.stopPropagation()}
      data-testid="row-popup"
      className="row-popup"
    >
      {showCloseButton && (
        <StyledButton
          renderAs="button"
          role="button"
          $settings={closeButtonStyle}
          className="close-btn"
          onClick={() => closeActivePopup()}
          data-testid="styled-button-component"
        />
      )}
      {children}
    </StyledRowPopup>
  );
};

export default RowPopup;
