import React, { memo } from "react";
import classNames from "classnames";
import { VariantDto } from "@ecp-redux/dto/searchResults.types";
import LinkWrapper from "../../../../../../global/components/LinkWrapper/LinkWrapper";
import StyledOption from "../../../../../../shared/styleElements/StyledOption/StyledOption";
import StyledButton from "../../../../../styleElements/StyledButton/StyledButton";
import { TVariant } from "../../ProductVariants/ProductVariants.types";
import { StyledVariantsContainer } from "./VariantsList.styled";
import { useVariantsList } from "./useVariantsList";

interface IVariantsListProps {
  variantSettings: TVariant;
  variants: VariantDto[];
  productSku: string;
}

const VariantsList: React.FC<IVariantsListProps> = ({
  variantSettings,
  variants,
  productSku,
}) => {
  const {
    wrapperRef,
    elementRefs,
    wrapperWidth,
    elementsInRow,
    showLabel,
    labelText,
    optionStyle,
    labelStyle,
    maxNumbersOfRows,
  } = useVariantsList(variantSettings);

  return (
    <StyledVariantsContainer
      ref={wrapperRef}
      key={wrapperWidth}
      $variantSettings={variantSettings}
      $showDefaultLabelButton={!!elementsInRow.length && showLabel}
      className="variants-list"
    >
      {!elementsInRow.length &&
        variants
          .filter((v) => v.inStock)
          .map((variant, index) => (
            <StyledOption
              key={variant.sku + index}
              className={classNames(
                "variants-list__element",
                variant.sku === productSku && "active-button"
              )}
              option={optionStyle}
              url={variant.productUrl}
              ref={(el: HTMLButtonElement) => (elementRefs.current[index] = el)}
              text={variant.attribute.label}
            />
          ))}

      {(elementsInRow.length && !(elementsInRow.length > maxNumbersOfRows)) ||
      !showLabel ? (
        elementsInRow.map(
          (row, index) =>
            index < maxNumbersOfRows && (
              <div className="variants-list__sorted-list" key={index}>
                {row.map((element, idx) => {
                  const { url } = element.dataset;
                  const isActiveVariant =
                    element.classList.contains("active-button");

                  return (
                    <LinkWrapper key={idx} href={url}>
                      <StyledOption
                        className={classNames(
                          "variants-list__sorted-element",
                          isActiveVariant && "active-button"
                        )}
                        option={optionStyle}
                        disabled={!url}
                        text={element.textContent ?? ""}
                      />
                    </LinkWrapper>
                  );
                })}
              </div>
            )
        )
      ) : (
        <StyledButton
          renderAs="button"
          className="variants-list__label_element"
          $settings={labelStyle}
        >
          {labelText}
        </StyledButton>
      )}
    </StyledVariantsContainer>
  );
};

export default memo(VariantsList);
