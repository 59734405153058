import { useState } from "react";
import { nanoid } from "nanoid";
import { useTheme } from "styled-components";
import { IOrderB2B } from "@ecp-redux/dto/cartB2B.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  StyledLogisticMinimumContent,
  StyledLogisticMinimumProgressBarContainer,
} from "../../../boxes/CartB2B/BoxCartB2B/components/LogisticMinimumProgressBar";
import ImageWrapper from "../../../global/components/ImageWrapper/ImageWrapper";
import { formatPrice } from "../../../helpers/helpers";
import { convertTypoIdToValues } from "../../../settingsPatterns/settingsPatterns.methods";
import ProgressBar from "../../../shared/components/ProgressBar/ProgressBar";
import { StyledSpaceKeeper } from "../../../shared/components/domain/Product/ProductModule/ProductModule.styled";
import { ReactComponent as ArrowDown } from "../../../shared/icons/arrowDown.svg";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledOrderAccordionMiniCartB2B } from "../BoxMiniCartB2B.styled";
import {
  IBoxMiniCartB2BMessages,
  IBoxMiniCartB2BSettings,
} from "../BoxMiniCartB2B.types";

const BoxMiniCartB2BOrderAccordion = ({
  order,
  idx,
}: {
  order: IOrderB2B;
  idx: number;
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMiniCartB2BMessages,
    IBoxMiniCartB2BSettings
  >();
  const {
    advanceSettings: { messages: globalMessages, settings: globalSettings },
    typography,
  } = useTheme() as IThemeState;

  const [isExpanded, setIsExpanded] = useState(true);

  const cartLinesWithUniqueIds = order.cartLines.map((cartLine) => ({
    ...cartLine,
    cartLineUniqueId: nanoid(),
  }));

  return (
    <StyledOrderAccordionMiniCartB2B
      settings={settings}
      isExpanded={isExpanded}
      className="order-accordion"
    >
      <div
        onClick={() => setIsExpanded((prev) => !prev)}
        className="order-accordion__accordion-header"
      >
        <ArrowDown
          width="19px"
          height="19px"
          className="order-accordion__accordion-header__arrow"
        />
        <StyledText
          $settings={{
            font: settings.mini_cart_order_header_typo,
            text: { color: settings.mini_cart_order_header_color },
          }}
        >
          {`${messages.mini_cart_order_header_label} ${idx + 1}`}
        </StyledText>
        <StyledText
          renderAs="span"
          $settings={{
            font: settings.mini_cart_single_order_items_typo,
            text: { color: settings.mini_cart_single_order_items_color },
          }}
        >
          {`(${cartLinesWithUniqueIds.length} ${messages.mini_cart_summary_items_sufix})`}
        </StyledText>
      </div>
      {isExpanded && (
        <div className="order-accordion__accordion-content">
          <StyledLogisticMinimumProgressBarContainer className="order-accordion__accordion-content__logistic-bar-wrapper">
            <ProgressBar
              actualValue={order.netLogisticMin - order.netLogisticMinMissing}
              fullValue={order.netLogisticMin}
              fillerColor={
                order.netLogisticMinMissing === 0
                  ? settings.mini_cart_logistic_bar_progress_success_background_color
                  : settings.mini_cart_logistic_bar_progress_background_color
              }
              borderColor={settings.mini_cart_logistic_bar_border_color}
              fillerBorderColor={
                order.netLogisticMinMissing === 0
                  ? settings.mini_cart_logistic_bar_progress_success_background_color
                  : settings.mini_cart_logistic_bar_progress_background_color
              }
            />
            <StyledLogisticMinimumContent>
              <StyledText
                $settings={{
                  font: settings.mini_cart_logistic_bar_min_typo,
                  text: {
                    color: settings.mini_cart_logistic_bar_min_color,
                  },
                }}
              >
                {messages.mini_cart_logistic_bar_minimum_label}
              </StyledText>
              <StyledText
                $settings={{
                  font: settings.mini_cart_logistic_bar_left_typo,
                  text: {
                    color: settings.mini_cart_logistic_bar_left_color,
                  },
                }}
              >
                {`${
                  messages.mini_cart_logistic_bar_minimum_left_label
                } ${formatPrice(
                  order.netLogisticMinMissing < 0
                    ? 0
                    : order.netLogisticMinMissing,
                  globalMessages.currencyShort
                )}`}
              </StyledText>
            </StyledLogisticMinimumContent>
          </StyledLogisticMinimumProgressBarContainer>
          {cartLinesWithUniqueIds.map((cartLine) => (
            <div
              className="order-accordion__accordion-content__order"
              key={cartLine.cartLineUniqueId}
            >
              <div className="order-accordion__accordion-content__order__product-image">
                <ImageWrapper
                  width={60}
                  imageUrl={
                    cartLine.photoUrl ?? globalSettings.dynamicBoxImagePlug
                  }
                  seoDescription={cartLine.name}
                  imageFit
                  imageAlignment={{
                    horizontal: AlignmentHorizontalOption.CENTER,
                    vertical: AlignmentVerticalOption.CENTER,
                  }}
                />
              </div>
              <div className="order-accordion__accordion-content__order__product-info">
                <StyledSpaceKeeper
                  height={
                    convertTypoIdToValues(
                      settings.mini_cart_single_item_title_typo,
                      typography
                    ).lineHeight
                  }
                  rows={1}
                >
                  <StyledText
                    $settings={{
                      font: settings.mini_cart_single_item_title_typo,
                      text: {
                        color: settings.mini_cart_single_item_title_color,
                      },
                    }}
                  >
                    {cartLine.name}
                  </StyledText>
                </StyledSpaceKeeper>
                <StyledText
                  $settings={{
                    font: settings.mini_cart_single_item_price_typo,
                    text: {
                      color: settings.mini_cart_single_item_price_color,
                    },
                  }}
                >
                  {formatPrice(
                    cartLine.productPriceDetails.grossValue,
                    globalMessages.currencyShort
                  )}
                </StyledText>
                <div className="order-accordion__accordion-content__order__product-info__line">
                  <div>
                    <StyledText
                      $settings={{
                        font: settings.mini_cart_single_item_items_typo,
                        text: {
                          color: settings.mini_cart_single_item_items_color,
                        },
                      }}
                    >
                      {`${cartLine.quantity} ${messages.mini_cart_product_qty_sufix}`}
                    </StyledText>
                  </div>
                  <div>
                    <StyledText
                      $settings={{
                        font: settings.mini_cart_single_item_gross_price_typo,
                        text: {
                          color:
                            settings.mini_cart_single_item_gross_price_color,
                        },
                      }}
                    >
                      {`${formatPrice(
                        cartLine.productPriceDetails.grossValue *
                          cartLine.quantity,
                        globalMessages.currencyShort
                      )} ${messages.mini_cart_gross_price_label}`}
                    </StyledText>
                    <StyledText
                      $settings={{
                        font: settings.mini_cart_single_item_net_price_typo,
                        text: {
                          color: settings.mini_cart_single_item_net_price_color,
                        },
                      }}
                    >
                      {`${formatPrice(
                        cartLine.productPriceDetails.netValue *
                          cartLine.quantity,
                        globalMessages.currencyShort
                      )} ${messages.mini_cart_netto_price_label}`}
                    </StyledText>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </StyledOrderAccordionMiniCartB2B>
  );
};

export default BoxMiniCartB2BOrderAccordion;
