import { useState } from "react";
import {
  IBoxSearchResultsB2BMessages,
  IBoxSearchResultsB2BSettings,
  SortOption,
} from "../../../../../boxes/BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import {
  IBoxTableDataMessages,
  IBoxTableDataSettings,
} from "../../../../../boxes/BoxTableData/BoxTableData.types";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import CloseIcon from "../../../../icons/CloseIcon";
import StyledButton from "../../../../styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../../styleElements/StyledText/StyledText";
import { Radio } from "../../../Radio/Radio";
import { StyledSortB2B, StyledSortB2BParameters } from "./SortB2B.styled";
import { SortB2BProps, useSortB2B } from "./useSortB2B";

const SortB2B: React.FC<SortB2BProps> = ({ closeSorting }) => {
  const { settings, messages } = useMessagesSettingsContext<
    IBoxSearchResultsB2BMessages | IBoxTableDataMessages,
    IBoxSearchResultsB2BSettings | IBoxTableDataSettings
  >();

  const {
    findSortElementMessageKey,
    chosenB2BSortQuery,
    sortOptions,
    setPageSize,
  } = useSortB2B();

  const [sortingOption, setSortingOption] =
    useState<SortOption>(chosenB2BSortQuery);

  return (
    <StyledSortB2B
      topBackgroundColor={
        settings.srp_mobile_sorting_parameter_top_background_color
      }
      backgroundColor={settings.srp_mobile_sorting_parameter_background_color}
    >
      <div className="sort-b2b">
        <div className="sort-b2b__top">
          <StyledText
            $settings={{
              text: {
                color: settings.srp_mobile_sorting_label_color,
              },
              font: settings.srp_mobile_sorting_label_typo,
            }}
          >
            {messages.srp_mobile_sorting_label}
          </StyledText>
          <CloseIcon
            fill="black"
            height={18}
            width={18}
            onClick={closeSorting}
          />
        </div>
        <div className="sort-b2b__parameters">
          <StyledSortB2BParameters
            $settings={{
              text: {
                color: settings.srp_mobile_sorting_parameter_value_color,
              },
              font: settings.srp_mobile_sorting_parameter_value_typo,
            }}
            backgroundColor={
              settings.srp_mobile_sorting_parameter_background_color
            }
            renderAs="ul"
          >
            {sortOptions.map((el) => {
              return (
                <li key={messages[findSortElementMessageKey(el)]}>
                  <Radio
                    onClick={() => setSortingOption(el)}
                    checked={
                      sortingOption.type === el.type &&
                      sortingOption.direction === el.direction
                    }
                  >
                    {messages[findSortElementMessageKey(el)]}
                  </Radio>
                </li>
              );
            })}
          </StyledSortB2BParameters>
        </div>
        <StyledButton
          renderAs="button"
          role="button"
          className="sort-b2b__button"
          $settings={settings.srp_mobile_sorting_parameter_button_style}
          onClick={() => {
            setPageSize(undefined, sortingOption);
            closeSorting();
          }}
          data-testid="sort-b2b-show-results-button"
        >
          {messages.srp_mobile_sorting_button_label}
        </StyledButton>
      </div>
    </StyledSortB2B>
  );
};

export default SortB2B;
