import {
  ISearchResultsRequest,
  ISearchResultsResponse,
  ISearchResultsTransformResponse,
  ISrpRequestV2,
  ISrpTransformResponseV2,
  ISrpWithSearchTransformResponseV2,
  isCategoryValue,
} from "../dto/searchResults.types";
import { API, api } from "./api";
import {
  srpProjectionMapper,
  transformResponseSrp,
} from "./searchResults.methods";

export const searchResults = api.injectEndpoints({
  endpoints: (builder) => ({
    getSearchResults: builder.query<
      ISearchResultsTransformResponse,
      ISearchResultsRequest
    >({
      query: ({ query }) => API.getSearchResults.endpoint(query),
      transformResponse: (
        result: ISearchResultsResponse,
        _,
        { strictCategory }
      ) => {
        const productSkus = result.products?.map((product) => product.sku);

        if (strictCategory) {
          const parameters = result?.parameters.map((param) => {
            if (isCategoryValue(param)) {
              return {
                ...param,
                values: param.values.filter((subCategory) => {
                  return strictCategory.length >= subCategory.id.length
                    ? strictCategory.startsWith(subCategory["id"])
                    : subCategory["id"].startsWith(strictCategory);
                }),
              };
            }
            return param;
          });
          return { ...result, products: productSkus, parameters };
        }
        return { ...result, products: productSkus };
      },
      providesTags: ["CONTRACTORS"],
    }),
    getSearchWithSrpResultsV2: builder.query<
      ISrpWithSearchTransformResponseV2,
      { searchQueries: string; projection: string[] }
    >({
      query: ({ searchQueries, ...body }) => {
        return {
          url: API.getSearchWithSrpResultsV2.endpoint(searchQueries),
          method: "POST",
          body: {
            ...body,
            projection: srpProjectionMapper(body.projection),
          },
        };
      },
      transformResponse: (result: {
        products: ISrpTransformResponseV2[];
        size: number;
      }) => ({
        ...transformResponseSrp(result.products),
        size: result.size,
      }),
      providesTags: ["SEARCH_RESULTS", "CONTRACTORS"],
    }),
    getPostProducts: builder.query<
      ISrpWithSearchTransformResponseV2,
      ISrpRequestV2
    >({
      query: (body) => {
        return {
          url: API.getSearchProducts.endpoint(),
          method: "POST",
          body: {
            ...body,
            projection: srpProjectionMapper(body.projection),
          },
        };
      },
      transformResponse: (result: ISrpTransformResponseV2[]) => ({
        ...transformResponseSrp(result),
      }),
      providesTags: ["SEARCH_RESULTS"],
    }),
  }),
});

export const {
  useGetSearchResultsQuery,
  useLazyGetSearchResultsQuery,
  useLazyGetPostProductsQuery,
  useGetPostProductsQuery,
  useGetSearchWithSrpResultsV2Query,
} = searchResults;

export const { getPostProducts, getSearchWithSrpResultsV2, getSearchResults } =
  searchResults.endpoints;
