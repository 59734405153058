import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  TColorId,
  VerticalPosition,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../global/global";
import { alignmentOptionsToValue } from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";

export const StyledFacetsContainer = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    ${StyledButton} {
      cursor: pointer;
      width: 100%;
    }
    .search-filters-container__facets__button-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;

      &__clear-button,
      &__show-result-button {
        width: 100%;
      }
    }
  }
`;

export const StyledFacetAccordion = styled.div<{
  accordionAlignment: AlignmentHorizontalOption;
}>`
  .accordion-container {
    display: flex;
    flex-direction: column;
    align-items: ${({ accordionAlignment }) =>
      alignmentOptionsToValue(accordionAlignment)};
  }
`;

export const StyledFiltersContainer = styled.div<{
  categoriesPosition: VerticalPosition;
  spaceBetweenCategoriesAndFilters: number;
  backgroundColor?: TColorId;
}>(
  ({
    categoriesPosition,
    backgroundColor,
    theme,
    spaceBetweenCategoriesAndFilters,
  }) => {
    return `
  display: flex;
  flex-direction: ${
    categoriesPosition === VerticalPosition.BOTTOM ? "column-reverse" : "column"
  };
  overflow-y: scroll;
  .search-filters-container__facets-container{
    margin-top: ${spaceBetweenCategoriesAndFilters}px;
  }
 
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 95%;
    .search-filters-container {
      &__categories__title,
      &__facets-title {
        padding: 8px 16px;
        gap: 20px;
        ${
          backgroundColor &&
          `background-color: ${convertColorIdToHex(
            backgroundColor,
            theme.colorPalette
          )}`
        };
      }
      &__categories__category-name,
      &__facets { 
        padding: 8px 16px;
      }

      &__facets-title {
        margin-top: 20px;
      }
    }
  }
`;
  }
);

export const StyledMobileSearchFiltersContainer = styled.div<{
  backgroundColor: TColorId;
}>(({ backgroundColor, theme }) => {
  return `
  .search-filters__header {
    background-color: ${convertColorIdToHex(
      backgroundColor,
      theme.colorPalette
    )};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 8px 16px;
  }

  .search-filters-container__close-icon-container {
    background-color: ${convertColorIdToHex(
      backgroundColor,
      theme.colorPalette
    )};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 100%;
    cursor: pointer;
  }
`;
});
