import { useEffect } from "react";
import { TSizeCSS } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { StyledPopup, StyledPopupBackdrop } from "./Popup.styled";
import PopupPortal from "./PopupPortal";

export interface IPopupProps {
  closePopup?: () => void;
  desktopWidth?: TSizeCSS;
  className?: string;
}

const Popup: React.FC<IPopupProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  closePopup,
  ...props
}) => {
  const close = () => {
    closePopup && closePopup();
  };

  useEffect(() => {
    document.documentElement.classList.toggle("overflow-hidden", true);

    return () => {
      document.documentElement.classList.toggle("overflow-hidden", false);
    };
  }, []);

  return (
    <PopupPortal>
      <StyledPopupBackdrop>
        <StyledPopup
          className={className}
          {...props}
          data-testid="popupComponent"
        >
          {closePopup && (
            <button
              className="popup__close-button"
              onClick={close}
              data-testid="closePopup"
            >
              <CloseIcon />
            </button>
          )}
          {children}
        </StyledPopup>
      </StyledPopupBackdrop>
    </PopupPortal>
  );
};

export default Popup;
