import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  TColorId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";

export const StyledSearchResultsLabel = styled(StyledText)<{
  backgroundColor: TColorId;
}>(({ backgroundColor, theme }) => {
  return `
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 27px;
  background-color: ${convertColorIdToHex(backgroundColor, theme.colorPalette)}
`;
});

export const StyledSearchResultsParameters = styled(StyledText)`
  padding: 8px 27px;
  background: white;

  li {
    display: flex;
    flex-direction: row;
    width: 100%;
    white-space: nowrap;
    padding: 10px 0 10px 0;
  }

  li:last-child {
    padding-bottom: 35px;
  }
`;

export const StyledCloseArea = styled.div<{
  backgroundColor: TColorId;
}>(({ backgroundColor, theme }) => {
  return `
    background-color: ${convertColorIdToHex(
      backgroundColor,
      theme.colorPalette
    )};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 100%;
`;
});

export const StyledNavigationLeftArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%;
  background-color: white;
  ${StyledButton} {
    width: 90%;
  }

  .navigation__left-area {
    &__button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__separator {
      width: 90%;
      border-bottom: 2px solid #cccccc;
      margin-bottom: 30px;
    }

    &__extended-sort {
      display: flex;
      flex-direction: column;
    }

    &__extended-show-on-page {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const StyledSortingButton = styled.div<{
  align: AlignmentHorizontalOption;
}>`
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  justify-content: ${({ align }) => alignmentOptionsToValue(align)};

  .sortingButtonLabel {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
  }
`;
