import React, { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { useTheme } from "styled-components";
import { PageCategoryType } from "@ecp-pageTypes";
import * as searchResults from "@ecp-redux/api/searchResults";
import {
  IThemeState,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { ProductContext, useCheckPageContext } from "../../helpers/pageContext";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import {
  getProductData,
  getProductGalleryPayload,
  isOnePhotoOrMore,
} from "./BoxPhotoGallery.method";
import { IBoxPhotoGalleryProps } from "./BoxPhotoGallery.types";
import PhotoGalleryMobile from "./PhotoGalleryMobile";
import PhotoGalleryModal from "./elements/PhotoGalleryModal/PhotoGalleryModal";
import PhotoGallerySlider from "./elements/PhotoGallerySlider/PhotoGallerySlider";

export const BoxPhotoGallery: React.FC<IBoxPhotoGalleryProps> = () => {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [photoGallery, setPhotoGallery] = useState<TUrl[]>();
  const context = useCheckPageContext<ProductContext>(PageCategoryType.PRODUCT);

  const contextProductSKU =
    context.type === PageCategoryType.PRODUCT ? context.productSku : undefined;

  const product = searchResults.useGetPostProductsQuery(
    getProductGalleryPayload(contextProductSKU ?? ""),
    { skip: !contextProductSKU }
  );
  const {
    advanceSettings: { settings: globalSettings },
  } = useTheme() as IThemeState;

  const isMobile = useIsMobilePortal();

  const handleShowModal = () => {
    if (!contextProductSKU) return;
    setShowModal((prev) => !prev);
  };

  useEffect(() => {
    if (product?.data) {
      const coverPhoto =
        product.data.products?.length > 0 &&
        product.data.products[0].coverPhoto;
      const photoGallery = product.data.products[0]?.photoGallery ?? [];
      const mainPhoto = coverPhoto || globalSettings.dynamicBoxImagePlug;

      setPhotoGallery([mainPhoto, ...photoGallery].filter(Boolean));
    }
  }, [product.data, globalSettings.dynamicBoxImagePlug]);

  if (product?.data && !photoGallery?.length) {
    return (
      <div className="photo-gallery--empty">{t("global_keys.empty_box")}</div>
    );
  }

  return (
    <>
      {!isMobile && (
        <div className="photo-gallery-desktop">
          {showModal && isOnePhotoOrMore(photoGallery) && (
            <PhotoGalleryModal
              onClose={handleShowModal}
              images={photoGallery}
              setActiveSlide={setActiveSlide}
              activeSlide={activeSlide}
              productData={getProductData(product.data?.products)}
            />
          )}
          <PhotoGallerySlider
            handleClick={handleShowModal}
            productData={getProductData(product.data?.products)}
            images={photoGallery}
            setActiveSlide={setActiveSlide}
          />
        </div>
      )}
      {isMobile && (
        <PhotoGalleryMobile
          handleClick={handleShowModal}
          images={photoGallery}
          productData={getProductData(product.data?.products)}
          setActiveSlide={setActiveSlide}
        />
      )}
    </>
  );
};

export default BoxPhotoGallery;
