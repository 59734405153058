import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineMailOpen } from "react-icons/hi";
import * as auth from "@ecp-redux/api/auth";
import { IUserRemindPasswordForm } from "@ecp-redux/dto/auth.types";
import { yupResolver } from "@hookform/resolvers/yup";
import LinkWrapper from "../../../global/components/LinkWrapper/LinkWrapper";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { Info, StyledCircleIcon } from "../../../shared/components/Info/Info";
import { InputText } from "../../../shared/components/Input/Inputs";
import { StyledAlignWrapper } from "../../../shared/components/StyledAlignWrapper/StyledAlignWrapper";
import useScrollIntoElement from "../../../shared/hooks/useScrollIntoElement";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxRemindPasswordMessages,
  IBoxRemindPasswordSettings,
} from "../BoxRemind.types";
import { getValidationRemindPassSchema } from "../validation/RemindValidationSchemas";

const remindPasswordDefaultValues: IUserRemindPasswordForm = {
  email: "",
};

const RemindSendEmail = () => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxRemindPasswordMessages,
    IBoxRemindPasswordSettings
  >();

  const [postRemindPassword, { isSuccess }] =
    auth.usePostRemindPasswordMutation();

  const remindRef = useRef<HTMLDivElement>(null);

  const scrollIntoElement = useScrollIntoElement(remindRef);

  const sendPasswordEmailFormMethods = useForm<IUserRemindPasswordForm>({
    defaultValues: remindPasswordDefaultValues,
    resolver: yupResolver(getValidationRemindPassSchema(messages)),
    mode: "onBlur",
    delayError: 500,
  });

  const { handleSubmit, control: remindControl } = sendPasswordEmailFormMethods;
  const onSubmit = (data: IUserRemindPasswordForm) => {
    postRemindPassword(data);

    scrollIntoElement();
  };

  return isSuccess ? (
    <Info data-testid="send-email-sucess">
      <>
        <StyledCircleIcon>
          <HiOutlineMailOpen size={24} color="black" />
        </StyledCircleIcon>
        <StyledText
          $settings={{
            font: settings.remind_password_form_title_typo,
            text: { color: settings.remind_password_form_title_color },
          }}
          alignment={settings.remind_password_form_title_align?.toLowerCase()}
          ref={remindRef}
          show={isNotEmptyMessage(messages.remind_password_form_success)}
        >
          {messages.remind_password_form_success}
        </StyledText>
      </>
    </Info>
  ) : (
    <>
      <StyledText
        $settings={{
          font: settings.remind_password_form_title_typo,
          text: { color: settings.remind_password_form_title_color },
        }}
        alignment={settings.remind_password_form_title_align?.toLowerCase()}
        ref={remindRef}
        show={isNotEmptyMessage(messages.remind_password_form_title)}
      >
        {messages.remind_password_form_title}
      </StyledText>
      <StyledText
        $settings={{
          font: settings.remind_password_form_description_typo,
          text: {
            color: settings.remind_password_form_description_color,
          },
        }}
        alignment={settings.remind_password_form_description_align?.toLowerCase()}
        show={isNotEmptyMessage(messages.remind_password_form_description)}
      >
        {messages.remind_password_form_description}
      </StyledText>
      <FormProvider {...sendPasswordEmailFormMethods}>
        <form onSubmit={handleSubmit((d) => onSubmit(d))} autoComplete="off">
          <InputText
            name="email"
            control={remindControl}
            placeholder={messages.remind_password_form_placeholder_email}
            label={messages.remind_password_form_heading_email}
            $settings={settings.remind_password_form_input_style}
          />
          <StyledButton
            renderAs="button"
            type="submit"
            $settings={settings.remind_password_form_button_style}
            show={isNotEmptyMessage(messages.remind_password_form_button)}
          >
            {messages.remind_password_form_button}
          </StyledButton>
          <StyledAlignWrapper
            alignment={settings.remind_password_form_login_align?.toLowerCase()}
          >
            <LinkWrapper
              linkStyle={settings.remind_password_form_link_style}
              fontStyle={settings.remind_password_form_redirect_login_typo}
              href={settings.remind_password_form_redirect_login}
            >
              {messages.remind_password_form_login}
            </LinkWrapper>
          </StyledAlignWrapper>
        </form>
      </FormProvider>
    </>
  );
};

export default RemindSendEmail;
