import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../global/global";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";

export const StyledOrderDetailsWrapper = styled.div<{
  backgroundColorId: TColorId;
}>(({ backgroundColorId, theme }) => {
  const bgcColor = convertColorIdToHex(backgroundColorId, theme.colorPalette);

  return `
          display: flex;
          padding: 20px;
          background-color: ${bgcColor};
          width: 100%; 
          flex-direction: column;
          justify-content: space-between;

          .my-order-details-header__container {
            display: flex;
            justify-content: space-between;

            @media (max-width: ${MOBILE_BREAKPOINT}px) {
              flex-direction: column;
            }
          }

          .my-order-details-total-costs__container {
            .&__main {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            }

            .&__gap-container__column-info-text {
              display: flex;
              gap: 3px;
              flex-direction: column;
            }
          }

          .my-order-details-delivery {
            &__container {
              display: flex;
              justify-content: space-between;

              &__gap-container__dispatch-letter-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
                gap: 20px;
                align-items: center;

                &__dispatch-letter__header__container {
                  max-width: 200px;
                  width: 100%;
                }
              }
            }
          }
        `;
});

export const StyledOrderGapWrapper = styled.div<{
  elementsGap: string;
}>(({ elementsGap }) => {
  return `
            display: flex;
            flex-direction: column;
            gap: ${elementsGap};
            flex: 1;

            @media (max-width: ${MOBILE_BREAKPOINT}px) {
            ${StyledButton} {
              width: 100%;
            } 
          }
        `;
});

export const StyledOrderDetailsActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: flex-end;
`;

export const StyledColumnInfoText = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  align-items: center;

  .column-info-text__message-label {
    max-width: 200px;
    width: 100%;
  }

  .column-info-text__value {
    width: 100%;
  }
`;
