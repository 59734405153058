import { useMemo, useState } from "react";
import classNames from "classnames";
import { getImageNameFromUrl } from "../../../helpers/helpers";
import StyledImage from "../../../settingsPatterns/elements/StyledImage/StyledImage";
import { useLinkContentActions } from "../../../shared/hooks/useLinkContentAction";
import LinkWrapper from "../LinkWrapper/LinkWrapper";
import { checkImageType, imgResizer } from "./ImageWrapper.methods";
import { StyledSimplyImage } from "./ImageWrapper.styled";
import { IImageWrapperProps, ImgType } from "./ImageWrapper.types";
import Image from "./MyNextImage";

const ImageWrapper: React.FC<IImageWrapperProps> = (props) => {
  const [isNextImageLoadedLocal, setIsNextImageLoadedLocal] = useState(false);
  const { getHref, getTarget, linkContentActions } = useLinkContentActions();
  const {
    imageUrl,
    seoDescription,
    imageFit,
    imageAlignment,
    opacity,
    parallax,
    width,
    height,
    link,
    imgTagAsBackground,
    className,
    setIsNextImageLoaded,
  } = props;

  const memoizedSeoDesc = useMemo(
    () =>
      seoDescription ? seoDescription : getImageNameFromUrl(imageUrl ?? ""),
    [seoDescription, imageUrl]
  );

  if (!imageUrl) return null;

  const imgType: ImgType = checkImageType({ ...props });
  const href = link ? getHref(link) : undefined;

  const handleImageLoadComplete = () => {
    setIsNextImageLoaded?.(true);
    setIsNextImageLoadedLocal(true);
  };

  switch (imgType) {
    case "next-resize": {
      return (
        <LinkWrapper
          href={href}
          openInNewTab={link?.goToPageSetting?.openInNewTab}
          className={className}
          onClick={link ? () => linkContentActions(link) : undefined}
        >
          <Image
            src={imgResizer(imageUrl, width ?? 0)}
            alt={memoizedSeoDesc}
            layout={height ? "responsive" : "fill"}
            objectFit={imageFit ? "cover" : "contain"}
            objectPosition={`${imageAlignment.horizontal} ${imageAlignment.vertical}`}
            onLoadingComplete={handleImageLoadComplete}
            style={{
              ...(opacity !== undefined && { opacity: opacity / 100 ?? 1 }),
              visibility: !isNextImageLoadedLocal ? "hidden" : "visible",
            }}
            width={width}
            loading="lazy"
            height={height}
            data-testid="next-image"
            className={className}
          />
        </LinkWrapper>
      );
    }
    case "ecp-resize": {
      return (
        <LinkWrapper
          href={href}
          openInNewTab={link?.goToPageSetting?.openInNewTab}
          className={className}
          onClick={link ? () => linkContentActions(link) : undefined}
        >
          <StyledSimplyImage
            src={imgResizer(imageUrl, width ?? 0)}
            alt={memoizedSeoDesc}
            opacity={opacity ?? 100}
            className={classNames("ecp-image", className)}
            data-testid="ecp-resize-image"
            objectPosition={`${imageAlignment.horizontal} ${imageAlignment.vertical}`}
            objectFit={imageFit ? "contain" : "cover"}
            backgroundMode={imgTagAsBackground}
          />
        </LinkWrapper>
      );
    }
    case "backgorund-img": {
      return (
        <StyledImage
          className={className}
          href={href}
          target={link ? getTarget(link) : undefined}
          role="img"
          data-testid="background-image"
          aria-label={memoizedSeoDesc}
          alt={memoizedSeoDesc}
          onClick={link ? () => linkContentActions(link) : undefined}
          $settings={{
            backgroundUrl: imageUrl,
            fit: imageFit,
            alignment: imageAlignment,
            parallax: !!parallax,
            opacity: opacity,
            width: width,
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default ImageWrapper;
