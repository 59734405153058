import React from "react";
import { useRouter } from "next/router";
import * as auth from "@ecp-redux/api/auth";
import * as myAccount from "@ecp-redux/api/myAccount";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import Popup from "../../../shared/components/Popup/Popup";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledConfirmDeletePopup } from "../BoxMyAccountConsents.styled";
import {
  IBoxMyAccountConsentsMessages,
  IBoxMyAccountConsentsSettings,
} from "./../BoxMyAccountConsents.types";

interface IConfirmDeletePopupProps {
  closePopup: () => void;
}

const ConfirmDeletePopup: React.FC<IConfirmDeletePopupProps> = ({
  closePopup,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountConsentsMessages,
    IBoxMyAccountConsentsSettings
  >();
  const [deleteAccount] = myAccount.useDeleteCustomerAccountMutation();
  const router = useRouter();

  const [logout] = auth.useDeleteLogoutMutation();

  return (
    <Popup
      desktopWidth="600px"
      className="confirm-delete-popup"
      closePopup={closePopup}
    >
      <StyledConfirmDeletePopup className="confirm-delete-popup__content-container">
        <StyledText
          $settings={{
            font: settings.popup_title_typo,
            text: { color: settings.popup_text_color },
          }}
          className="confirm-delete-popup__content-container__title"
          show={isNotEmptyMessage(messages.customer_data_consent_popup_title)}
        >
          {messages.customer_data_consent_popup_title}
        </StyledText>
        <StyledText
          className="confirm-delete-popup__content-container__description"
          $settings={{
            font: settings.popup_description_typo,
            text: { color: settings.popup_text_color },
          }}
          show={isNotEmptyMessage(
            messages.customer_data_consent_popup_description
          )}
        >
          {messages.customer_data_consent_popup_description}
        </StyledText>
        <div className="confirm-delete-popup__content-container__button-container">
          <StyledButton
            className="confirm-delete-popup__content-container__button-container__save-popup-button"
            $settings={settings.popup_yes_button}
            renderAs={"button"}
            data-testid="yesPopupBtn"
            onClick={async () => {
              await deleteAccount();
              await logout();
              router.push(settings.redirect_after_delete_link);
            }}
            show={isNotEmptyMessage(
              messages.customer_data_consent_popup_button_save
            )}
          >
            {messages.customer_data_consent_popup_button_save}
          </StyledButton>
          <StyledButton
            className="confirm-delete-popup__content-container__button-container__cancel-button"
            $settings={settings.popup_no_button}
            renderAs={"button"}
            onClick={closePopup}
            show={isNotEmptyMessage(
              messages.customer_data_consent_popup_button_cancel
            )}
          >
            {messages.customer_data_consent_popup_button_cancel}
          </StyledButton>
        </div>
      </StyledConfirmDeletePopup>
    </Popup>
  );
};

export default ConfirmDeletePopup;
