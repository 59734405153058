import React from "react";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../../../helpers/isNotEmptyMessage";
import Alert from "../../../../../shared/components/Alert/Alert";
import StyledButton from "../../../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../../../BoxCartStepTwo/BoxCartStepTwo.types";
import { StyledSummary } from "../../BoxCartStepOne.styled";
import {
  IBoxCartStepOneMessages,
  IBoxCartStepOneSettings,
} from "../../BoxCartStepOne.types";
import { ISummaryProps } from "./Summary.types";

const Summary: React.FC<ISummaryProps> = ({
  cartSummary,
  disabledNextStep,
  buttonClick,
  isSelectedDeliveryChannel,
}) => {
  const {
    cartValueFromProducts,
    cartValueAfterDiscounts,
    cartDeliveryCosts,
    amountToPay,
  } = cartSummary;
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepOneMessages | IBoxCartStepTwoMessages,
    IBoxCartStepOneSettings | IBoxCartStepTwoSettings
  >();
  const theme = useTheme() as IThemeState;
  const currencyShort = theme.advanceSettings.messages.currencyShort;

  return (
    <StyledSummary
      data-testid="Summary"
      className="summary"
      backgroundColor={settings.basket_step_all_summary_background_color}
      buttonWidth={settings.basket_step_all_summary_button_width}
    >
      <div className="summary_content">
        <StyledText
          className="summary__header"
          $settings={{
            font: settings.basket_step_0_typography_header_2,
            text: { color: settings.basket_step_all_color_main },
          }}
          show={isNotEmptyMessage(messages.summary_header)}
        >
          <p>{messages.summary_header}</p>
        </StyledText>

        <Alert
          alertsCodes={["DELIVERY_CHANNELS_NOT_AVAILABLE", "1010"]}
          backgroundColor={settings.basket_alert_background}
          textColor={settings.basket_alert_typo_color}
          typography={settings.basket_alert_typo}
          closeable={false}
        />

        <StyledText
          className="summary__products-price"
          $settings={{
            font: settings.basket_step_all_typography_paragraph_1,
            text: { color: settings.basket_step_all_color_main },
          }}
          show={isNotEmptyMessage(messages.summary_products_price)}
        >
          <p>{messages.summary_products_price}</p>
          <p>{formatPrice(cartValueFromProducts, currencyShort)}</p>
        </StyledText>
        {cartValueFromProducts - cartValueAfterDiscounts >= 0.01 && (
          <StyledText
            className="summary__save-amount"
            $settings={{
              font: settings.basket_step_all_typography_paragraph_1,
              text: { color: settings.basket_step_all_color_second },
            }}
            show={isNotEmptyMessage(messages.summary_save_ammont)}
          >
            <p>{messages.summary_save_ammont}</p>
            <p>
              {formatPrice(
                cartValueFromProducts - cartValueAfterDiscounts,
                currencyShort
              )}
            </p>
          </StyledText>
        )}
        <StyledText
          className="summary__delivery-cost"
          $settings={{
            font: settings.basket_step_all_typography_paragraph_1,
            text: { color: settings.basket_step_all_color_main },
          }}
          show={
            isSelectedDeliveryChannel
              ? isNotEmptyMessage(messages.summary_delivery_costs_after_select)
              : isNotEmptyMessage(messages.summary_delivery_costs_before_select)
          }
        >
          <p>
            {isSelectedDeliveryChannel
              ? messages.summary_delivery_costs_after_select
              : messages.summary_delivery_costs_before_select}
          </p>
          <p>{formatPrice(cartDeliveryCosts, currencyShort)}</p>
        </StyledText>
        <hr />
        <div className="summary__amount-to-pay">
          <StyledText
            $settings={{
              font: settings.basket_step_all_typography_paragraph_1,
              text: { color: settings.basket_step_all_color_main },
            }}
            show={isNotEmptyMessage(messages.summary_amount_to_pay)}
          >
            <p>{messages.summary_amount_to_pay}</p>
          </StyledText>
          <StyledText
            $settings={{
              font: settings.basket_step_all_amount_to_pay_typography,
              text: { color: settings.basket_step_all_color_main },
            }}
            show={isNotEmptyMessage(messages.summary_amount_to_pay)}
          >
            <p>{formatPrice(amountToPay, currencyShort)}</p>
          </StyledText>
        </div>
        <StyledButton
          className="summary__button"
          $settings={settings.basket_step_2_3_button_summary}
          renderAs={"button"}
          onClick={buttonClick}
          data-testid="cartNextBtn"
          disabled={disabledNextStep}
          show={isNotEmptyMessage(messages.summary_button_next_step)}
        >
          {messages.summary_button_next_step}
        </StyledButton>
      </div>
    </StyledSummary>
  );
};

export default Summary;
