import { useEffect, useState } from "react";
import { PageBuilderBoxToPageBox } from "@ecp-redux/dto/page/pageBuilderToPortalPage";
import {
  AccordionItem,
  StyledAccordionContainer,
} from "../../shared/components/Accordion/Accordion";
import { PortalPath } from "../../shared/portalPath/portalPath";
import Box from "../../structure/Box/Box";
import { useBoxAccordionData } from "./BoxAccordion.methods";
import { IBoxAccordionProps } from "./BoxAccordion.types";

const BoxAccordion: React.FC<IBoxAccordionProps> = (props) => {
  const { content, displaySettings } = useBoxAccordionData(props, props.path);
  const createInitialOpenState = () =>
    displaySettings.showOneAtATime
      ? displaySettings.show.accordionId
      : [displaySettings.show.accordionId];

  const [open, setOpen] = useState<string | string[]>(createInitialOpenState);

  useEffect(() => {
    if (typeof open === "string") {
      setOpen(displaySettings.show.accordionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySettings.show.accordionId]);

  const handleOpen = (boxId: string) => () => {
    if (displaySettings.showOneAtATime && typeof open === "string") {
      setOpen(open === boxId ? "" : boxId);
    } else {
      if (open.includes(boxId) && typeof open === "object") {
        setOpen(open.filter((o) => o !== boxId));
      } else {
        setOpen([...open, boxId]);
      }
    }
  };

  return (
    <StyledAccordionContainer
      accordionSettings={displaySettings}
      className="accordion-custom-style"
    >
      {content !== undefined &&
        content.map((box) => {
          if (box !== undefined) {
            return (
              <AccordionItem
                forceOpen={
                  typeof open === "string"
                    ? open === box.id
                    : open.includes(box.id)
                }
                labelText={box.headerText}
                accordionItemStyle={displaySettings.accordionStyle}
                handleOpenCallback={handleOpen(box.id)}
                accordionSettings={displaySettings}
                disabled={box.boxesToShow === undefined}
                key={`accordionItem-${box.id}`}
              >
                {box?.boxesToShow !== undefined && (
                  <Box
                    boxData={PageBuilderBoxToPageBox(box.boxesToShow)}
                    isEditMode={false}
                    path={new PortalPath()}
                    key={box?.id + box?.headerText}
                  />
                )}
              </AccordionItem>
            );
          }
          return null;
        })}
    </StyledAccordionContainer>
  );
};

export default BoxAccordion;
