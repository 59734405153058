import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../global/global";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";

export const StyledOrderButtonsWrapper = styled.div<{
  elementsGap: string;
}>(({ elementsGap }) => {
  return `
                display: flex;
                align-items: center;
                justify-content: end;
                flex-wrap: wrap;
                gap: ${elementsGap};
    
                @media (max-width: ${MOBILE_BREAKPOINT}px) {
                  width: 100%;
                  flex-direction: column;

                  ${StyledButton} {
                    width: 100%;
                  }
                }
            `;
});

export const StyledOrderHeadingWrapper = styled.div<{
  elementsGap: string;
}>(({ elementsGap }) => {
  return `
              display: flex;
              flex-direction: column;
              flex:auto;
              gap: ${elementsGap};

              .single-order-heading {
                &__container {
                  display: flex;
                  width: 100%;
                  gap: 4px;

                  &__info {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    width: 100%;

                    &__label__container {
                      max-width: 150px;
                      width: 100%;
                    }
                    
                    &__value__container {
                      width: 100%;

                    }
                  }
                }
              }
          `;
});

export const StyledSingleOrderHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const StyledSingleOrderWrapper = styled.div<{
  backgroundColorId: TColorId;
}>(({ backgroundColorId, theme }) => {
  const bgcColor = convertColorIdToHex(backgroundColorId, theme.colorPalette);

  return `
        display: flex;
        padding: 20px 10px;
        background-color: ${bgcColor};
        width: 100%; 
        flex-direction: column;
        justify-content: space-between;
      `;
});

export const StyledSingleProductWrapper = styled.div<{
  elementPaddingX: string;
}>(({ elementPaddingX }) => {
  return `
          display: flex;
          border-bottom: 1px solid #cccccc;
          flex-direction: column;
          padding: ${elementPaddingX} 0;

          :last-of-type {
            border-bottom: none;
            padding-bottom: 0;
          }

          .single-product__container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            

            &__image {
              padding-right: 20px;
              pointer-events: none;
              object-fit: contain;
              object-position: center;
            }

            &__product-info {
              display: flex;
              flex-direction: column;
              gap: 5px;
            }
          }

          .my-order-details-delivery__product {
            &__container {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 20px;
            }

            &-image {
              pointer-events: none;
              width: 200px;
              aspect-ratio: 3/4;
              object-fit: contain;
              object-position: center;
            }

            &__info-container {
              display: flex;
              flex-direction: column;
              gap: 7px;
            }
          }
        `;
});

export const StyledBoxMyAccountMyOrdersWrapper = styled.div<{
  gapElements: string;
}>(({ gapElements }) => {
  return `
        display: flex;
        flex-direction: column;
        gap: ${gapElements};
        margin: 20px;

        .my-account-orders-container__single-order {
          display: grid;
          justify-items: end;
        }
      `;
});

export const StyledOrderActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: flex-end;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    align-items: flex-start;
  }
`;

export const StyledUnavalibleReorderPopup = styled.div`
  .unavailable-reorder-popup__save-button {
    min-width: 100%;
  }

  .unavailable-reorder-popup__button-container {
    margin-top: 15px;
    align-items: center;
    gap: 0.75rem;
  }
`;

export const StyledReorderPopup = styled.div`
  .reorder-popup__description {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .reorder-popup__button-container {
    display: grid;
    align-items: center;
    gap: 0.75rem;

    &__save-button,
    &__cancel-button {
      min-width: 100%;
    }

    @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      grid-template-rows: repeat(2, minmax(0, 1fr));
    }
  }
`;

export const StyledCancelOrderPopup = styled.div`
  .cancel-order-popup__description {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .cancel-order-popup__button-container {
    display: grid;
    align-items: center;
    gap: 0.75rem;

    &__confirm-button,
    &__cancel-button {
      min-width: 100%;
    }

    @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      grid-template-rows: repeat(2, minmax(0, 1fr));
    }
  }
`;
