import React from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import Popup from "../../../shared/components/Popup/Popup";
import StoresDisplay from "../../BoxStoreLocator/elements/StoresDisplay";

interface ISelectStoreFromMapPopupProps {
  closePopup?: () => void | undefined;
  onStoreSelect: (storeCode: string) => void;
}

export const StyledSelectStoreFromMapPopup = styled(Popup)`
  max-width: 1280px;
  width: 100%;
  padding: 30px;
  max-height: 100svh;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-top: 25px;
    max-height: none;
  }
}`;

const SelectStoreFromMapPopup: React.FC<ISelectStoreFromMapPopupProps> = ({
  closePopup,
  onStoreSelect,
}) => {
  return (
    <StyledSelectStoreFromMapPopup closePopup={closePopup}>
      <StoresDisplay onSelectAddressButtonClick={onStoreSelect} cartStatus />
    </StyledSelectStoreFromMapPopup>
  );
};

export default SelectStoreFromMapPopup;
