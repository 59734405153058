import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { IDisplaySettingsSection } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import { TRowWidthOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StickyRowEffectOption } from "@ecp-redux/dto/themeSettings/visualEffects.types";
import { isPortalSide } from "../../../helpers/helpers";
import composeCss from "../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import { useWindowScrollPositions } from "../../../shared/hooks/useWindowScrollPosition";
import {
  OffsetData,
  useStickyOffsetsContext,
} from "../../../structure/Contexts/StickyOffsetsContext";

export const StyledStickySectionEffects = styled.div<{
  $displaySettings: IDisplaySettingsSection;
  $sectionUuid: string;
}>(({ $displaySettings, theme, $sectionUuid }) => {
  const { offsetsData } = useStickyOffsetsContext();
  const { scrollY: scrollPosition } = useWindowScrollPositions();
  const prevOffset = useRef<OffsetData>();
  const [effectState, setEffectState] = useState({
    forwards: false,
    backwards: false,
    effectReset: false,
  });

  const {
    effects: {
      stickyRow: {
        enabled,
        settings: {
          name: effectName,
          backgroundColor,
          duration,
          padding,
          revealOnScrollUp,
          topOffset,
        },
      },
    },
  } = $displaySettings;

  const effectOnScroll = enabled && scrollPosition >= topOffset;
  const currentOffset = offsetsData.find(
    (offset) => offset.sectionUuid === $sectionUuid
  );

  useEffect(() => {
    prevOffset.current = currentOffset;
  }, [currentOffset]);

  useEffect(() => {
    if (effectOnScroll) {
      setEffectState({
        forwards: true,
        backwards: false,
        effectReset: false,
      });
    } else if (
      effectState.forwards &&
      effectName !== StickyRowEffectOption.PADDING &&
      scrollPosition <= topOffset
    ) {
      setEffectState({
        forwards: false,
        backwards: true,
        effectReset: false,
      });
    } else if (
      scrollPosition <=
        (prevOffset.current?.sumHeight ?? 0) -
          (prevOffset.current?.height ?? 0) &&
      effectName !== StickyRowEffectOption.PADDING
    ) {
      setEffectState({
        forwards: false,
        backwards: false,
        effectReset: true,
      });
    }
  }, [effectOnScroll, scrollPosition]);

  if (!isPortalSide()) return "";

  if (
    (enabled && effectName === StickyRowEffectOption.NONE) ||
    (prevOffset.current?.isHeaderSection &&
      theme.stylePages.pages.headerFooter.stickyHeader)
  ) {
    return `
      position: sticky;
      top: ${prevOffset.current?.offset}px;
      z-index: ${prevOffset.current?.zIndex};
    `;
  }

  if (enabled && effectName === StickyRowEffectOption.SLIDE_IN) {
    const offsetTranslate =
      (prevOffset.current?.offset || 0) + (prevOffset.current?.height || 0);
    return `
      @keyframes slideIn-${$sectionUuid} {
        from {
          transform: translateY(${-offsetTranslate}px);
        }
        to {
          transform: translateY(0);
        }
      }

      ${
        effectState.forwards && !effectState.effectReset
          ? `
              animation: slideIn-${$sectionUuid} ${duration}s ease-in-out forwards;
              position: sticky;
            `
          : ``
      }
      ${
        effectState.backwards && revealOnScrollUp && !effectState.effectReset
          ? `
              position: sticky;
            `
          : ""
      }

      top: ${prevOffset.current?.offset}px; 
      z-index: ${prevOffset.current?.zIndex};
    `;
  }

  if (enabled && effectName === StickyRowEffectOption.PADDING) {
    return `
      @keyframes paddingIn-${$sectionUuid} {
        from {
          padding: none;
        }
         to {
          padding: ${composeCss.padding(padding)};
        }
      }

      @keyframes paddingOut-${$sectionUuid} {
        0% {
          padding: ${composeCss.padding(padding)};
        }
        100% {
          padding: none;
        }
      }

      position: sticky;
      top: ${prevOffset.current?.offset}px; 
      z-index: ${prevOffset.current?.zIndex};
    
      section #slots-line {
        ${
          effectState.forwards
            ? `
                animation: paddingIn-${$sectionUuid} ${duration}s forwards;
              `
            : ""
        }
        ${
          effectState.backwards && revealOnScrollUp
            ? `animation: paddingOut-${$sectionUuid} ${duration}s forwards;`
            : ""
        }
      }
    `;
  }

  if (enabled && effectName === StickyRowEffectOption.COLOR) {
    return `
      position: sticky;
      top: ${prevOffset.current?.offset}px; 
      z-index: ${prevOffset.current?.zIndex};

      section::before,
      section #slots-line::before {
        ${
          effectState.forwards
            ? `
            transition: background ${duration}s ease-in-out;
            background: ${convertColorIdToHex(
              backgroundColor,
              theme.colorPalette
            )}`
            : ""
        }

        ${
          effectState.backwards && revealOnScrollUp
            ? `
            transition: background ${duration}s;
              `
            : ""
        }
      }
    `;
  }

  if (
    (enabled && effectName === StickyRowEffectOption.NONE) ||
    (prevOffset.current?.isHeaderSection &&
      theme.stylePages.pages.headerFooter.stickyHeader)
  ) {
    return `
      position: sticky;
      top: ${prevOffset.current?.offset}px;
      z-index: ${prevOffset.current?.zIndex};
    `;
  }

  return `
    position: relative;
    z-index: ${prevOffset.current?.zIndex};
  `;
});

export const StyledFoldedRow = styled.div<{
  $displaySettings: IDisplaySettingsSection;
  isRowFolded: boolean;
}>(({ $displaySettings, isRowFolded, theme }) => {
  const {
    effects: {
      foldedRow: {
        settings: { effectHeight, height, fadeOpacity },
      },
    },
    background: { color },
    width: {
      selectedOption,
      margin: { left: rowMarginLeft, right: rowMarginRight },
      percent,
    },
    popup: { enabled: popupEnabled },
  } = $displaySettings;

  const isMarginPercent =
    selectedOption === TRowWidthOption.PERCENT || popupEnabled;
  const rowMarginPercent = popupEnabled ? 100 : percent;

  const marginDifference = (rowMarginLeft - rowMarginRight) / 2;
  const { maxWidth } = theme.stylePages.pages;
  const gradient = `linear-gradient(
    to top,
    ${convertColorIdToHex(color, theme.colorPalette)} ${fadeOpacity}%,
    transparent 100%
  )`;

  const maxWidthValueExpandBtn = isPortalSide()
    ? `calc(${maxWidth}px * ${rowMarginPercent / 100})`
    : `${rowMarginPercent}%`;

  const maxWidthValueExpandBtnFallback = isPortalSide()
    ? `${maxWidth - rowMarginLeft - rowMarginRight}px`
    : `calc(100% - ${rowMarginLeft + rowMarginRight}px)`;

  return css`
    .folded-row-wrapper {
      position: relative;
      max-height: ${isRowFolded ? `${height}px` : `unset`};
      overflow: hidden;
      max-width: ${maxWidth}px;
      margin-inline: auto;
      &::after {
        content: "";
        position: absolute;
        z-index: 1;
        height: ${effectHeight}px;
        max-height: ${isPortalSide() ? effectHeight : "85%"};
        inset: auto 0 0 0;
        background: ${isRowFolded ? gradient : "none"};
        display: ${isRowFolded ? "block" : "none"};
        ${isMarginPercent
          ? css`
              width: ${rowMarginPercent}%;
              margin-inline: auto;
            `
          : css`
              width: calc(100% - ${rowMarginLeft + rowMarginRight}px);
              transform: translateX(${rowMarginLeft}px);
            `}
      }
    }
    .expand-btn-wrapper {
      background: ${convertColorIdToHex(color, theme.colorPalette)};
      ${isMarginPercent
        ? css`
            max-width: ${maxWidthValueExpandBtn};
          `
        : css`
            transform: translateX(${marginDifference}px);
            max-width: ${maxWidthValueExpandBtnFallback};
          `}
      margin-inline: auto;
      & > * {
        ${isPortalSide() &&
        css`
          cursor: pointer;
        `}
      }
    }
  `;
});
