import { useEffect, useMemo } from "react";
import produce from "immer";
import * as cartAPI from "@ecp-redux/api/cart";
import * as searchResults from "@ecp-redux/api/searchResults";

const useBoxMiniCart = () => {
  const miniCartData = cartAPI.useGetMiniCartByPortalUserTokenQuery();
  const miniCartCount = cartAPI.useGetMiniCartCountByPortalUserTokenQuery();

  const [getProducts, products] = searchResults.useLazyGetPostProductsQuery();

  const productsSkus = useMemo(
    () =>
      miniCartData?.data?.content?.orderLines.map((e) => e.productSku) || [],
    [miniCartData?.data?.content?.orderLines.length]
  );

  useEffect(() => {
    const productsRequestBody = {
      skus: productsSkus,
      projection: ["coverPhoto", "sku"],
    };
    if (productsRequestBody.skus.length !== 0) {
      getProducts(productsRequestBody);
    }
  }, [productsSkus]);

  return {
    miniCartContent: {
      ...miniCartData,
      ...produce(miniCartData, (dataCopy) => {
        dataCopy?.data?.content?.orderLines.forEach((line) => {
          line.image = products.data?.products?.find((e) => {
            return e.sku === line.productSku;
          })?.coverPhoto;
        });
      }),
    },
    miniCartCount: miniCartCount.data?.count ?? 0,
    refetch: () => {
      miniCartData.refetch();
      miniCartCount.refetch();
    },
  };
};

export default useBoxMiniCart;
