import React, { useCallback } from "react";
import Link from "next/link";
import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useObserveElementDimensions } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { TemporaryDescendant } from "../../helpers/htmlHelpers";
import serializeHtmlFromDescendant from "../../helpers/serializeHtmlFromDescendant";
import {
  BOX_RICH_TEXT_CONTENT_PATTERN,
  BOX_RICH_TEXT_PATTERN,
  StyledBoxRichText,
} from "../../settingsPatterns/boxes/BoxRichText/StyledRichText";
import StyledRichText from "../../settingsPatterns/elements/StyledRichText/StyledRichText";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { useLinkContentActions } from "../../shared/hooks/useLinkContentAction";
import { IBoxRichTextProps } from "./BoxRichText.types";

const StyledHrefWrapper = styled.a`
  cursor: pointer;
`;

const BoxRichText: React.FC<IBoxRichTextProps> = (props) => {
  const { getHref, linkContentActions, getTarget, anyActionIsActive } =
    useLinkContentActions(props.path);

  const { settings } = usePrepareSettings({
    props: {
      ...props.contentSettings,
      ...props.displaySettings,
    },
    settingsPattern: BOX_RICH_TEXT_PATTERN(),
  });

  const { settings: content } = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_RICH_TEXT_CONTENT_PATTERN(),
  });
  const {
    imageInline: { imageUrl },
  } = content;

  const [imgWrapper, imageWidth] = useObserveElementDimensions();

  //Here we used Descendant typing but it caused typing errors so temporarly we added TemporaryDescendant type.
  const descendantSlateTitle: TemporaryDescendant[] = JSON.parse(content.title);
  const descendantSlateDescription: TemporaryDescendant[] = JSON.parse(
    content.description
  );

  const changeProceentToFraction = (percent: string): number => {
    const percentNumber = Number(percent.replace("%", ""));
    return percentNumber / 100;
  };

  const getCalculatedWidth = useCallback(
    () =>
      imageWidth &&
      Math.round(
        imageWidth * changeProceentToFraction(settings.imageInline.width)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageWidth, settings.imageInline.width]
  );

  return (
    <StyledBoxRichText
      $settings={settings}
      $content={content}
      ref={imgWrapper}
      $imageWidth={getCalculatedWidth()}
      className="rich-text-container rich-text-custom-style"
    >
      {settings.imageInline.show && imageUrl && imageWidth && (
        <div className="rich-text-container__image-container">
          <ConditionalWrapper
            condition={anyActionIsActive(content.richTextUrl)}
            wrapper={(children) => (
              <ConditionalWrapper
                condition={getHref(content.richTextUrl) !== undefined}
                wrapper={(children1) => (
                  <Link href={getHref(content.richTextUrl) ?? ""} passHref>
                    {children1}
                  </Link>
                )}
              >
                <StyledHrefWrapper
                  target={getTarget(content.richTextUrl)}
                  onClick={() => linkContentActions(content.richTextUrl)}
                >
                  {children}
                </StyledHrefWrapper>
              </ConditionalWrapper>
            )}
          >
            <ImageWrapper
              imageUrl={imageUrl}
              seoDescription={content.seoDescription}
              imageFit={true}
              imageAlignment={{
                horizontal: AlignmentHorizontalOption.CENTER,
                vertical: AlignmentVerticalOption.CENTER,
              }}
              opacity={settings.imageInline.opacity}
              width={getCalculatedWidth()}
            />
          </ConditionalWrapper>
        </div>
      )}
      <div className="rich-text-container__content-container">
        <StyledRichText
          $settings={settings.title}
          className="rich-text-container__title"
        >
          {serializeHtmlFromDescendant(descendantSlateTitle)}
        </StyledRichText>
        <StyledRichText
          $settings={settings.description}
          className="rich-text-container__description"
        >
          {serializeHtmlFromDescendant(descendantSlateDescription)}
        </StyledRichText>
      </div>
    </StyledBoxRichText>
  );
};

export default BoxRichText;
