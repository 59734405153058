import {
  DeliveryTimeCode,
  ISrpTransformResponseV2,
} from "../dto/searchResults.types";
import { getLineAttributesDetectedV2 } from "../helpers";

export const getActualPrice = (currentProduct: ISrpTransformResponseV2) => {
  const { basePrice, promotionPrice } = currentProduct;
  return !promotionPrice || basePrice <= promotionPrice
    ? basePrice
    : promotionPrice;
};

export const getOldPrice = (currentProduct: ISrpTransformResponseV2) => {
  const { basePrice, promotionPrice } = currentProduct;
  return promotionPrice && promotionPrice < basePrice ? basePrice : null;
};

export const transformResponseSrp = (result: ISrpTransformResponseV2[]) => {
  return {
    products: result.map<ISrpTransformResponseV2>(
      (product) =>
        ({
          ...product,
          deliveryTime: product.deliveryTime ?? DeliveryTimeCode.NOT_AVAILABLE,
        } as ISrpTransformResponseV2)
    ),
    size: result.length,
    attributesLineDetection: getLineAttributesDetectedV2(result),
  };
};

const projectionMap: Record<string, string> = {
  Net_price: "netPrice",
};

export const srpProjectionMapper = (projection: string[]) => {
  return projection.map((attribute) => {
    return projectionMap[attribute] ?? attribute;
  });
};
