import { isEmpty } from "lodash";
import { useTheme } from "styled-components";
import { TAttributeValue } from "@ecp-redux/dto/searchResults.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import LinkWrapper from "../../../../../global/components/LinkWrapper/LinkWrapper";
import { formatPercentage, formatPrice } from "../../../../../helpers/helpers";
import {
  convertButtonIdToValues,
  convertTypoIdToValues,
} from "../../../../../settingsPatterns/settingsPatterns.methods";
import StyledText from "../../../../styleElements/StyledText/StyledText";
import ActionButton from "./ActionButton/ActionButton";
import PrefixSuffixContainer from "./PrefixSuffixContainer";
import { StyledSpaceKeeper } from "./ProductModule.styled";
import { IProductAttributesDisplayProps } from "./ProductModule.types";
import ProductRating from "./ProductRating/ProductRating";
import SpaceReservation from "./SpaceReservation";

const ProductAttributesDisplay: React.FC<IProductAttributesDisplayProps> = ({
  product,
  productSettings,
  attribute,
  boxId,
  safeIndex,
  buttonText,
  ratingText,
  variantSettings,
  attributesLineDetection,
}) => {
  const {
    advanceSettings: { messages: globalMessages },
    typography,
    globalObjects: { buttons },
  } = useTheme() as IThemeState;

  const deliveryTimeMessage =
    globalMessages[`delivery_${product.deliveryTime}`] ??
    globalMessages[`delivery_unknown`];

  const isAttributeLineDetected = (attributeName: string) =>
    attributesLineDetection?.[attributeName] ?? false;

  switch (attribute) {
    case "sku":
      return (
        <StyledText
          className="product-module__content-container__sku"
          $settings={productSettings.custom?.[attribute] ?? productSettings.sku} //For BoxProductSlider sku is in custom
          key={product.sku + "sku" + boxId + safeIndex}
        >
          <PrefixSuffixContainer
            productSettings={productSettings.sku}
            child={
              <StyledSpaceKeeper
                className="product-module__content-container__sku"
                rows={1}
                height={productSettings.sku?.font.custom.size}
                key={product.sku + "sku" + boxId + safeIndex}
              >
                {product.conceptId ? product.conceptId : product.sku}
              </StyledSpaceKeeper>
            }
          />
        </StyledText>
      );

    case "productName": {
      const calculatedHeight = convertTypoIdToValues(
        productSettings.productName?.text ?? "typo$$1",
        typography
      ).lineHeight;

      return productSettings.productName?.show && product.name ? (
        <LinkWrapper
          href={product.url ?? ""}
          show={productSettings.productName?.show}
          linkStyle={productSettings.productName?.link}
          fontStyle={productSettings.productName?.text}
        >
          <PrefixSuffixContainer
            productSettings={productSettings.productName}
            child={
              <StyledSpaceKeeper
                className="product-module__content-container__product-name"
                rows={2}
                height={calculatedHeight}
                key={product.sku + "productName" + boxId + safeIndex}
                style={{ whiteSpace: "normal" }}
              >
                {product.name}
              </StyledSpaceKeeper>
            }
          />
        </LinkWrapper>
      ) : (
        <StyledSpaceKeeper
          className="product-module__content-container__product-name"
          rows={2}
          key={product.sku + "productName" + boxId + safeIndex}
        >
          <StyledText
            $settings={{
              font: productSettings.productName?.text,
              text: productSettings.productName?.link,
              show: productSettings.productName?.show,
            }}
          >
            {"messages.unknown_product_text"}
          </StyledText>
        </StyledSpaceKeeper>
      );
    }
    case "actualPrice":
      return productSettings.actualPrice?.show ? (
        <PrefixSuffixContainer
          productSettings={productSettings.actualPrice}
          child={
            <StyledSpaceKeeper
              className="product-module__content-container__actual-price"
              height={
                convertTypoIdToValues(productSettings.actualPrice?.font).size
              }
              rows={1}
              key={product.sku + "actualPrice" + boxId + safeIndex}
            >
              <StyledText
                className="product-module__content-container__actual-price"
                $settings={productSettings.actualPrice}
                key={product.sku + "actualPrice" + boxId + safeIndex}
              >
                {formatPrice(
                  product.promotionPrice &&
                    product.promotionPrice < product.basePrice
                    ? product.promotionPrice
                    : product.basePrice,
                  globalMessages["currencyShort"]
                )}
              </StyledText>
            </StyledSpaceKeeper>
          }
        />
      ) : (
        <SpaceReservation
          reservation={
            isAttributeLineDetected("basePrice") &&
            productSettings.actualPrice?.show === true
          }
          component={{
            typo: convertTypoIdToValues(
              productSettings.actualPrice?.font,
              typography
            ),
          }}
        />
      );

    case "oldPrice":
      return productSettings.oldPrice?.show &&
        product.promotionPrice &&
        product.basePrice > product.promotionPrice ? (
        <PrefixSuffixContainer
          productSettings={productSettings.oldPrice}
          child={
            <StyledSpaceKeeper
              className="product-module__content-container__old-price"
              height={productSettings.oldPrice?.font.custom.size}
              rows={1}
              key={product.sku + "oldPrice" + boxId + safeIndex}
            >
              <StyledText
                $settings={productSettings.oldPrice}
                className="product-module__content-container__old-price__line-through"
              >
                {formatPrice(
                  product.basePrice,
                  globalMessages["currencyShort"]
                )}
              </StyledText>
            </StyledSpaceKeeper>
          }
        />
      ) : (
        <SpaceReservation
          reservation={
            isAttributeLineDetected("promotionPrice") &&
            productSettings.oldPrice?.show === true
          }
          component={{
            typo: convertTypoIdToValues(
              productSettings.oldPrice?.font,
              typography
            ),
          }}
        />
      );

    case "netPrice":
      return productSettings.netPrice?.show && product.netPrice ? (
        <PrefixSuffixContainer
          productSettings={productSettings.netPrice}
          child={
            <StyledSpaceKeeper
              className="product-module__content-container__net-price"
              height={productSettings.netPrice?.font.custom.size}
              rows={1}
              key={product.sku + "netPrice" + boxId + safeIndex}
            >
              <StyledText
                $settings={productSettings.netPrice}
                className="product-module__content-container__net-price"
              >
                {formatPrice(product.netPrice, globalMessages["currencyShort"])}
              </StyledText>
            </StyledSpaceKeeper>
          }
        />
      ) : (
        <SpaceReservation
          reservation={
            isAttributeLineDetected("netPrice") &&
            productSettings.netPrice?.show === true
          }
          component={{
            typo: convertTypoIdToValues(
              productSettings.netPrice?.font,
              typography
            ),
          }}
        />
      );

    case "grossPrice":
      return productSettings.custom?.[attribute]?.show && product.grossPrice ? (
        <PrefixSuffixContainer
          productSettings={productSettings.custom[attribute]}
          child={
            <StyledSpaceKeeper
              className="product-module__content-container__gross-price"
              height={productSettings.custom[attribute]?.font.custom.size}
              rows={1}
              key={product.sku + attribute + boxId + safeIndex}
            >
              <StyledText
                $settings={productSettings.custom[attribute]}
                className="product-module__content-container__gross-price"
              >
                {formatPrice(
                  product.grossPrice,
                  globalMessages["currencyShort"]
                )}
              </StyledText>
            </StyledSpaceKeeper>
          }
        />
      ) : (
        <SpaceReservation
          reservation={
            isAttributeLineDetected("grossPrice") &&
            productSettings.custom?.[attribute]?.show === true
          }
          component={{
            typo: convertTypoIdToValues(
              productSettings.custom?.["grossPrice"]?.font,
              typography
            ),
          }}
        />
      );

    case "omnibusPrice":
      return productSettings.custom?.[attribute]?.show &&
        product.omnibusPrice &&
        product.promotionPrice &&
        product.promotionPrice < product.basePrice ? (
        <PrefixSuffixContainer
          productSettings={productSettings.custom[attribute]}
          child={
            <StyledSpaceKeeper
              className="product-module__content-container__omnibus-price"
              height={productSettings.custom[attribute]?.font.custom.size}
              rows={1}
              key={product.sku + attribute + boxId + safeIndex}
            >
              <StyledText
                $settings={productSettings.custom[attribute]}
                className="product-module__content-container__omnibus-price"
              >
                {formatPrice(
                  product.omnibusPrice,
                  globalMessages["currencyShort"]
                )}
              </StyledText>
            </StyledSpaceKeeper>
          }
        />
      ) : (
        <SpaceReservation
          reservation={
            isAttributeLineDetected("omnibusPrice") &&
            productSettings.custom?.[attribute]?.show === true
          }
          component={{
            typo: convertTypoIdToValues(
              productSettings.custom?.["omnibusPrice"]?.font,
              typography
            ),
          }}
        />
      );

    case "rating":
      return productSettings.rating?.show ? (
        <PrefixSuffixContainer
          productSettings={productSettings.rating}
          child={
            <ProductRating
              labelName={ratingText}
              ratingCount={product.rating?.numberOfReviews}
              ratingScore={product.rating?.rate || 0}
              ratingSettings={productSettings.rating}
              key={product.sku + "rating" + boxId + safeIndex}
            />
          }
        />
      ) : (
        <SpaceReservation
          reservation={
            isAttributeLineDetected("rating") &&
            productSettings.rating?.show === true
          }
          component={{
            typo: convertTypoIdToValues(
              productSettings.rating?.font,
              typography
            ),
          }}
        />
      );

    case "delivery": // delivery as base element of ProductModule
      return (
        <PrefixSuffixContainer
          productSettings={productSettings.delivery}
          child={
            <StyledSpaceKeeper
              className="product-module__content-container__delivery"
              height={productSettings.delivery?.font.custom.size}
              rows={1}
              key={product.sku + "delivery" + boxId + safeIndex}
            >
              <StyledText
                className="product-module__content-container__delivery"
                $settings={productSettings.delivery}
                key={product.sku + "delivery" + boxId + safeIndex}
              >
                {deliveryTimeMessage}
              </StyledText>
            </StyledSpaceKeeper>
          }
        />
      );

    case "deliveryTime": // delivery as attribute
      return (
        <PrefixSuffixContainer
          productSettings={productSettings.custom?.["deliveryTime"]}
          child={
            <StyledText
              className="product-module__content-container__delivery"
              $settings={productSettings.custom?.["deliveryTime"]}
              key={product.sku + "deliveryTime" + boxId + safeIndex}
            >
              {deliveryTimeMessage}
            </StyledText>
          }
        />
      );

    case "button": {
      return !product?.variants?.length && !variantSettings ? (
        <ActionButton
          itemId={product.sku}
          buttonText={buttonText}
          buttonStyle={productSettings.button ?? "button$$1"}
          buttonSettings={productSettings.button}
          productUrl={product.url}
          key={product.sku + "button" + boxId}
        />
      ) : (
        <SpaceReservation
          reservation={productSettings.button?.show === true}
          component={{
            button: convertButtonIdToValues(productSettings.button, buttons),
          }}
        />
      );
    }

    case "TAX":
      return (product["TAX"] as TAttributeValue)?.value ? (
        <PrefixSuffixContainer
          productSettings={productSettings.custom?.[attribute]}
          child={
            <StyledText
              className="product-module__content-container__custom"
              $settings={productSettings.custom?.[attribute]}
              key={product.sku + "TAX" + attribute + boxId + safeIndex}
            >
              {formatPercentage(
                parseFloat((product["TAX"] as TAttributeValue)?.value),
                globalMessages["taxShort"]
              )}
            </StyledText>
          }
        />
      ) : (
        <SpaceReservation
          reservation={isAttributeLineDetected("TAX")}
          component={{
            typo: convertTypoIdToValues(
              productSettings.custom?.["TAX"].font,
              typography
            ),
          }}
        />
      );

    case "badges":
      return product?.badges && product.badges.length > 0 ? (
        <PrefixSuffixContainer
          productSettings={productSettings.custom?.["badges"]}
          child={
            <StyledText
              className="product-module__content-container__badges"
              $settings={productSettings.custom?.["badges"]}
              key={product.sku + "badges" + boxId + safeIndex}
            >
              {product.badges.map((badge) => badge.name).join(", ")}
            </StyledText>
          }
        />
      ) : (
        <SpaceReservation
          reservation={isAttributeLineDetected("badges")}
          component={{
            typo: convertTypoIdToValues(
              productSettings.custom?.["badges"].font,
              typography
            ),
          }}
        />
      );

    case "pools":
      return product.pools && product.pools?.length > 0 ? (
        <PrefixSuffixContainer
          productSettings={productSettings.custom?.["pools"]}
          child={
            <StyledText
              className="product-module__content-container__pools"
              $settings={productSettings.custom?.["pools"]}
              key={product.sku + "pools" + boxId + safeIndex}
            >
              {product.pools.map((pool) => pool.name).join(", ")}
            </StyledText>
          }
        />
      ) : (
        <SpaceReservation
          reservation={isAttributeLineDetected("pools")}
          component={{
            typo: convertTypoIdToValues(
              productSettings.custom?.["pools"].font,
              typography
            ),
          }}
        />
      );

    default: {
      if (isEmpty(productSettings.custom)) return null;

      const productAttributes = product?.[attribute];

      if (
        productSettings.custom?.[attribute] !== undefined &&
        productAttributes !== undefined
      ) {
        return (
          <PrefixSuffixContainer
            productSettings={productSettings.custom[attribute]}
            child={
              <StyledSpaceKeeper
                className="product-module__content-container__custom"
                rows={1}
                key={product.sku + "custom" + boxId + safeIndex}
              >
                <StyledText
                  className="product-module__content-container__custom"
                  $settings={productSettings.custom[attribute]}
                  key={product.sku + "custom" + attribute + boxId + safeIndex}
                >
                  {Array.isArray(productAttributes)
                    ? productAttributes
                        .map((el) => el.label ?? el.value)
                        .join(", ")
                    : productAttributes.label ?? productAttributes.value}
                </StyledText>
              </StyledSpaceKeeper>
            }
          />
        );
      } else {
        return (
          <SpaceReservation
            reservation={isAttributeLineDetected(attribute)}
            component={{
              typo: convertTypoIdToValues(
                productSettings.custom?.[attribute]?.font,
                typography
              ),
            }}
          />
        );
      }
    }
  }
};

export default ProductAttributesDisplay;
