import React from "react";
import useTranslation from "next-translate/useTranslation";
import { isPortalSide } from "../../helpers/helpers";
import AttributeList from "./AttributeList/AttributeList";
import AttributeTable from "./AttributeTable/AttributeTable";
import { isEveryAttributeIsEmpty } from "./BoxProductAttributes.methods";
import {
  StyledAttributeListWrapper,
  StyledBoxProductAttributesWrapper,
} from "./BoxProductAttributes.styled";
import {
  AttributeDisplayType,
  BoxProductAttributesProps,
} from "./BoxProductAttributes.types";
import useBoxProductAttribute from "./hooks/useBoxProductAttributes";

const BoxProductAttributes: React.FC<BoxProductAttributesProps> = (props) => {
  const { t } = useTranslation();
  const {
    contentSettings,
    displaySettings,
    productExtendedAttributes,
    searchRedirectUrl,
  } = useBoxProductAttribute(props);

  if (
    productExtendedAttributes === undefined ||
    productExtendedAttributes.length === 0 ||
    isEveryAttributeIsEmpty(productExtendedAttributes)
  )
    return (
      <div className="product-attributes--empty">
        {!isPortalSide() && t("global_keys.empty_box")}
      </div>
    );

  return (
    <StyledBoxProductAttributesWrapper
      spacing={displaySettings.padding}
      className="product-attributes-container product-attributes-custom-style"
    >
      {displaySettings.displayType === AttributeDisplayType.LIST ? (
        <StyledAttributeListWrapper
          gap={displaySettings.tableSettings.spaceBetweenRows}
          className="product-attributes-container__list"
        >
          {productExtendedAttributes.map((attribute, id) => (
            <AttributeList
              key={id}
              contentSettings={contentSettings}
              displaySettings={displaySettings}
              attribute={attribute}
              searchRedirectUrl={searchRedirectUrl}
            />
          ))}
        </StyledAttributeListWrapper>
      ) : (
        <AttributeTable
          contentSettings={contentSettings}
          displaySettings={displaySettings}
          productExtendedAttributes={productExtendedAttributes}
          searchRedirectUrl={searchRedirectUrl}
        />
      )}
    </StyledBoxProductAttributesWrapper>
  );
};

export default BoxProductAttributes;
