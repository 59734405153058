import styled from "styled-components";
import {
  TColorId,
  TSizeCSS,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";

export const StyledBoxCartStepOne = styled.div(() => {
  return `
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      width: 100%;

      @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
      }

      .cart_products{
        grid-column: span 2 / span 2;
        
        @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
          padding-top: 20px;
        }
       
      }
    `;
});

export const StyledProductsCartList = styled.div<{
  backgroundColor: TColorId;
}>(({ backgroundColor, theme }) => {
  const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
  return `
      background-color: ${bg};
      width: 100%;
      padding: 32px;

      .crossed {
        opacity: 0.5;
      }

      .products-cart-list {
        &__header-container {
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;
        }

        &__items-count {
          align-self: center;
        }

        &__description {
          margin-bottom: 19px;
          display: flex;
          justify-content: space-between;
        }
        
        &__alerts-container {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
        }

        &__product-cart-element-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-top: 1rem;
        }
      }
    `;
});

export const StyledCrossedArea = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  width: 100%;
  height: 100%;
`;

export const StyledProductsCartElement = styled.div<{
  unvailableProduct: boolean;
  imageSize: TSizeCSS;
}>(({ unvailableProduct, imageSize }) => {
  return `
        position: relative;
        display: grid;
        grid-template-columns: ${imageSize} 1fr;
        gap: 4px;

        .product-cart-element {
          &__image{
              width: 100%;
              grid-column-start: 1;
              grid-row: span 2 / span 2;
              grid-row-start: 1;
              ${unvailableProduct && "opacity: 0.2;"};
              position:  relative;
              height: fit-content;
          }

          &__name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            grid-column: span 11;
          }

          &__remove-button {
            justify-self: end;

            :hover {
              opacity: 0.2;
            }
          }

          &__suggested-retail-price {
            text-decoration: line-through;
            margin-top: 5px;
          }

          &__delivery-time {
            margin: 28px 0;
          }

          &__total-price {
            flex-grow: 1;
            text-align: right;
            align-self: center;
          }

          &__wishlist-icon {
            margin-left: 28px;
          }

          &__icons {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }

        .product_cart_info {
            grid-column-start: 2;
            grid-row-start: 1;

            &__text {
              display: grid;
              grid-template-columns: repeat(12, 1fr);
              gap: 8px;
            }
        }

        .product_cart_quantity{
            grid-column: span 2 / span 2;
            @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
                grid-row-start: 2;
            }
            grid-row-start: 3;
            display: flex;
            gap: 16px;
            align-self: center;
        }
    `;
});

export const StyledSummary = styled.div<{
  backgroundColor: TColorId;
  buttonWidth: string;
}>(({ backgroundColor, theme, buttonWidth }) => {
  const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
  return `
      background-color: ${bg};
      width: 100%;
      ${buttonWidth ? `${StyledButton} { width: ${buttonWidth}; }` : ""}

      .summary {
        &_content{
          padding: 40px 16px 16px;
          position: sticky;
          top: 0;
        }

        &__header {
          margin-bottom: 1.25rem;
        }

        &__products-price {
          margin: 19px 0;
          display: flex;
          justify-content: space-between;
        }

        &__save-amount {
          margin-bottom: 19px;
          display: flex;
          justify-content: space-between;
        }

        &__delivery-cost {
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
        }

        &__amount-to-pay {
          display: flex;
          justify-content: space-between;
          margin: 14px 0;
          align-items: center;
        }
      }
    `;
});
