import { useMemo } from "react";
import { PageCategoryType } from "@ecp-pageTypes";
import * as marketingBanners from "@ecp-redux/api/marketingBanners";
import { IBannerElement } from "@ecp-redux/dto/marketingBanners.types";
import { ISrpTransformResponseV2 } from "@ecp-redux/dto/searchResults.types";
import {
  BannersPositions,
  CountOfTemplatesInShake,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  ProductCategoryContext,
  categoryCodeForSearch,
  useCheckPageContext,
  useSearchQueries,
} from "../../../helpers/pageContext";
import { IMarketingCampaign } from "../BoxSearchResults.types";

const mixElementsInBannersArray = (
  array: IBannerElement[]
): IBannerElement[] => {
  let copiedArray = array.slice();

  const randomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  for (let i = copiedArray.length - 1; i > 0; i--) {
    const j = randomNumber(0, i);
    [copiedArray[i], copiedArray[j]] = [copiedArray[j], copiedArray[i]];
  }

  return copiedArray;
};

const ShakePositionTemplatesThree = [
  [3, 10, 21],
  [9, 16, 27],
  [3, 11, 19],
  [7, 18, 25],
  [6, 12, 18],
];

const ShakePositionTemplatesTwo = [
  [1, 21],
  [4, 15],
  [5, 17],
  [6, 22],
  [2, 20],
];
const ShakePositionTemplatesOne = [[1], [27], [5], [14], [2]];

const selectedVariantOfShakeTemplates = (variant: CountOfTemplatesInShake) => {
  switch (variant) {
    case CountOfTemplatesInShake.ONE:
      return ShakePositionTemplatesOne;

    case CountOfTemplatesInShake.TWO:
      return ShakePositionTemplatesTwo;

    case CountOfTemplatesInShake.THREE:
      return ShakePositionTemplatesThree;

    default:
      return ShakePositionTemplatesThree;
  }
};

const selectRandomTemplate = (variant: CountOfTemplatesInShake) => {
  const templatesVariant = selectedVariantOfShakeTemplates(variant);
  const randomIndex = Math.floor(Math.random() * templatesVariant.length);

  return templatesVariant[randomIndex];
};

const applyShakePositions = (
  products: ISrpTransformResponseV2[],
  positions: number[],
  banners: { idx: number; banner: IBannerElement }[]
) => {
  const result: (IBannerElement | ISrpTransformResponseV2)[] = [];

  products.forEach((product, index) => {
    if (positions.includes(index + 1)) {
      const banner = banners.find((b) => b.idx === index + 1);
      if (banner && banner.banner.imageUrl) {
        result.push(banner.banner);
      }
    }
    result.push(product);
  });

  return result;
};

export const useMarketingBannersLogic = (
  initialTake: number,
  marketingCampaign?: IMarketingCampaign
) => {
  const { skip, take } = useSearchQueries({
    initialPageIndex: 1,
    initialPageSize: initialTake,
  });
  const context = useCheckPageContext<ProductCategoryContext>(
    PageCategoryType.PRODUCT_CATEGORY
  );

  const { data: fetchedMarketingBanners } =
    marketingBanners.useGetMarketingBannersQuery(
      context.type === PageCategoryType.PRODUCT_CATEGORY
        ? context.productCategoryCode
        : categoryCodeForSearch
    );

  const bannersPositions = useMemo(() => {
    if (!marketingCampaign) return [];
    const currentTemplate = selectRandomTemplate(
      marketingCampaign.countOfTemplatesInShake
    ).map((el) => el + skip);
    return marketingCampaign.positionOption === BannersPositions.SHAKE
      ? currentTemplate
      : marketingCampaign.fixPositionOrder;
  }, [
    marketingCampaign?.positionOption,
    marketingCampaign?.fixPositionOrder,
    skip,
    take,
  ]);

  const bannersToDisplay = useMemo(() => {
    if (fetchedMarketingBanners && marketingCampaign) {
      return marketingCampaign.orderOption === BannersPositions.SHAKE
        ? mixElementsInBannersArray(fetchedMarketingBanners)
        : fetchedMarketingBanners;
    } else {
      return [];
    }
  }, [marketingCampaign?.orderOption, fetchedMarketingBanners]);

  if (
    marketingCampaign === undefined ||
    !marketingCampaign.turnOn ||
    bannersToDisplay.length === 0
  ) {
    return {
      getProductsAndBanners: (products: ISrpTransformResponseV2[]) => products,
      bannersPositions: [],
    };
  }

  const filteredBannersToDisplay = bannersPositions
    .map((el, idx) => {
      const index =
        marketingCampaign.positionOption === BannersPositions.FIX
          ? idx
          : (skip / take) * bannersPositions.length + idx;
      return {
        idx: el,
        banner: bannersToDisplay[index % bannersToDisplay.length],
      };
    })
    .filter(({ idx }) => idx < skip + take && idx >= skip);

  const filteredBannersPositions = bannersPositions
    .filter((e) => e < skip + take && e >= skip)
    .map((e) => (skip > 0 ? (e % skip) + 1 : e));

  const finalResult = (products: ISrpTransformResponseV2[]) =>
    applyShakePositions(
      products,
      filteredBannersPositions,
      filteredBannersToDisplay
    );

  return {
    getProductsAndBanners: finalResult,
    bannersPositions,
  };
};
