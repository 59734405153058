import React from "react";
import { useRouter } from "next/router";
import { StyledAuthFormWrapper } from "../../shared/components/StyledAuthFormWrapper/StyledAuthFormWrapper.styled";
import { TBoxRemindProps } from "./BoxRemind.types";
import RemindChangePassword from "./RemindChangePassword/RemindChangePassword";
import RemindSendEmail from "./RemindSendEmail/RemindSendEmail";

const BoxRemind: React.FC<TBoxRemindProps> = ({ settings }) => {
  const router = useRouter();

  const changePasswordToken = router?.query?.["token"];

  return (
    <StyledAuthFormWrapper
      wrapperMargin={settings.remind_password_form_wrapper_margin}
      buttonWidth={settings.remind_password_form_submit_button_width}
      inputStyleId={settings.remind_password_form_input_style}
      formElementsGap={settings.remind_password_form_elements_gap}
      titleSpacing={settings.remind_password_form_title_spacing}
      backgroundColorId={settings.remind_password_form_wrapper_background_color}
      className="remind-password-custom-style"
    >
      {typeof changePasswordToken === "string" ? (
        <RemindChangePassword changePasswordToken={changePasswordToken} />
      ) : (
        <RemindSendEmail />
      )}
    </StyledAuthFormWrapper>
  );
};

export default BoxRemind;
