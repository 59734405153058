import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../../global/global";
import { isNotEmptyMessage } from "../../../../helpers/isNotEmptyMessage";
import Popup from "../../../../shared/components/Popup/Popup";
import StyledButton from "../../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { IBoxCartB2BMessages, IBoxCartB2BSettings } from "../BoxCartB2B.types";

const StyledPopupWrapper = styled.div`
  margin: 30px;

  .delete-type-popup {
    &__button-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.75rem;
      align-items: center;
    }

    &__description {
      margin: 30px 0;
    }

    &__button-container__save-button {
      min-width: 100%;
    }

    &__title,
    &__description {
      text-align: center;
    }

    &__button-container__cancel-button {
      min-width: 100%;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      &__button-container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
`;

const ClearProductTypeB2BPopup = ({
  confirmPopup,
  closePopup,
}: {
  closePopup: () => void;
  confirmPopup: () => void;
}) => {
  const {
    messages: {
      order_deleting_popup_title,
      order_deleting_popup_description,
      order_deleting_popup_cancel_button_message,
      order_deleting_popup_success_button_message,
    },
    settings: {
      order_deleting_popup_title_typo,
      order_deleting_popup_title_color,
      order_deleting_popup_description_typo,
      order_deleting_popup_description_color,
      order_deleting_popup_cancel_button_style,
      order_deleting_popup_approve_button_style,
    },
  } = useMessagesSettingsContext<IBoxCartB2BMessages, IBoxCartB2BSettings>();

  return (
    <Popup
      desktopWidth={"700px"}
      className="delete-type-popup"
      closePopup={closePopup}
    >
      <StyledPopupWrapper>
        <StyledText
          className="delete-type-popup__title"
          $settings={{
            font: order_deleting_popup_title_typo,
            text: { color: order_deleting_popup_title_color },
          }}
          show={isNotEmptyMessage(order_deleting_popup_title)}
        >
          {order_deleting_popup_title}
        </StyledText>

        <StyledText
          className="delete-type-popup__description"
          $settings={{
            font: order_deleting_popup_description_typo,
            text: {
              color: order_deleting_popup_description_color,
            },
          }}
          show={isNotEmptyMessage(order_deleting_popup_description)}
        >
          {order_deleting_popup_description}
        </StyledText>
        <div className="delete-type-popup__button-container">
          <StyledButton
            className="delete-type-popup__button-container__cancel-button"
            $settings={order_deleting_popup_cancel_button_style}
            renderAs={"button"}
            data-testid="noPopupBtn"
            onClick={() => closePopup()}
            show={isNotEmptyMessage(order_deleting_popup_cancel_button_message)}
          >
            {order_deleting_popup_cancel_button_message}
          </StyledButton>
          <StyledButton
            className="delete-type-popup__button-container__save-button"
            $settings={order_deleting_popup_approve_button_style}
            renderAs="button"
            data-testid="yesPopupBtn"
            onClick={() => {
              confirmPopup();
            }}
            show={isNotEmptyMessage(
              order_deleting_popup_success_button_message
            )}
          >
            {order_deleting_popup_success_button_message}
          </StyledButton>
        </div>
      </StyledPopupWrapper>
    </Popup>
  );
};

export default ClearProductTypeB2BPopup;
