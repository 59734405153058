import React, { useState } from "react";
import * as myAddressList from "@ecp-redux/api/myAddressList";
import {
  MyAddressType,
  TMyAddresses,
  isMyPostalBillingAddress,
} from "@ecp-redux/dto/myAddresses.type";
import { ReactComponent as PenIcon } from "../../../../../../../libs/boxes/src/lib/shared/icons/pen.svg";
import { ReactComponent as TrashIcon } from "../../../../../../../libs/boxes/src/lib/shared/icons/trash.svg";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { Radio } from "../../../shared/components/Radio/Radio";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountMyAddressListMessages,
  IBoxMyAccountMyAddressListSettings,
} from "../BoxMyAccountMyAddressList.types";
import BoxMyAccountMyAddressListForm from "../BoxMyAccountMyAddressListForm";
import { StyledChangeAddress } from "../StyledBoxMyAccountMyAddressList.styled";
import ConfirmDeleteAddressPopup from "./ConfirmDeleteAddressPopup";
import MyAddressInfo from "./MyAddressInfo";
import SelectStoreFromMapPopup from "./SelectStoreFromMapPopup";

interface ChangeAddressProps {
  selectedAddress: TMyAddresses | null;
  addresses: TMyAddresses[];
  closeEditAddress: () => void;
  addressType: MyAddressType;
  disableAdressEdit?: boolean;
}

const ChangeAddress: React.FC<ChangeAddressProps> = ({
  selectedAddress,
  addresses,
  addressType,
  closeEditAddress,
  disableAdressEdit,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyAddressListMessages,
    IBoxMyAccountMyAddressListSettings
  >();
  const [addStoreAddress] = myAddressList.usePostAddNewAddressMutation();

  const [showAddressForm, setShowAddressForm] = useState<
    "NEW" | "NONE" | "EDIT"
  >("NONE");

  const [deleteAddressById, setDeleteAddressById] = useState<
    number | undefined
  >(undefined);

  const [editAddress, setEditAddres] = useState<null | TMyAddresses>(null);

  const [changeSelectedAddress] =
    myAddressList.usePutChangeDefaultAddressMutation();

  const closeForm = () => {
    setShowAddressForm("NONE");
  };

  const selectStoreAddress = async (storeCode: string) => {
    await addStoreAddress({
      storeCode,
      type: MyAddressType.STORE,
    });
    closeForm();
  };

  return (
    <div
      data-testid="ChangeBillingPostalAddressComponent"
      className="change-my-address-container"
    >
      {((showAddressForm === "NEW" && addressType === MyAddressType.STORE) ||
        showAddressForm === "NONE") && (
        <StyledChangeAddress>
          {addresses.map((address) => (
            <div className="change-my-address-container__main" key={address.id}>
              <div className="change-my-address-container__main__radio-button">
                <Radio
                  checked={address.id === selectedAddress?.id}
                  onClick={() =>
                    changeSelectedAddress({ addressId: address.id }).then(
                      closeEditAddress
                    )
                  }
                />
                <MyAddressInfo {...{ address }} />
              </div>

              <div className="change-my-address-container__button-container">
                {!disableAdressEdit && (
                  <button
                    className="change-my-address-container__button-container__edit-address-button"
                    onClick={() => {
                      setShowAddressForm("EDIT");
                      setEditAddres(address);
                    }}
                    data-testid="editDeliveryAddress"
                  >
                    <PenIcon className="change-my-address-container__button-container__edit-address-button__icon" />
                  </button>
                )}
                <button
                  className="change-my-address-container__button-container__delete-address-button"
                  onClick={() => {
                    setDeleteAddressById(address.id);
                  }}
                  data-testid="deleteDeliveryAddress"
                >
                  <TrashIcon className="change-my-address-container__button-container__delete-address-button__icon" />
                </button>
              </div>
            </div>
          ))}

          <StyledButton
            className="change-my-address-container__add-address-button"
            $settings={settings.myaccount_address_list_add_address_button}
            renderAs="button"
            onClick={() => setShowAddressForm("NEW")}
            data-testid="addNewAddressButton"
            show={isNotEmptyMessage(
              messages.myaccount_address_list_button_add__address
            )}
          >
            {messages.myaccount_address_list_button_add__address}
          </StyledButton>
        </StyledChangeAddress>
      )}
      {showAddressForm !== "NONE" && (
        <div>
          {(addressType === MyAddressType.BILLING ||
            addressType === MyAddressType.POSTAL) && (
            <BoxMyAccountMyAddressListForm
              disableForm={closeForm}
              addressType={addressType}
              editData={
                showAddressForm === "EDIT" &&
                isMyPostalBillingAddress(editAddress)
                  ? editAddress
                  : null
              }
            />
          )}
          {addressType === MyAddressType.STORE && (
            <SelectStoreFromMapPopup
              closePopup={closeForm}
              onStoreSelect={selectStoreAddress}
            />
          )}
        </div>
      )}

      {deleteAddressById && (
        <ConfirmDeleteAddressPopup
          closePopup={() => setDeleteAddressById(undefined)}
          addressId={deleteAddressById}
        />
      )}
    </div>
  );
};

export default ChangeAddress;
