import cleanDeep from "clean-deep";
import { SectionType } from "@ecp-redux/dto/page/portal.types";
import { DisplaySettingsSectionScheme } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import { useRowPopupContext } from "../Contexts/RowPopupContext";
import Section from "../Section/Section";
import RowPopup from "./RowPopup";
import { StyledRowPopupContainer } from "./RowPopupContainer.styled";

const RowPopupContainer = () => {
  const { activePopup } = useRowPopupContext();
  const popupWithoutNulls = cleanDeep(
    activePopup?.sectionData.displaySettings ?? {},
    {
      nullValues: true,
    }
  );

  const popupSettings = DisplaySettingsSectionScheme.parse(popupWithoutNulls);

  return (
    <StyledRowPopupContainer
      $settings={popupSettings}
      isPopupOpen={activePopup !== null}
      data-testid="row-popup-container"
    >
      {activePopup && (
        <>
          <RowPopup settings={popupSettings}>
            <Section
              sectionData={activePopup.sectionData}
              type={SectionType.POPUP}
              path={activePopup.sectionPath}
            />
          </RowPopup>
          <div className="backdrop" data-testid="row-popup-backdrop" />
        </>
      )}
    </StyledRowPopupContainer>
  );
};
export default RowPopupContainer;
