import {
  BannersPositions,
  CountOfTemplatesInShake,
  HoverEffects,
  IElementArticleImage,
  IElementButton,
  IElementImage,
  IElementImageInline,
  IElementInnerBox,
  IElementInput,
  IElementText,
  IElementTextContent,
  ImageInlineWidthOptions,
  ImagePlacement,
  ProductHoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  OutlineOptions,
  SettingPositionOptions,
  TAccordionId,
  TColorId,
  TInputId,
  TLinkId,
  TLinkReadoutValue,
  TTypoId,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const HOVER_EFFECTS_OPTIONS = [
  {
    id: "none",
    label: "image_hover_effects.none",
    value: HoverEffects.NONE,
  },
  {
    id: "blur",
    label: "image_hover_effects.blur",
    value: HoverEffects.BLUR,
  },
  {
    id: "flashing",
    label: "image_hover_effects.flashing",
    value: HoverEffects.FLASHING,
  },
  {
    id: "grey",
    label: "image_hover_effects.grey",
    value: HoverEffects.GREY,
  },
  {
    id: "opacity",
    label: "image_hover_effects.opacity",
    value: HoverEffects.OPACITY,
  },
  {
    id: "zoomin",
    label: "image_hover_effects.zoom_in",
    value: HoverEffects.ZOOMIN,
  },
  {
    id: "zoomout",
    label: "image_hover_effects.zoom_out",
    value: HoverEffects.ZOOMOUT,
  },
];

export const PRODUCT_HOVER_EFFECTS_OPTIONS = [
  {
    id: "second",
    label: "image_hover_effects.second",
    value: ProductHoverEffects.SECOND,
  },
];

export const IMAGE_CONTENT_SETTING_PATTERN = (): IElementImage => ({
  show: true,
  alignment: {
    vertical: AlignmentVerticalOption.CENTER,
    horizontal: AlignmentHorizontalOption.LEFT,
  },
  backgroundUrl: "",
  opacity: 100,
  fit: false,
  parallax: false,
});

export const ARTICLE_IMAGE_CONTENT_SETTING_PATTERN =
  (): IElementArticleImage => ({
    show: true,
    height: 400,
    width: 380,
    hover: HoverEffects.ZOOMIN,
    alignment: ImagePlacement.TOP,
  });

export const IMAGE_INLINE_CONTENT_SETTING_PATTERN =
  (): IElementImageInline => ({
    show: true,
    opacity: 20,
    width: ImageInlineWidthOptions.ONE_THIRD,
  });

export const INNER_BOX_CONTENT_SETTING_PATTERN = (): IElementInnerBox => ({
  show: true,
  color: { id: "color$$3", custom: "#000000" },
  alignment: {
    horizontal: AlignmentHorizontalOption.LEFT,
    vertical: AlignmentVerticalOption.BOTTOM,
  },
  contentAlignment: AlignmentHorizontalOption.LEFT,
  textPadding: { top: 2, right: 2, bottom: 2, left: 2 },
  width: 40,
});

export const TITLE_CONTENT_SETTING_PATTERN = (
  typoId?: TTypoId,
  colorId?: TColorId
): IElementTextContent => ({
  show: true,
  font: {
    id: typoId ?? "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 2,
      lineHeight: 2,
      letterSpacing: 2,
    },
  },
  text: {
    color: { id: colorId ?? "color$$1", custom: "#000000" },
  },
});

export const TEXT_STYLE_SHOW_SETTING_PATTERN = (
  typoId?: TTypoId,
  colorId?: TColorId,
  show?: boolean
): IElementTextContent => ({
  show: show ?? true,
  font: {
    id: typoId ?? "typo$$2",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 2,
      lineHeight: 2,
      letterSpacing: 2,
    },
  },
  text: {
    color: { id: colorId ?? "color$$2", custom: "#000000" },
  },
});

export const TEXT_STYLE_SETTING_PATTERN = (
  typoId?: TTypoId,
  colorId?: TColorId
): IElementText => ({
  font: {
    id: typoId ?? "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  text: {
    color: { id: colorId ?? "color$$2", custom: "#000000" },
  },
});

export const TYPO_STYLE_SETTING_PATTERN = (
  typoId: TTypoId
): TTypoReadoutValue => ({
  id: typoId ?? "typo$$1",
  custom: {
    family: FontFamilyOptions.ROBOTO,
    weight: FontWeightOptionsLongListOfWeights.Weight300,
    size: 24,
    lineHeight: 10,
    letterSpacing: 1,
  },
});

export const MAIN_BUTTON_CONTENT_SETTING_PATTERN = (): IElementButton => ({
  id: "button$$1",
  show: true,
  custom: {
    horizontalPadding: 35,
    verticalPadding: 8,
    background: {
      color: { id: "color$$2", custom: "#000000" },
      hoverColor: { id: "color$$2", custom: "#000000" },
      activeColor: { id: "color$$3", custom: "#000000" },
      disabledColor: { id: "color$$4", custom: "#000000" },
    },
    border: {
      radius: 4,
      width: 0,
      radiusV2: null,
      widthV2: null,
      color: { id: "color$$5", custom: "#000000" },
      hoverColor: { id: "color$$6", custom: "#000000" },
      activeColor: { id: "color$$7", custom: "#000000" },
      disabledColor: { id: "color$$8", custom: "#000000" },
    },
    text: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$10", custom: "#000000" },
      activeColor: { id: "color$$11", custom: "#000000" },
      disabledColor: { id: "color$$12", custom: "#000000" },
    },
    font: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    icon: {
      iconUrl: null,
      position: SettingPositionOptions.RIGHT,
      size: 16,
      spacing: 10,
      color: {
        id: "color$$1",
        custom: "#000000",
      },
      hoverColor: {
        id: "color$$1",
        custom: "#000000",
      },
    },
  },
});

export const SECOND_BUTTON_CONTENT_SETTING_PATTERN = (): IElementButton => ({
  id: "button$$1",
  show: true,
  custom: {
    horizontalPadding: 35,
    verticalPadding: 8,
    background: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    border: {
      radiusV2: null,
      widthV2: null,
      width: 0,
      radius: 4,
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    text: {
      color: { id: "color$$2", custom: "#000000" },
      hoverColor: { id: "color$$2", custom: "#000000" },
      activeColor: { id: "color$$2", custom: "#000000" },
      disabledColor: { id: "color$$2", custom: "#000000" },
    },
    font: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    icon: {
      iconUrl: "",
      position: SettingPositionOptions.RIGHT,
      size: 16,
      spacing: 10,
      color: {
        id: "color$$1",
        custom: "#000000",
      },
      hoverColor: {
        id: "color$$1",
        custom: "#000000",
      },
    },
  },
});

export const INPUT_SEARCH_PATTERN = (inputId: TInputId): IElementInput => ({
  show: true,
  id: inputId ?? "input$$1",
  custom: {
    width: 203,
    height: 37,
    border: {
      style: OutlineOptions.SOLID,
      radius: 0,
      width: { left: 0, right: 0, top: 0, bottom: 0 },
      color: { id: "color$$1", custom: "#000000" },
    },
    activeBorder: {
      style: OutlineOptions.SOLID,
      radius: 0,
      width: { left: 0, right: 0, top: 0, bottom: 0 },
      color: { id: "color$$1", custom: "#000000" },
    },
    hoverBorder: {
      style: OutlineOptions.SOLID,
      radius: 0,
      width: { left: 0, right: 0, top: 0, bottom: 0 },
      color: { id: "color$$1", custom: "#000000" },
    },
    disabledBorder: {
      style: OutlineOptions.SOLID,
      radius: 0,
      width: { left: 0, right: 0, top: 0, bottom: 0 },
      color: { id: "color$$1", custom: "#000000" },
    },
    errorBorder: {
      style: OutlineOptions.SOLID,
      radius: 0,
      width: { left: 0, right: 0, top: 0, bottom: 0 },
      color: { id: "color$$1", custom: "#000000" },
    },
    background: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    font: {
      input: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      label: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      placeholder: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      error: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
    },
    text: {
      errorColor: { id: "color$$1", custom: "#000000" },
      placeholderColor: { id: "color$$1", custom: "#000000" },
      labelColor: { id: "color$$1", custom: "#000000" },
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
  },
});

export const LINK_STYLE_SETTING_PATTERN = (
  linkId?: TLinkId | "custom"
): TLinkReadoutValue => ({
  id: linkId ?? "link$$1",
  custom: {
    text: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
    },
  },
});

export const ACCORDION_STYLE_SETTING_PATTERN = (
  accordionId?: TAccordionId | "custom"
) => ({
  id: accordionId ?? "accordion$$1",
  custom: {
    accordionAlignment: AlignmentHorizontalOption.CENTER,
    accordionPadding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    accordionWidth: 100,
    accordionTypography: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    accordionBackground: {
      color: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    accordionText: {
      color: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    accordionBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 20,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.SOLID,
    },
    accordionHoverBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 20,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.SOLID,
    },
    accordionActiveBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 20,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.SOLID,
    },
    accordionDisabledBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 20,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.SOLID,
    },
    openIcon: {
      size: 24,
      position: SettingPositionOptions.RIGHT,
      spacing: 0,
      iconUrl: "",
    },
    closeIcon: {
      size: 24,
      position: SettingPositionOptions.RIGHT,
      spacing: 0,
      iconUrl: "",
    },
  },
});

export const BANNERS_POSITIONS = [
  {
    id: "fix",
    label: "banners_position.fix",
    value: BannersPositions.FIX,
  },
  {
    id: "shake",
    label: "banners_position.shake",
    value: BannersPositions.SHAKE,
  },
];

export const BANNERS_ORDER = [
  {
    id: "fix",
    label: "banners_order.fix",
    value: BannersPositions.FIX,
  },
  {
    id: "shake",
    label: "banners_order.shake",
    value: BannersPositions.SHAKE,
  },
];

export const COUNT_OF_TEMPLATES = [
  {
    id: "ONE",
    label: "1",
    value: CountOfTemplatesInShake.ONE,
  },
  {
    id: "TWO",
    label: "2",
    value: CountOfTemplatesInShake.TWO,
  },
  {
    id: "THREE",
    label: "3",
    value: CountOfTemplatesInShake.THREE,
  },
];
